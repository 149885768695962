import { gql } from '@apollo/client'

export const PRODUCT_FRAGMENT = gql`
  fragment ProductQueryFragment on ProductType {
    id
    name
    salesforceId
    projectCount
    deletedAt
    searchLabel
    specification {
      id
      gembahCategory
      merchandisingProfile {
        sector {
          name
        }
        subsector {
          name
        }
      }
    }
    company {
      id
      name
    }
  }
`

export const GET_PRODUCT_BY_ID = gql`
  query GetProduct($productId: ID!) {
    product(productId: $productId) {
      ...ProductQueryFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`

export const GET_PRODUCTS = gql`
  query GetProducts($search: String) {
    products(search: $search) {
      totalCount
      results {
        ...ProductQueryFragment
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`
