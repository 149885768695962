import PropTypes from 'prop-types'
import React, { useState } from 'react'

import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import SettingsIcon from '@public/svg/icons/settings-icon.svg'

import { TEAL_FURBY } from '@lib/colors'
import tracking, { serializeData } from '@lib/tracking'

import SvgLoader from '@components_pop/SvgLoader'

const useStyles = makeStyles((theme) => ({
  headerButton: {
    '& > span > div': {
      width: 32,
      height: 32,
    },
  },
  squareButtonIcon: {
    fontSize: '1.2rem',
  },
  icons: {
    marginRight: theme.spacing(1),
    width: 15,
    height: 15,
  },
  menuWrapper: {
    border: `2px solid ${TEAL_FURBY}`,
  },
  menuItem: {
    fontSize: 12,
  },
  menuItemContent: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const ButtonSquareMenu = ({ entries, id, loading, ...props }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleSelection = (entry) => {
    tracking.trackEvent({
      id: `project_checklist-add-dropdown-option_click`,
      option: entry.label,
    })

    handleClose()

    if (entry.cb) {
      entry.cb()
    }
  }

  return (
    <>
      <IconButton
        className={classes.headerButton}
        onClick={handleClick}
        data-test-id="square-menu-button"
        {...props}
      >
        <SvgLoader {...SettingsIcon} isLoading={loading} />
      </IconButton>
      <Menu
        id={id}
        open={!!anchorEl}
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
        classes={{
          paper: classes.menuWrapper,
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {entries.map((entry) => (
          <MenuItem
            key={entry.label}
            disabled={entry.disabled}
            onClick={() => handleSelection(entry)}
            className={classes.menuItem}
            data-tracking-info={entry.trackingInfo ? serializeData(entry.trackingInfo) : null}
            data-test-id={entry.dataTestId || null}
          >
            <Typography classes={{ root: classes.menuItemContent }} variant="inherit" noWrap>
              {entry.icon && <SvgLoader {...entry.icon} className={classes.icons} />} {entry.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

ButtonSquareMenu.propTypes = {
  loading: PropTypes.bool,
  entries: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
}

export default ButtonSquareMenu
