import { useMutation, useReactiveVar } from '@apollo/client'
import clsx from 'clsx'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import InfoIcon from '@public/svg/icons/info-icon.svg'

import {
  currentMilestone,
  editingJourneyTabElementId,
  isEditingJourneyTab,
} from '@lib/apollo/apolloCache'
import {
  COMMON_WHITE,
  GREEN_MOON_SHOES,
  PURPLE_KOOSH,
  PURPLE_MEGATRON,
  TEAL_DOLLHOUSE,
  TEAL_FURBY,
  TEAL_LITEBRITE,
  TEAL_POGS,
  TEAL_ROBOTS,
} from '@lib/colors'
import { FONTS } from '@lib/theme'

import { TOGGLE_NPI_MILESTONE_VISIBILITY, TOGGLE_NPI_STEP_VISIBILITY } from '@graphql/npi/mutators'

import ButtonSpinnerContent from '@components_pop/ButtonSpinnerContent'
import NpiEditDateModal from '@components_pop/ProjectDetailsNPI/NpiEditDateModal'
import NpiEditModal from '@components_pop/ProjectDetailsNPI/NpiEditModal'
import NpiMenu from '@components_pop/ProjectDetailsNPI/NpiMenu'
import NpiMilestoneContent from '@components_pop/ProjectDetailsNPI/NpiMilestoneContent'
import NpiMoveModal from '@components_pop/ProjectDetailsNPI/NpiMoveModal'
import {
  EMPTY_STATE,
  NPI_MENU_ACTION_TYPES,
  NPI_MENU_TYPE_ITEMS,
  NPI_VISUAL_DATE_FORMAT,
} from '@components_pop/ProjectDetailsNPI/constants'
import SvgLoader from '@components_pop/SvgLoader'
import { TOAST_TYPES } from '@components_pop/Toast'

import useToast from '@hooks/useToast'

const useStyles = makeStyles((theme) => ({
  hiddenMilestone: {
    ...theme.npiTable.hiddenNpiElement,
  },
  menuButtonRoot: {
    marginLeft: 20,
  },
  layout: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: '800px',
    height: '100%',
  },
  scroller: {
    height: '100%',
  },
  tab: {
    fontSize: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 0',
    height: 45,
    cursor: 'pointer',
    color: TEAL_FURBY,
    backgroundColor: TEAL_LITEBRITE,
    borderBottom: `1px solid ${TEAL_POGS}`,
    flex: 1,
    fontWeight: 700,
  },
  tabContent: {
    flex: 1,
  },
  selectedTab: {
    fontSize: 12,
    backgroundColor: TEAL_ROBOTS,
    color: COMMON_WHITE,
    fontWeight: 800,
  },
  selectedAndActiveTab: {
    backgroundColor: PURPLE_MEGATRON,
    color: COMMON_WHITE,
  },
  completedTab: {
    backgroundColor: TEAL_FURBY,
    color: COMMON_WHITE,
  },
  tabContainer: {
    width: 45,
    borderRight: ({ milestoneColor }) => `5px solid ${milestoneColor}`,
    display: 'flex',
    flexDirection: 'column',
  },
  titleBar: {
    height: '64px',
    padding: '0 16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  secondaryTitle: {
    fontSize: 10,
    textTransform: 'capitalize',
  },
  mainTitle: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: '4px',
    textTransform: 'capitalize',
  },
  rightPanel: {
    flex: 1,
    textAlign: 'end',
  },
  disabledTab: {
    cursor: 'not-allowed',
  },
  emptyState: {
    ...theme.projectDetails.tabEmptyState,
    padding: theme.spacing(2),
  },
  milestonePlaceholder: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontFamily: FONTS.POPPINS,
  },
  paperTooltipRoot: {
    left: '-23px !important',
    top: '-2px !important',
    zIndex: 100,
  },
  tooltipRoot: {
    maxWidth: 500,
    background: GREEN_MOON_SHOES,
    padding: '5px 5px 5px 25px',
    marginLeft: 0,
    borderRadius: 5,
    fontSize: 10,
    color: TEAL_DOLLHOUSE,
    textTransform: 'none',
    boxShadow: '0 0 8px rgba(149, 145, 142, 0.5)',
  },
  iconWrapper: {
    zIndex: 101,
    marginLeft: '10px',
    cursor: 'pointer',
    '& > div': {
      width: 23,
      height: 23,
      background: 'rgba(255, 255, 255, 0.5)',
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > div': {
        width: 15,
        height: 19,
      },
    },
  },
  openedIconWrapper: {
    '& > div': {
      background: 'transparent',
    },
  },
  descriptionAndNameWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function NpiTabsContainer({ formattedNpiSteps }) {
  const { addToast } = useToast()
  const [menuItem, setMenuItem] = useState(null)
  const [editMoveItem, setEditMoveItem] = useState(null)
  const [openTooltip, setOpenTooltip] = useState(false)

  const npiSteps = Object.entries(formattedNpiSteps)
  const currentStep = useReactiveVar(currentMilestone)
  const currentMovedJourneyTabElementId = useReactiveVar(editingJourneyTabElementId)
  const isEditingJourneyProcess = useReactiveVar(isEditingJourneyTab)

  const filteredMilestones = isEditingJourneyProcess
    ? npiSteps
    : npiSteps.filter(([, milestone]) => milestone.visible)
  const currentMilestoneIndex = currentStep?.milestonePosition ?? 0

  const [selectedTab, setSelectedTab] = useState(currentMilestoneIndex)
  const selectedMilestone = filteredMilestones[selectedTab]?.[1]

  const dueDate = selectedMilestone?.dueAt
  const classes = useStyles({ milestoneColor: selectedMilestone?.color })

  const npiStepsProps = npiSteps.map((npiStep) => npiStep[1])
  const isEditingMilestone = isEditingJourneyProcess && !selectedMilestone?.completedAt

  const [callToggleNpiStepVisibility] = useMutation(TOGGLE_NPI_STEP_VISIBILITY)
  const [callToggleNpiMilestoneVisibility] = useMutation(TOGGLE_NPI_MILESTONE_VISIBILITY)

  const handleMenuOpen = (itemOptions) => (event) => {
    event.stopPropagation()
    event.preventDefault()
    setMenuItem({
      ...itemOptions,
      anchorEl: event.currentTarget,
    })
  }

  const handleMenuClose = () => {
    setMenuItem(null)
  }

  const handleEditMoveOpen = (itemOptions) => {
    setEditMoveItem(itemOptions)
    handleMenuClose()
  }

  const handleModalClose = () => {
    setEditMoveItem(null)
  }

  const handleToggleNpiItemVisibility = (id, visible, type) => {
    const method =
      type === NPI_MENU_TYPE_ITEMS.MILESTONE
        ? callToggleNpiMilestoneVisibility({
            variables: {
              milestoneId: id,
              visible: !visible,
            },
          })
        : callToggleNpiStepVisibility({
            variables: {
              stepId: id,
              visible: !visible,
            },
          })

    method.then((res) => {
      if (res.errors) return

      addToast({
        message: `Item has been successfully ${visible ? 'hidden' : 'unhidden'}`,
        type: TOAST_TYPES.SUCCESS,
      })

      handleMenuClose()
    })
  }

  const getBorderStyles = () => {
    const color =
      !!selectedMilestone?.startedAt && !selectedMilestone?.completedAt
        ? PURPLE_MEGATRON
        : TEAL_ROBOTS

    return { borderRight: `5px solid ${color}` }
  }

  const getTitleBarStyles = () => {
    let backgroundColor
    let color = TEAL_DOLLHOUSE
    const isMilestoneInProgress = !!selectedMilestone?.startedAt && !selectedMilestone?.completedAt
    const isMilestoneCompleted = !!selectedMilestone?.startedAt && !!selectedMilestone?.completedAt

    if (isMilestoneInProgress) {
      backgroundColor = PURPLE_KOOSH
    } else if (isMilestoneCompleted) {
      backgroundColor = TEAL_FURBY
      color = COMMON_WHITE
    } else {
      backgroundColor = TEAL_POGS
    }

    return { backgroundColor, color }
  }

  useEffect(() => {
    if (currentStep?.milestonePosition) {
      setSelectedTab(() => currentMilestone().milestonePosition)
    }
  }, [currentStep?.milestonePosition, setSelectedTab, isEditingJourneyProcess])

  return (
    <div data-test-id="project-details-npi-wrapper" style={{ height: '100%' }}>
      {filteredMilestones.length === 0 ? (
        <div className={classes.emptyState}>
          No milestones have been configured for this journey.
        </div>
      ) : (
        <div className={classes.scroller}>
          <div className={classes.layout}>
            <div className={classes.tabContainer} style={getBorderStyles()}>
              {filteredMilestones.map(([, milestone], i) => {
                const isSelected = selectedTab === i
                const isHidden = isEditingJourneyProcess && !milestone.visible
                const isCompleted = !!milestone.completedAt
                const isActive = currentStep === null || i <= currentMilestoneIndex
                const isActiveAndSelected = isSelected && isActive
                const isActiveAndNotSelected = !isSelected && isActive
                const tabClasses = clsx(classes.tab, {
                  [classes.selectedTab]: isSelected,
                  [classes.selectedAndActiveTab]:
                    (isActiveAndNotSelected || isActiveAndSelected) && !isCompleted,
                  [classes.completedTab]: !isSelected && isCompleted,
                  [classes.hiddenMilestone]: isHidden,
                })

                return (
                  <div
                    className={tabClasses}
                    onClick={() => {
                      setSelectedTab(i)
                    }}
                    key={milestone.id}
                  >
                    <Tooltip placement="right-end" arrow title={milestone.name ?? ''}>
                      <span>{milestone.code?.slice(0, 4)}</span>
                    </Tooltip>
                  </div>
                )
              })}
            </div>
            {!selectedMilestone ? (
              <div className={classes.milestonePlaceholder}>
                Configuring current milestone. Please wait...
              </div>
            ) : (
              <div
                className={clsx(classes.tabContent, {
                  [classes.hiddenMilestone]: isEditingJourneyProcess && !selectedMilestone.visible,
                })}
              >
                <div className={classes.titleBar} style={getTitleBarStyles()}>
                  <div>
                    <div className={classes.secondaryTitle}>{selectedMilestone?.stageName}</div>
                    <div className={classes.descriptionAndNameWrapper}>
                      <div className={classes.mainTitle}>{selectedMilestone?.name}</div>
                      {selectedMilestone?.description && (
                        <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            classes={{
                              popper: classes.paperTooltipRoot,
                              tooltipPlacementRight: classes.tooltipRoot,
                            }}
                            open={openTooltip}
                            placement="right-start"
                            onClose={() => setOpenTooltip(false)}
                            title={selectedMilestone.description ?? ''}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                          >
                            <span
                              onClick={() => setOpenTooltip(!openTooltip)}
                              className={clsx(classes.iconWrapper, {
                                [classes.openedIconWrapper]: openTooltip,
                              })}
                            >
                              <SvgLoader {...InfoIcon} />
                            </span>
                          </Tooltip>
                        </ClickAwayListener>
                      )}
                    </div>
                  </div>
                  <div className={classes.rightPanel}>
                    <div className={classes.secondaryTitle}>Milestone Due Date</div>
                    <div className={classes.mainTitle}>
                      {dueDate ? moment(dueDate).format(NPI_VISUAL_DATE_FORMAT) : EMPTY_STATE}
                    </div>
                  </div>
                  {isEditingMilestone && (
                    <IconButton
                      disabled={currentMovedJourneyTabElementId !== null}
                      size="medium"
                      data-test-id={`project-details--milestone-${selectedMilestone?.id}`}
                      classes={{ root: classes.menuButtonRoot }}
                      onClick={handleMenuOpen({
                        item: selectedMilestone,
                        type: NPI_MENU_TYPE_ITEMS.MILESTONE,
                      })}
                    >
                      <ButtonSpinnerContent
                        isLoading={currentMovedJourneyTabElementId === selectedMilestone?.id}
                        size="xs"
                      >
                        <MoreHorizIcon />
                      </ButtonSpinnerContent>
                    </IconButton>
                  )}
                </div>
                <NpiMilestoneContent milestone={selectedMilestone} onMenuOpen={handleMenuOpen} />
                {menuItem && (
                  <NpiMenu
                    menuOptions={menuItem}
                    onClose={handleMenuClose}
                    onToggleHide={handleToggleNpiItemVisibility}
                    onMoveOrEdit={handleEditMoveOpen}
                  />
                )}
                {editMoveItem?.action === NPI_MENU_ACTION_TYPES.EDIT_DATE && (
                  <NpiEditDateModal onCloseModal={handleModalClose} itemOptions={editMoveItem} />
                )}
                {editMoveItem?.action === NPI_MENU_ACTION_TYPES.EDIT && (
                  <NpiEditModal onCloseModal={handleModalClose} itemOptions={editMoveItem} />
                )}
                {editMoveItem?.action === NPI_MENU_ACTION_TYPES.MOVE && (
                  <NpiMoveModal
                    onCloseModal={handleModalClose}
                    itemOptions={editMoveItem}
                    npiSteps={npiStepsProps}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

NpiTabsContainer.propTypes = {
  formattedNpiSteps: PropTypes.shape({}),
}
