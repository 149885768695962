import { useQuery, useReactiveVar } from '@apollo/client'
import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import PopButton from '@forms/PopButton'

import { currentAccountUser, currentProject } from '@lib/apollo/apolloCache'
import { serializeData } from '@lib/tracking'
import { hasPermission } from '@lib/userAuth'

import { GET_ACCOUNT_USERS_SIMPLE } from '@graphql/users/queries'

import { TAB_STATES } from '@components_pop/ProjectDetailsTeam/container'

const useStyles = makeStyles(() => ({
  actionSelector: {
    padding: 0,
    border: '1px solid #e0e0e0',
    minWidth: '400px',
    maxWidth: '500px',
  },
  actionListContainer: {
    paddingTop: '2rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  nextIcon: {
    marginLeft: '.5rem',
  },
  dataTooltip: {
    fontSize: '0.8rem',
  },
  hideTooltip: {
    display: 'none',
  },
  tooltipRoot: {
    display: 'block',
  },
}))

const TeamActionSelector = ({ cancel, goToAdd }) => {
  const accountUser = useReactiveVar(currentAccountUser)
  const { id: projectId } = useReactiveVar(currentProject)
  const classes = useStyles()
  const isGembah = hasPermission.GEMBAH(accountUser)

  const { data } = useQuery(GET_ACCOUNT_USERS_SIMPLE, {
    variables: { projectId, limit: 0 },
  })

  const { creatorsCount, expertsCount, gembahCount, vendorsCount } = data?.accountUsers ?? {}

  const items = [
    {
      isVisible: isGembah,
      count: gembahCount,
      userType: TAB_STATES.ATTACH_EMPLOYEE,
      label: 'Add Gembah to project',
      tooltipText: 'No available or eligible Gembah users to add',
    },
    {
      isVisible: true,
      count: expertsCount,
      userType: TAB_STATES.ATTACH_CONTRACTOR,
      label: 'Add Expert to project',
      tooltipText: 'No available or eligible Experts to add',
    },
    {
      isVisible: true,
      count: vendorsCount,
      userType: TAB_STATES.ATTACH_FACTORY,
      label: 'Add Factory user to project',
      tooltipText: 'No available or eligible Factory users to add',
    },
    {
      isVisible: isGembah,
      count: creatorsCount,
      userType: TAB_STATES.ADD_CUSTOMER,
      label: 'Add Creator to project',
      tooltipText: 'No available or eligible Creators to add',
    },
  ]

  return (
    <div className={classes.actionListContainer}>
      <List className={classes.actionSelector} data-test-id="project-team--add-action-list">
        {items.map(
          (item, i) =>
            item.isVisible && (
              <Tooltip
                classes={{
                  popper: item.count > 0 ? classes.hideTooltip : classes.tooltipRoot,
                  tooltip: classes.dataTooltip,
                }}
                placement="top"
                title={item.tooltipText}
                arrow
              >
                <span>
                  <ListItem
                    button
                    disabled={item.count === 0}
                    onClick={() => goToAdd(item.userType)}
                  >
                    <ListItemText primary={item.label} />
                    <NavigateNextIcon className={classes.nextIcon} />
                  </ListItem>
                  {i !== items.length - 1 && <Divider />}
                </span>
              </Tooltip>
            )
        )}
      </List>
      <PopButton
        size="small"
        btnStyle="simple"
        onClick={cancel}
        data-tracking-info={serializeData({
          id: `project_team-member-add-cancel-button_click`,
        })}
      >
        Cancel
      </PopButton>
    </div>
  )
}

TeamActionSelector.propTypes = {
  cancel: PropTypes.func,
  goToAdd: PropTypes.func,
}

export default TeamActionSelector
