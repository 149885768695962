import { useReactiveVar } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useRef, useEffect, useContext } from 'react'

import { ClickAwayListener, Portal } from '@material-ui/core'

import { currentProject } from '@lib/apollo/apolloCache'

import FileUploadContext from '@components_pop/DropZone/FileUploadContext'
import { TOAST_TYPES } from '@components_pop/Toast'

import useToast from '@hooks/useToast'

import ChatMentionSearch from '../ChatMentionSearch'
import useMentionSearch from '../ChatMentionSearch/hooks'
import ErrorBoundary from './ErrorBoundary'
import { MAX_MSG_LENGTH } from './constants'
import ChatEditorView from './view'

const ChatEditorContainer = ({
  channelId,
  onSendMessage,
  isEdit,
  editValue,
  onEditCancel,
  isInternal,
}) => {
  const projectContext = useReactiveVar(currentProject)
  const fileUploadContext = useContext(FileUploadContext)
  const { addToast } = useToast()

  const team = (projectContext || {}).availableMentions || []
  const filteredTeam = isInternal ? team.filter((member) => !member.isCustomer) : team

  const editorComponentRef = useRef()
  // Contains a reference to the remirror instance
  const markdownEditorRef = useRef()

  const saveDraft = () => {
    setTimeout(() => {
      const currentText = markdownEditorRef.current?.helpers.getMarkdown()
      if (isEdit) {
        localStorage.setItem('chatItemMessage', currentText ?? '')
      } else {
        localStorage.setItem(`chatWindowMessage${channelId}`, currentText ?? '')
      }
    })
  }

  const { isSearchOpen, openSearch, closeSearch } = useMentionSearch({
    onClose: () => markdownEditorRef.current?.commands.focus(),
  })

  // handling @ keypress with textChange because mobile soft keyboards don't have the right `key`, `which` or `keyCode`
  // fields in the keyDown event object https://github.com/facebook/react/issues/6176
  const onChange = (editorRef) => {
    const incomingText = editorRef.tr?.steps[0]?.slice?.content?.content[0]?.text
    if (incomingText === '@') {
      // ensure the @ symbol is typed in the chatbox before opening the mention menu
      setTimeout(openSearch, 100)
    }
    saveDraft()
  }

  const handleMentionMenuSelect = ({ id: userId, fullName }) => {
    if (!markdownEditorRef.current) {
      return
    }
    const editorView = markdownEditorRef.current.view
    const editorSchema = markdownEditorRef.current.view.state.schema
    const cursorPosition = editorView.state.selection.ranges[0].$from.pos

    const mentionAttrs = { title: `@${fullName}`, href: `mention:${userId}` }
    const node = editorSchema.text(mentionAttrs.title, [
      editorSchema.marks.link.create(mentionAttrs),
    ])
    editorView.dispatch(
      editorView.state.tr.replaceRangeWith(cursorPosition - 1, cursorPosition, node).insertText(' ')
    )

    closeSearch()
  }

  const handleRestoreMessage = () => {
    let restoredMessageBody = ''

    if (isEdit) {
      restoredMessageBody = localStorage?.getItem('chatItemMessage') || ''
    } else {
      restoredMessageBody = localStorage?.getItem(`chatWindowMessage${channelId}`) || ''
    }

    if (markdownEditorRef.current) {
      if (restoredMessageBody) markdownEditorRef.current?.setContent(restoredMessageBody)
      markdownEditorRef.current?.commands.focus()
    }
  }

  useEffect(() => {
    handleRestoreMessage()
  }, [channelId])

  const handleUploadFilesModalOpen = () => {
    fileUploadContext.onOpenFileSelectModal()
  }

  const handleSendMessage = () => {
    const message = markdownEditorRef.current?.helpers.getMarkdown()
    if (!message) return
    if (!markdownEditorRef.current?.helpers.isCountValid()) {
      addToast({
        message: `Your message exceeds the ${MAX_MSG_LENGTH} character limit.`,
        type: TOAST_TYPES.ERROR,
      })
      return
    }

    onSendMessage({ messageBody: message })
    markdownEditorRef.current?.setContent('')

    setTimeout(() => {
      markdownEditorRef.current?.commands.focus()
    }, 500)
  }

  return (
    <ErrorBoundary restoreMessage={handleRestoreMessage}>
      <ChatEditorView
        editValue={editValue}
        isEdit={isEdit}
        isMentionMenuOpen={isSearchOpen}
        markdownEditorRef={markdownEditorRef}
        onChange={onChange}
        onEditCancel={onEditCancel}
        onSendMessage={handleSendMessage}
        onUploadFiles={handleUploadFilesModalOpen}
        openMentionMenu={openSearch}
        ref={editorComponentRef}
      />
      {isSearchOpen && (
        <ClickAwayListener onClickAway={closeSearch}>
          {/* this div exists so that https://gembah.atlassian.net/browse/PROD-2033 doesnt happen  */}
          <div>
            <Portal container={editorComponentRef.current}>
              <ChatMentionSearch
                team={filteredTeam}
                closeSearch={closeSearch}
                onSelect={handleMentionMenuSelect}
              />
            </Portal>
          </div>
        </ClickAwayListener>
      )}
    </ErrorBoundary>
  )
}

ChatEditorContainer.propTypes = {
  channelId: PropTypes.string.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  editValue: PropTypes.string,
  onEditCancel: PropTypes.func,
  isInternal: PropTypes.bool,
}

ChatEditorContainer.defaultProps = {
  isEdit: false,
  editValue: '',
  onEditCancel: () => {},
}

export default ChatEditorContainer
