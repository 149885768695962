import { useMutation, useReactiveVar } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Tab from '@material-ui/core/Tab'
import { makeStyles } from '@material-ui/core/styles'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'

import {
  currentProject,
  currentProjectNpiSteps,
  editingJourneyTabElementId,
} from '@lib/apollo/apolloCache'
import { COMMON_WHITE, NPI_GREY, PRIMARY_TEXT_GREY, TEAL_FURBY } from '@lib/colors'
import { serializeData } from '@lib/tracking'

import { UPDATE_NPI_STEP_POSITION } from '@graphql/npi/mutators'
import { GET_NPI_STEPS } from '@graphql/npi/queries'

import { extractTasksForClient } from '@components_pop/ProjectDetailsNPI/utils'

import NpiDeliverablesContent from './NpiDeliverablesContent'
import NpiMilestoneHeader from './NpiMilestoneHeader'

const useStyles = makeStyles(() => ({
  topSection: {
    background: COMMON_WHITE,
    paddingBottom: 20,
    display: 'flex',
    alignItems: 'center',
  },
  bottomSection: {
    padding: 20,
    paddingTop: 0,
    background: NPI_GREY,
  },
  tabsRoot: {
    marginBottom: 15,
  },
  indicator: {
    display: 'none',
  },
  tabRoot: {
    margin: 0,
    padding: 0,
    fontSize: '1rem',
    textTransform: 'none',
    '&:before': {
      content: "''",
      display: 'block',
      marginRight: 5,
      width: 14,
      height: 13,
      borderRadius: '50%',
      border: `1px solid ${PRIMARY_TEXT_GREY}`,
    },
    '& > span': {
      alignItems: 'baseline',
    },
  },
  selectedTab: {
    color: TEAL_FURBY,
    textDecoration: 'underline',
    '&:before': {
      border: `3px solid ${TEAL_FURBY}`,
    },
  },
  tabPanel: {
    padding: 0,
  },
}))

const NpiMilestoneContent = ({ milestone, onMenuOpen }) => {
  const classes = useStyles()

  const [tabIndex, setTabIndex] = useState('0')
  const npiSteps = useReactiveVar(currentProjectNpiSteps)
  const project = useReactiveVar(currentProject)

  const [callUpdateNpiStepPosition] = useMutation(UPDATE_NPI_STEP_POSITION, {
    refetchQueries: [{ query: GET_NPI_STEPS, variables: { projectId: project?.id } }],
  })

  const { children: tasks, id: milestoneId } = milestone

  const tasksForClient = extractTasksForClient(Object.values(tasks))

  const handleMoveNpiStep = (stepId, position) => {
    callUpdateNpiStepPosition({
      variables: {
        stepId,
        milestoneId,
        position,
      },
    }).then(() => {
      setTimeout(() => {
        editingJourneyTabElementId(null)
      }, 1000)
    })
  }

  const handleDND = (result, localItems) => {
    const { destination, draggableId, source } = result

    if (!destination) return

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    editingJourneyTabElementId(draggableId)

    const clonedNpiStep = [...npiSteps]

    const toReplaceSourceStepIndex = clonedNpiStep.findIndex((step) => step.id === draggableId)
    const toReplaceDestinationStepIndex = clonedNpiStep.findIndex(
      (step) => step.id === localItems[destination.index - 1].npiStepId
    )

    const draggedItem = { ...clonedNpiStep[toReplaceSourceStepIndex] }
    const draggedOverItem = { ...clonedNpiStep[toReplaceDestinationStepIndex] }

    clonedNpiStep.splice(toReplaceSourceStepIndex, 1)
    clonedNpiStep.splice(toReplaceDestinationStepIndex, 0, draggedItem)

    currentProjectNpiSteps([...clonedNpiStep])

    handleMoveNpiStep(draggedItem.id, draggedOverItem.position)
  }

  const tabsAndContentList = [
    {
      visible: true,
      name: 'Deliverables',
      index: '0',
      dataTracking: serializeData({ id: `project_npi-deliverables-tab_click` }),
      content: (
        <NpiDeliverablesContent
          tasks={tasksForClient}
          milestone={milestone}
          onMenuOpen={onMenuOpen}
          handleDND={handleDND}
        />
      ),
    },
  ]

  return (
    <>
      <div className={classes.topSection}>
        <NpiMilestoneHeader milestone={milestone} />
      </div>
      <div className={classes.bottomSection}>
        <TabContext value={tabIndex}>
          {tabsAndContentList.length > 1 && (
            <TabList
              onChange={(_, newTabIndex) => setTabIndex(newTabIndex)}
              variant="scrollable"
              scrollButtons="auto"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.indicator,
              }}
            >
              {tabsAndContentList.map((tab) => (
                <Tab
                  classes={{ selected: classes.selectedTab, root: classes.tabRoot }}
                  label={tab.name}
                  value={tab.index}
                  key={tab.name}
                  data-tracking-info={tab.dataTracking}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                />
              ))}
            </TabList>
          )}
          {tabsAndContentList.map((tabContent) => (
            <TabPanel
              key={tabContent.name}
              classes={{ root: classes.tabPanel }}
              value={tabContent.index}
            >
              {tabContent.content}
            </TabPanel>
          ))}
        </TabContext>
      </div>
    </>
  )
}

NpiMilestoneContent.propTypes = {
  milestone: PropTypes.object,
  onMenuOpen: PropTypes.func,
}

export default NpiMilestoneContent
