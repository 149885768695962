/* eslint-disable import/prefer-default-export */
import { useAttrs, useChainedCommands, useCurrentSelection, useUpdateReason } from '@remirror/react'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { createMarkPositioner, extractHref } from 'remirror/extensions'

export function useLinkState() {
  const chain = useChainedCommands()
  const [isEditing, setIsEditing] = useState(false)
  const { to, empty } = useCurrentSelection()

  const url = useAttrs().link()?.href ?? ''
  const [href, setHref] = useState(url)

  // A positioner which only shows for links.
  const linkPositioner = useMemo(() => createMarkPositioner({ type: 'link' }), [])

  const onRemove = useCallback(() => {
    return chain.removeLink().focus().run()
  }, [chain])

  const updateReason = useUpdateReason()

  useLayoutEffect(() => {
    if (!isEditing) {
      return
    }

    if (updateReason.doc || updateReason.selection) {
      setIsEditing(false)
    }
  }, [isEditing, setIsEditing, updateReason.doc, updateReason.selection])

  useEffect(() => {
    setHref(url)
  }, [url])

  const submitHref = useCallback(() => {
    setIsEditing(false)

    if (href === '') {
      chain.removeLink()
    } else {
      chain.updateLink({ href: extractHref({ url: href, defaultProtocol: 'https:' }), auto: false })
    }

    chain.focus(to).run()

    setHref('')
  }, [setIsEditing, chain, href, to])

  const cancelHref = useCallback(() => {
    setIsEditing(false)
  }, [setIsEditing])

  const clickEdit = useCallback(() => {
    if (empty) {
      chain.selectLink()
    }

    setIsEditing(true)
  }, [chain, empty, setIsEditing])

  return useMemo(
    () => ({
      href,
      setHref,
      linkPositioner,
      isEditing,
      clickEdit,
      onRemove,
      submitHref,
      cancelHref,
    }),
    [href, linkPositioner, isEditing, clickEdit, onRemove, submitHref, cancelHref]
  )
}

// eslint-disable-next-line react/prop-types
export const DelayAutoFocusInput = ({ autoFocus, ...rest }) => {
  const inputRef = useRef(null)

  useEffect(() => {
    if (!autoFocus) {
      return
    }

    const frame = window.requestAnimationFrame(() => {
      inputRef.current?.focus()
    })

    // eslint-disable-next-line consistent-return
    return () => {
      window.cancelAnimationFrame(frame)
    }
  }, [autoFocus])

  return <input className="link-editor" ref={inputRef} {...rest} />
}
