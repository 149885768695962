import { useMutation, useReactiveVar } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { Tooltip } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

import EnvelopeIcon from '@public/svg/icons/envelope.svg'
import PaperPlaneIcon from '@public/svg/icons/paper-plane.svg'

import GembahConfirmDialog from '@dialog/GembahConfirmDialog'

import { currentMilestone, currentProjectNpiSteps, currentUser } from '@lib/apollo/apolloCache'
import { NPI_BLUE, NPI_FILES_TABLE_BACKGROUND } from '@lib/colors'
import { makeEllipsisText } from '@lib/theme'
import { serializeData } from '@lib/tracking'

import { DELIVER_NPI_SUBMISSION } from '@graphql/npi/mutators'
import { GET_PROJECT_QUERY } from '@graphql/project/queries'

import { EMPTY_STATE, NPI_VISUAL_DATE_FORMAT } from '@components_pop/ProjectDetailsNPI/constants'
import {
  isSubmissionDeleted,
  isSubmissionReviewed,
  isSubmissionStatusDelivered,
  modifyNpiStepsBySubmission,
} from '@components_pop/ProjectDetailsNPI/utils'
import SvgLoader from '@components_pop/SvgLoader'
import GembahTable from '@components_pop/Table'
import { TOAST_TYPES } from '@components_pop/Toast'

import useToast from '@hooks/useToast'

import DeliveredSubmissionButton from './NpiDeliveredSubmissionButton'

const useStyles = makeStyles((theme) => ({
  sendBtn: {
    ...theme.npiTable.actionBtn,
    '& > span > div': {
      width: 20,
      height: 20,
    },
  },
  filesTable: {
    tableLayout: 'fixed',
    '& tbody > tr': {
      background: NPI_FILES_TABLE_BACKGROUND,
    },
  },
  emptyFiles: {
    padding: '16px 16px 16px 100px',
  },
  alignContentRight: {
    textAlign: 'right',
  },
  deliveredSign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    color: NPI_BLUE,
    '& > span': {
      marginRight: 10,
    },
    '& > div': {
      width: 15,
      height: 15,
    },
  },
  deliveredCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  tableCellName: {
    ...makeEllipsisText(100),
    color: '#767676',
    fontWeight: 500,
    paddingLeft: 100,
  },
}))

const HEADERS = [
  {
    name: 'name',
    text: '',
    width: '25%',
  },
  {
    name: 'version',
    text: '',
    width: '10%',
  },
  {
    name: 'owner',
    text: '',
    width: '10%',
  },
  {
    name: '',
    text: '',
    width: '15%',
  },
  {
    name: 'uploaded',
    text: '',
    width: '10%',
  },
  {
    name: 'delivered',
    text: '',
    width: '10%',
  },
  {
    name: 'actions',
    text: '',
  },
]

// There can be multiple `isReview` steps in a  Milestone, but each of them would only relate to
// steps that are before themselves (position-wise) and after a previous Milestone Review step (if any).
const getRelatedTasks = (taskToSend, milestoneTasksMap) => {
  if (!taskToSend.isReview || !milestoneTasksMap) return []
  let skipRest = false
  return Object.values(milestoneTasksMap)
    .reverse() // Iterate from last to first so we can skip the rest of the elements if another Milestone Review step is found
    .reduce((relatedTasks, task) => {
      if (task.position >= taskToSend.position || skipRest) {
        return relatedTasks
      }
      if (task.isReview && task.id !== taskToSend.id) {
        skipRest = true
        return relatedTasks
      }
      return [...relatedTasks, task]
    }, [])
}

const SendConfirmDialog = ({
  submission,
  callDeliverNpiSubmission,
  clearSubmissionToSend,
  milestone,
  task: taskToSend,
}) => {
  if (!submission) {
    return null
  }

  const hasIncompleteItems = getRelatedTasks(taskToSend, milestone?.children).some(
    (task) => !task.completedAt && task.visible
  )
  const title = taskToSend.isReview ? 'Send Milestone Review' : 'Send Deliverable File'
  const description =
    taskToSend.isReview && hasIncompleteItems
      ? 'This Milestone has incomplete tasks and/or deliverables. Click Confirm to send anyway, otherwise click Cancel.'
      : 'Are you sure you want to send this deliverable file to the client?'

  return (
    <GembahConfirmDialog
      title={title}
      description={description}
      onAccept={() => callDeliverNpiSubmission(taskToSend.isReview)}
      onClose={clearSubmissionToSend}
      isSuccess={false}
    />
  )
}

SendConfirmDialog.propTypes = {
  submission: PropTypes.object,
  milestone: PropTypes.object,
  task: PropTypes.object.isRequired,
  clearSubmissionToSend: PropTypes.func.isRequired,
  callDeliverNpiSubmission: PropTypes.func.isRequired,
}

const NpiFilesVersioningExpandedContent = ({ rowItem: task }) => {
  const classes = useStyles()
  const router = useRouter()
  const { projectSlug } = router.query
  const { addToast } = useToast()
  const milestone = useReactiveVar(currentMilestone)

  const { deliverable, npiStepId, assignee } = task
  const { submissions } = deliverable ?? {}
  const user = useReactiveVar(currentUser)
  const isTaskOwner = user.id === task.assignee?.id

  const [submissionToSend, setSubmissionToSend] = useState(null)

  const [deliverNpiSubmission] = useMutation(DELIVER_NPI_SUBMISSION)

  const handleTrashedCellContent = (contentIfNotTrashed, isTrashed) => {
    return isTrashed ? EMPTY_STATE : contentIfNotTrashed
  }

  const clearSubmissionToSend = () => setSubmissionToSend(null)

  const callDeliverNpiSubmission = (refetchProject = false) => {
    deliverNpiSubmission({
      variables: {
        submissionId: submissionToSend?.id,
      },
      refetchQueries: [
        refetchProject ? { query: GET_PROJECT_QUERY, variables: { projectSlug } } : {},
      ],
    }).then((res) => {
      if (res.errors) {
        addToast({
          message: 'Could not send deliverable file to client',
          type: TOAST_TYPES.ERROR,
        })
        return
      }

      addToast({
        message: 'Deliverable file has been sent to the client',
        type: TOAST_TYPES.SUCCESS,
      })

      const newSubmission = res.data.deliverNpiSubmission.npiSubmission
      modifyNpiStepsBySubmission(newSubmission, currentProjectNpiSteps)

      setSubmissionToSend(null)
    })
  }

  const items = (submission) => {
    const { gembahVersion, media, status, deliveredToClientAt } = submission ?? {}
    const { uploadConfirmedAt, filename, uploadUser, url } = media?.[0] ?? []
    const isTrashed = isSubmissionDeleted(status)

    return {
      cells: [
        {
          classNames: { [classes.tableCellName]: true },
          content: handleTrashedCellContent(
            <Tooltip placement="top-start" title={filename ?? ''}>
              <Link href={url} style={{ color: 'inherit' }} target="_blank" rel="noreferrer">
                {filename ?? ''}
              </Link>
            </Tooltip>,
            isTrashed
          ),
        },
        {
          content: gembahVersion,
        },
        {
          content: handleTrashedCellContent(assignee?.fullName, isTrashed),
        },
        {
          content: handleTrashedCellContent(`Uploaded by: ${uploadUser?.fullName}`, isTrashed),
        },
        {
          content: handleTrashedCellContent(
            uploadConfirmedAt
              ? moment(uploadConfirmedAt).format(NPI_VISUAL_DATE_FORMAT)
              : EMPTY_STATE,
            isTrashed
          ),
        },
        {
          content: handleTrashedCellContent(
            deliveredToClientAt
              ? moment(deliveredToClientAt).format(NPI_VISUAL_DATE_FORMAT)
              : EMPTY_STATE,
            isTrashed
          ),
        },
        {
          classNames: { [classes.alignContentRight]: !isTrashed },
          content: handleTrashedCellContent(
            isSubmissionStatusDelivered(status) ? (
              <div className={classes.deliveredCell}>
                <div className={classes.deliveredSign}>
                  <span>Delivered</span>
                  <SvgLoader {...EnvelopeIcon} />
                </div>
                {!isSubmissionReviewed(submission.status) && (
                  <DeliveredSubmissionButton submissionId={submission.id} />
                )}
              </div>
            ) : (
              isTaskOwner && milestone && (
                <IconButton
                  size="small"
                  className={classes.sendBtn}
                  disabled={!assignee}
                  onClick={() => setSubmissionToSend(submission)}
                  data-test-id={`${filename}_task-assign-button`}
                  data-tracking-info={serializeData({
                    id: `npi-deliverable_task-assign-button_click`,
                    submissionId: submission.id,
                  })}
                >
                  <SvgLoader {...PaperPlaneIcon} />
                </IconButton>
              )
            ),
            isTrashed
          ),
        },
      ],
    }
  }

  return (
    <>
      <GembahTable
        tableClassNames={{
          [classes.filesTable]: true,
        }}
        tableName={`${npiStepId}-milestone-documents`}
        headers={HEADERS}
        emptyState={
          <tr>
            <td className={classes.emptyFiles} colSpan={HEADERS.length}>
              No files uploaded yet.
            </td>
          </tr>
        }
        listItems={_.orderBy(submissions ?? [], 'gembahVersion', 'desc')}
        listItemsMap={items}
        hasHiddenTableHeaders
      />
      <SendConfirmDialog
        task={task}
        milestone={milestone}
        submission={submissionToSend}
        clearSubmissionToSend={clearSubmissionToSend}
        callDeliverNpiSubmission={callDeliverNpiSubmission}
      />
    </>
  )
}

NpiFilesVersioningExpandedContent.propTypes = {
  rowItem: PropTypes.object,
}

export default NpiFilesVersioningExpandedContent
