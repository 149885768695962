import { useQuery, useMutation, useReactiveVar, useApolloClient } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { usePageVisibility } from 'react-page-visibility'

import { featureFlags, currentUser, prefetchedChatChannels } from '@lib/apollo/apolloCache'
import {
  CHAT_CHANNEL_POLL_INTERVAL,
  CHAT_CHANNEL_POLL_INTERVAL_WITH_REALTIME,
} from '@lib/apollo/apolloConfig'
import usePusherHelpers from '@lib/pusher/usePusherHelper'

import { MARK_MESSAGE_AS_READ } from '@graphql/chat/mutators'
import { GET_CHAT_CHANNEL_MESSAGES } from '@graphql/chat/queries'
import { GET_NOTIFICATIONS, GET_NOTIFICATION_COUNTS } from '@graphql/notifications/queries'

import Loader from '@components_pop/Loader'

import ChatChannelWindowContainer from './container'

const MESSAGE_COUNT_PER_BATCH = 50

const ChatChannelWindowApollo = ({ channelId }) => {
  const apolloClient = useApolloClient()
  const user = useReactiveVar(currentUser)
  const lastPusherDisconnectTime = useRef()
  const [channelBeingFetched, setChannelBeingFetched] = useState()
  const prefetchedChannels = useReactiveVar(prefetchedChatChannels)
  const { isRealtimeActive } = useReactiveVar(featureFlags)
  const { removePollingGraph } = usePusherHelpers()
  const isPageVisible = usePageVisibility()
  const timeoutId = useRef(null)
  const pollInterval = isRealtimeActive
    ? CHAT_CHANNEL_POLL_INTERVAL_WITH_REALTIME
    : CHAT_CHANNEL_POLL_INTERVAL
  const isFetchingCurrentChannel = channelBeingFetched === channelId

  // this cache-only query makes sure we're always displaying updated data from the cache
  // because Pusher messages go straight to the cache.
  const { data } = useQuery(GET_CHAT_CHANNEL_MESSAGES, {
    fetchPolicy: 'cache-only',
    variables: {
      channelId,
      limit: MESSAGE_COUNT_PER_BATCH,
    },
  })

  const dataRef = useRef(data)

  useEffect(() => {
    dataRef.current = data
  }, [data])

  // this network-only query gives us more control for cases where we want to fetch
  // from the server. E.g.: initial load and re-focusing after tab was in the background
  const fetchRecentMessages = () => {
    if (isFetchingCurrentChannel) {
      return
    }

    const messages = dataRef.current?.allChannelMessages.messages

    const mostRecentMessageDate =
      messages?.length > 0
        ? messages[messages.length - 1].editedAt || messages[messages.length - 1].postedAt
        : lastPusherDisconnectTime.current

    apolloClient
      .query({
        query: GET_CHAT_CHANNEL_MESSAGES,
        fetchPolicy: 'network-only',
        variables: {
          channelId,
          updatedAfter: mostRecentMessageDate,
          limit: MESSAGE_COUNT_PER_BATCH,
        },
      })
      .then(() => {
        if (isRealtimeActive || isFetchingCurrentChannel) return
        // https://github.com/apollographql/apollo-client/issues/3053
        // implementing our own polling because apollo doesn't provide a way to easily
        // recalculate variables on each poll tick
        clearTimeout(timeoutId.current)
        timeoutId.current = setTimeout(() => fetchRecentMessages(), pollInterval)
      })
  }

  const fetchOlderMessages = () => {
    if (isFetchingCurrentChannel) {
      return
    }
    setChannelBeingFetched(channelId)
    const messages = dataRef.current?.allChannelMessages.messages
    const oldestMessageDate = messages?.length > 0 ? messages[0].postedAt : null
    apolloClient
      .query({
        query: GET_CHAT_CHANNEL_MESSAGES,
        fetchPolicy: 'network-only',
        variables: {
          channelId,
          postedBefore: oldestMessageDate,
          limit: MESSAGE_COUNT_PER_BATCH,
        },
      })
      .then((res) => {
        setChannelBeingFetched(undefined)
        if (isRealtimeActive || !res?.data) return
        fetchRecentMessages()
      })
  }

  const [markMessageAsRead] = useMutation(MARK_MESSAGE_AS_READ, {
    refetchQueries: removePollingGraph([
      GET_CHAT_CHANNEL_MESSAGES,
      GET_NOTIFICATIONS,
      GET_NOTIFICATION_COUNTS,
    ]),
  })

  function fetchMessages() {
    // When the tab (channelId) changes, we refetch messages if the tab had not
    // been prefetched by a previous user interaction.
    if (prefetchedChannels.includes(channelId)) {
      return
    }

    fetchOlderMessages()
    prefetchedChatChannels([...prefetchedChannels, channelId])
  }

  useEffect(() => {
    fetchMessages()
  }, [channelId])

  useEffect(() => {
    // When an user changes tabs, we close the Pusher connection, so when the page
    // changes from not visible to visible we assume the messages are stale.
    // We refetch the current tab's messages and reset the prefetchedChannels ref
    // to make sure messages are synced.
    if (isPageVisible) {
      if (dataRef.current?.allChannelMessages?.messages?.length) {
        fetchRecentMessages()
      } else {
        fetchMessages()
      }

      prefetchedChatChannels([channelId])
    } else {
      // when the page goes from visible to not visible, we disconnect from pusher via
      // components_pop/Realtime.js. This ref saves the disconnect datetime so we know which date
      // to fetch from then attempting to sync the cached messages with the server.
      lastPusherDisconnectTime.current = new Date().toISOString()
      prefetchedChatChannels([])
      clearTimeout(timeoutId.current)
    }

    return () => {
      clearTimeout(timeoutId.current)
    }
  }, [isPageVisible])

  const handleMarkMessageAsRead = (messageId) => {
    if (!user?.isSwitched) {
      // https://github.com/apollographql/react-apollo/issues/3781
      markMessageAsRead({
        variables: {
          messageId,
        },
      }).then(
        (/* res */) => {},
        (/* err */) => {}
      )
    }
  }

  return (
    <Loader loaderElementsCount={5} isLoadingContent={!data} variant="chat">
      <ChatChannelWindowContainer
        channelId={channelId}
        messages={data?.allChannelMessages.messages || []}
        isLoadingOlderMessages={
          isFetchingCurrentChannel || data?.allChannelMessages.haveOlderMessages
        }
        messageCountPerBatch={MESSAGE_COUNT_PER_BATCH}
        fetchOlderMessages={fetchOlderMessages}
        hasOlderMessages={data?.allChannelMessages.haveOlderMessages}
        onMarkMessageAsRead={handleMarkMessageAsRead}
      />
    </Loader>
  )
}

ChatChannelWindowApollo.propTypes = {
  channelId: PropTypes.string.isRequired,
}

export default ChatChannelWindowApollo
