// inspired by https://github.com/remirror/remirror/blob/main/packages/remirror__react-editors/src/markdown/markdown-editor.tsx
// as a workaround for https://github.com/remirror/remirror/issues/1284
// this depends on @emotion/react and @emotion/styled because of the @remirror/styles/emotion import
import { CountExtension } from '@remirror/extension-count'
import {
  chainCommands,
  createParagraphNear,
  liftEmptyBlock,
  newlineInCode,
  splitBlock,
} from '@remirror/pm/commands'
import {
  ComponentItem,
  EditorComponent,
  FloatingWrapper,
  Remirror,
  ThemeProvider,
  Toolbar,
  useActive,
  useCurrentSelection,
  useKeymap,
  useRemirror,
} from '@remirror/react'
import { AllStyledComponent } from '@remirror/styles/styled-components'
import PropTypes from 'prop-types'
import React, { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react'
import { ExtensionPriority } from 'remirror'
import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  CodeBlockExtension,
  CodeExtension,
  HardBreakExtension,
  HeadingExtension,
  ItalicExtension,
  LinkExtension,
  ListItemExtension,
  MarkdownExtension,
  OrderedListExtension,
  PlaceholderExtension,
  StrikeExtension,
  TableExtension,
  TrailingNodeExtension,
} from 'remirror/extensions'

import { DelayAutoFocusInput, useLinkState } from './linkUtils'

const toolbarItems = [
  {
    type: ComponentItem.ToolbarGroup,
    label: 'Heading Formatting',
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleHeading',
        display: 'icon',
        attrs: { level: 1 },
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleHeading',
        display: 'icon',
        attrs: { level: 2 },
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleHeading',
        display: 'icon',
        attrs: { level: 3 },
      },
    ],
  },
  {
    type: ComponentItem.ToolbarGroup,
    label: 'Simple Formatting',
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleBulletList',
        display: 'icon',
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleOrderedList',
        display: 'icon',
      },
    ],
    // separator: 'end',
  },
]

export default { title: 'Editors / Markdown' }

/**
 * The editor which is used to create the annotation. Supports formatting.
 */
export const MarkdownEditor = forwardRef(
  (
    {
      placeholder,
      initialContent,
      maxLength,
      maximumExceededClassName,
      disableToolbar = false,
      onChange,
      isMobile,
      enterKeyPressHandler,
      children,
    },
    ref
  ) => {
    const listItemExtension = new ListItemExtension({
      priority: ExtensionPriority.High,
      enableCollapsible: true,
    })

    const extensions = useCallback(
      () => [
        new PlaceholderExtension({ placeholder }),
        new LinkExtension({ autoLink: true }),
        new BoldExtension(),
        new StrikeExtension(),
        new ItalicExtension(),
        new HeadingExtension(),
        new LinkExtension(),
        new BlockquoteExtension(),
        new BulletListExtension({ enableSpine: true }),
        new OrderedListExtension(),
        listItemExtension,
        new CodeExtension(),
        new CodeBlockExtension(),
        new TrailingNodeExtension(),
        new TableExtension(),
        new MarkdownExtension({ copyAsMarkdown: false }),
        new CountExtension({ maximum: maxLength, maximumExceededClassName }),
        // events,
        /**
         * `HardBreakExtension` allows us to create a newline inside paragraphs.
         * e.g. in a list item
         */
        new HardBreakExtension(),
      ],
      [placeholder]
    )

    const { manager, getContext } = useRemirror({
      extensions,
      stringHandler: 'markdown',
    })

    useImperativeHandle(ref, () => getContext(), [getContext])

    // AutoFocus the editor when it is mounted
    // To avoid losing focus on re-renders
    ref.current?.commands?.focus()

    const hooks = [
      () => {
        // Copied default 'Enter' behavior from https://github.com/ProseMirror/prosemirror-commands/blob/20371c5/src/commands.js#L638
        const shiftEnterHandler = useCallback((editorRef) => {
          // Execute Enter keymap from the list item extension. Creates a new list item if a list was active.
          const wasExecuted = listItemExtension.createKeymap().Enter(editorRef)

          if (wasExecuted) {
            return true
          }

          const { state, dispatch } = editorRef

          chainCommands(
            newlineInCode,
            createParagraphNear,
            liftEmptyBlock,
            splitBlock
          )(state, dispatch)
          return true
        }, [])

        const enterHandler = useCallback(() => {
          if (isMobile) return false

          enterKeyPressHandler()
          return true
        }, [enterKeyPressHandler])

        useKeymap('Shift-Enter', shiftEnterHandler, ExtensionPriority.Highest) // Add the handler to the keypress pattern.
        useKeymap('Enter', enterHandler) // Add the handler to the keypress pattern.
      },
    ]

    return (
      <AllStyledComponent>
        <ThemeProvider>
          <Remirror
            manager={manager}
            initialContent={initialContent}
            onChange={onChange}
            hooks={hooks}
          >
            {!disableToolbar && <Toolbar items={toolbarItems} refocusEditor label="Top Toolbar" />}
            {!disableToolbar && <LinkEditorToolbar />}
            <EditorComponent />
            {children}
          </Remirror>
        </ThemeProvider>
      </AllStyledComponent>
    )
  }
)

MarkdownEditor.propTypes = {
  placeholder: PropTypes.string,
  initialContent: PropTypes.string,
  disableToolbar: PropTypes.bool,
  isMobile: PropTypes.bool,
  maximumExceededClassName: PropTypes.string,
  children: PropTypes.node,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  enterKeyPressHandler: PropTypes.func,
}

export const LinkEditorToolbar = () => {
  const { isEditing, linkPositioner, clickEdit, onRemove, submitHref, href, setHref, cancelHref } =
    useLinkState()
  const active = useActive()
  const activeLink = active.link()
  const { empty } = useCurrentSelection()

  const linkEditItems = useMemo(
    () => [
      {
        type: ComponentItem.ToolbarGroup,
        label: 'Link handling',
        items: activeLink
          ? [
              { type: ComponentItem.ToolbarButton, onClick: () => clickEdit(), icon: 'pencilLine' },
              { type: ComponentItem.ToolbarButton, onClick: onRemove, icon: 'linkUnlink' },
            ]
          : [{ type: ComponentItem.ToolbarButton, onClick: () => clickEdit(), icon: 'link' }],
      },
    ],
    [clickEdit, onRemove, activeLink, isEditing]
  )

  return (
    <>
      <FloatingWrapper
        positioner="selection"
        placement="bottom"
        renderOutsideEditor
        enabled={!isEditing}
      >
        <Toolbar items={linkEditItems} />
      </FloatingWrapper>

      <FloatingWrapper
        positioner={linkPositioner}
        placement="bottom"
        renderOutsideEditor
        enabled={!isEditing && empty}
      >
        <Toolbar items={linkEditItems} />
      </FloatingWrapper>

      <FloatingWrapper
        positioner="always"
        placement="bottom"
        enabled={isEditing}
        renderOutsideEditor
      >
        <DelayAutoFocusInput
          style={{ zIndex: 20 }}
          autoFocus
          placeholder="Enter link..."
          onChange={(event) => setHref(event.target.value)}
          value={href}
          onKeyPress={(event) => {
            const { key } = event

            if (key === 'Enter') {
              submitHref()
            }

            if (key === 'Escape') {
              cancelHref()
            }
          }}
        />
      </FloatingWrapper>
    </>
  )
}
