import { gql } from '@apollo/client'

export const CREATE_PROJECT = gql`
  mutation CreateProject(
    $companyId: ID!
    $name: String!
    $description: String!
    $industry: String
    $merchandisingCategory: String
    $status: String
    $salesforceId: String
    $npiTemplateId: String
    $productId: ID!
    $npiMilestoneId: String
    $gpsdLink: String
    $folderLink: String
    $complexity: String
    $region: String
    $resolutionType: String
    $accountClosureNotes: String
    $isTest: Boolean
  ) {
    createProject(
      companyId: $companyId
      name: $name
      description: $description
      productId: $productId
      industry: $industry
      merchandisingCategory: $merchandisingCategory
      status: $status
      salesforceId: $salesforceId
      npiTemplateId: $npiTemplateId
      npiMilestoneId: $npiMilestoneId
      gpsdLink: $gpsdLink
      folderLink: $folderLink
      complexity: $complexity
      region: $region
      resolutionType: $resolutionType
      accountClosureNotes: $accountClosureNotes
      isTest: $isTest
    ) {
      project {
        id
        slug
      }
    }
  }
`

export const EDIT_PROJECT = gql`
  mutation EditProject($projectId: ID!, $projectData: EditProjectInput!) {
    editProject(projectId: $projectId, projectData: $projectData) {
      project {
        slug
        id
        name
        description
        status
      }
    }
  }
`

export const DELETE_PROJECT = gql`
  mutation DeleteProject($projectId: ID!) {
    deleteProject(projectId: $projectId) {
      trashedProject {
        id
      }
    }
  }
`

export const COMPLETE_CHECKLIST_ITEM = gql`
  mutation CompleteChecklistItem($checklistItemId: ID!) {
    completeChecklistItem(checklistItemId: $checklistItemId) {
      checklistItem {
        id
        completedAt
      }
    }
  }
`

export const SET_PROJECT_COVER_IMAGE = gql`
  mutation SetProjectCoverImage($projectId: ID!, $mediaId: ID!) {
    setProjectCoverImage(projectId: $projectId, mediaId: $mediaId) {
      project {
        id
        coverImage {
          id
          url
          thumbnailUrl
          filename
        }
      }
    }
  }
`

export const UNSET_PROJECT_COVER_IMAGE = gql`
  mutation UnsetProjectCoverImage($projectId: ID!) {
    unsetProjectCoverImage(projectId: $projectId) {
      project {
        id
      }
    }
  }
`

export const REOPEN_CHECKLIST_ITEM = gql`
  mutation ReopenChecklistItem($checklistItemId: ID!) {
    reopenChecklistItem(checklistItemId: $checklistItemId) {
      checklistItem {
        id
        completedAt
      }
    }
  }
`

export const CREATE_PRESET_CHECKLIST = gql`
  mutation BulkCreatePresetChecklist(
    $presetChecklist: String!
    $projectId: ID!
    $projectTimezone: String
  ) {
    bulkCreatePresetChecklist(
      presetChecklist: $presetChecklist
      projectId: $projectId
      projectTimezone: $projectTimezone
    ) {
      checklistItems {
        id
        description
        dueDate
        completedAt
        assignee {
          id
          firstName
          lastName
        }
        assignedAt
        postedBy {
          id
          firstName
          lastName
        }
        postedAt
      }
    }
  }
`

export const CREATE_CHECKLIST_ITEM = gql`
  mutation CreateChecklistItem(
    $projectId: ID!
    $description: String!
    $dueDate: Date
    $assigneeId: ID
    $groupId: ID
  ) {
    createChecklistItem(
      projectId: $projectId
      description: $description
      dueDate: $dueDate
      assigneeId: $assigneeId
      groupId: $groupId
    ) {
      checklistItem {
        id
        description
        dueDate
        completedAt
        assignee {
          id
          firstName
          lastName
        }
        assignedAt
        postedBy {
          id
          firstName
          lastName
        }
        postedAt
      }
    }
  }
`

export const CREATE_CHECKLIST_GROUP = gql`
  mutation CreateChecklistGroup($name: String!, $projectId: ID!) {
    createChecklistGroup(name: $name, projectId: $projectId) {
      checklistGroup {
        id
        name
        project {
          id
        }
        order
      }
    }
  }
`

export const UPDATE_CHECKLIST_GROUP = gql`
  mutation UpdateChecklistGroup($checklistGroupId: ID!, $name: String!, $assigneeId: ID) {
    updateChecklistGroup(
      checklistGroupId: $checklistGroupId
      name: $name
      assigneeId: $assigneeId
    ) {
      checklistGroup {
        id
        name
        project {
          id
        }
        order
      }
    }
  }
`

export const UPDATE_CHECKLIST_ITEM = gql`
  mutation UpdateChecklistItem(
    $checklistItemId: ID!
    $description: String!
    $dueDate: Date
    $assigneeId: ID
    $groupId: ID
  ) {
    updateChecklistItem(
      checklistItemId: $checklistItemId
      description: $description
      dueDate: $dueDate
      assigneeId: $assigneeId
      groupId: $groupId
    ) {
      checklistItem {
        id
        description
        dueDate
        completedAt
        assignee {
          id
          firstName
          lastName
        }
        assignedAt
      }
    }
  }
`

export const TRASH_CHECKLIST_ITEM = gql`
  mutation TrashChecklistItem($checklistItemId: ID!) {
    trashChecklistItem(checklistItemId: $checklistItemId) {
      checklistItem {
        id
        deletedAt
      }
    }
  }
`

export const TRASH_CHECKLIST_GROUP = gql`
  mutation TrashChecklistGroup($checklistGroupId: ID!) {
    trashChecklistGroup(checklistGroupId: $checklistGroupId) {
      checklistGroup {
        id
      }
    }
  }
`

export const USE_CHECKLIST_TEMPLATE = gql`
  mutation UseChecklistTemplate($checklistTemplateKey: String!, $projectId: ID!) {
    useChecklistTemplate(checklistTemplateKey: $checklistTemplateKey, projectId: $projectId) {
      createdItemsAndGroups {
        items {
          id
        }
        groups {
          id
        }
      }
    }
  }
`

export const MOVE_CHECKLIST_ITEM = gql`
  mutation MoveChecklistItem($checklistItemId: ID!, $order: Int!, $checklistGroupId: ID) {
    moveChecklistItem(
      checklistItemId: $checklistItemId
      order: $order
      checklistGroupId: $checklistGroupId
    ) {
      checklistItem {
        id
      }
    }
  }
`

export const REORDER_CHECKLIST_GROUP = gql`
  mutation ReorderChecklistGroup($checklistGroupId: ID!, $order: Int!) {
    reorderChecklistGroup(checklistGroupId: $checklistGroupId, order: $order) {
      checklistGroup {
        id
      }
    }
  }
`

export const REORDER_CHECKLIST_ITEM = gql`
  mutation ReorderChecklistItem($checklistItemId: ID!, $order: Int!) {
    reorderChecklistItem(checklistItemId: $checklistItemId, order: $order) {
      checklistItem {
        id
      }
    }
  }
`

export const UPDATE_SUMMARY = gql`
  mutation UpdateProjectSummary(
    $projectSummaryId: ID!
    $currentStatus: String!
    $projectHealth: String!
    $recentActivity: String!
  ) {
    updateProjectSummary(
      projectSummaryId: $projectSummaryId
      currentStatus: $currentStatus
      projectHealth: $projectHealth
      recentActivity: $recentActivity
    ) {
      projectSummary {
        id
        updatedAt
        projectHealth
        recentActivity
      }
    }
  }
`

export const TOGGLE_NEEDS_HELP = gql`
  mutation ToggleNeedsHelp(
    $projectId: ID!
    $needsHelp: Boolean!
    $problemArea: String
    $problemDetails: String
  ) {
    toggleNeedsHelp(
      projectId: $projectId
      needsHelp: $needsHelp
      problemArea: $problemArea
      problemDetails: $problemDetails
    ) {
      project {
        id
        clientPulse {
          needsHelpAt
        }
      }
    }
  }
`

export const CLEAR_NO_RESPONSE_SINCE = gql`
  mutation ClearNoResponseSince($projectId: ID!) {
    clearProjectNoResponseSince(projectId: $projectId) {
      project {
        id
      }
    }
  }
`

export const TOGGLE_MUTE_NOTIFICATIONS = gql`
  mutation ToggleNotifications($projectId: ID!, $mute: Boolean!) {
    toggleNotifications(projectId: $projectId, mute: $mute) {
      project {
        id
        mutedUsers {
          id
        }
      }
    }
  }
`
