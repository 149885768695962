import { gql } from '@apollo/client'

export const GET_ALL_ATTACHMENTS_ALL_CHANNELS = gql`
  query GetAllAttachmentsAllChannels(
    $internalChannelId: ID
    $externalChannelId: ID
    $designChannelId: ID
  ) {
    allAttachmentsAllChannels(
      internalChannelId: $internalChannelId
      externalChannelId: $externalChannelId
      designChannelId: $designChannelId
    ) {
      internalChannelAttachment {
        id
        url
        media {
          id
          filename
          uploadUser {
            id
            firstName
            lastName
          }
          url
          mimetype
          uploadConfirmedAt
          contentSize
          thumbnailUrl
        }
        createdAt
      }
      externalChannelAttachment {
        id
        url
        media {
          id
          filename
          uploadUser {
            id
            firstName
            lastName
          }
          url
          mimetype
          uploadConfirmedAt
          contentSize
          thumbnailUrl
        }
        createdAt
      }
      designChannelAttachment {
        id
        url
        media {
          id
          filename
          uploadUser {
            id
            firstName
            lastName
          }
          url
          mimetype
          uploadConfirmedAt
          contentSize
          thumbnailUrl
        }
        createdAt
      }
    }
  }
`

export const GET_ATTACHMENT = gql`
  query GetAttachment($mediaProjectChannelId: ID!) {
    attachment(mediaProjectChannelId: $mediaProjectChannelId) {
      id
      media {
        id
        filename
        uploadUser {
          id
          firstName
          lastName
        }
        url
        mimetype
        uploadConfirmedAt
        contentSize
        thumbnailUrl
      }
      createdAt
    }
  }
`
