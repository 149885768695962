/* eslint-disable react/no-danger */
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Media from './Media'
import Sender from './Sender'

const useStyles = makeStyles((theme) => ({
  messageTime: {
    marginLeft: theme.spacing(),
    color: theme.palette.grayscale.main || theme.palette.text.third,
  },
  message: {
    marginLeft: theme.spacing(5),
    '& p': {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
    },
    '& p:first-of-type': {
      marginTop: 0,
    },
  },
  postInfo: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  messageBody: {
    display: 'inline-block',
    wordBreak: 'break-word',
  },
  messageEdited: {
    color: theme.palette.text.third,
  },
}))

const ChatMessage = ({ showDate = false, message }) => {
  const classes = useStyles()
  const zone = moment.tz.guess(true)
  const { id, postedAt, messageBody, mediaProjectChannel } = message
  const media = mediaProjectChannel?.media
  const url = mediaProjectChannel?.url || media?.url
  const messageMoment = moment(postedAt).tz(zone)
  const datetimeFormat = showDate ? 'MMM Do [at] h:mm A' : 'h:mm A'

  return (
    <div id={`message-${id}`} style={{ scrollMarginBlockStart: '50px' }}>
      <div className={classes.postInfo}>
        <Sender message={message} />
        <span className={classes.messageTime}>{messageMoment.format(datetimeFormat)}</span>
      </div>
      <div className={classes.message}>
        {media ? (
          <Media url={url} media={media} />
        ) : (
          <>
            <div
              className={classes.messageBody}
              data-test-id="message-body"
              dangerouslySetInnerHTML={window.$mdSanitizedParse(messageBody)}
            />
            {message.editedAt && <span className={classes.messageEdited}> (edited)</span>}
          </>
        )}
      </div>
    </div>
  )
}

ChatMessage.propTypes = {
  showDate: PropTypes.bool,
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    postedAt: PropTypes.string.isRequired,
    editedAt: PropTypes.string,
    messageBody: PropTypes.string.isRequired,
    senderUser: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
    thirdPartySenderUser: PropTypes.shape({
      id: PropTypes.string.isRequired,
      thirdPartyAccountName: PropTypes.string.isRequired,
      user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }).isRequired,
    }),
    receipt: PropTypes.shape({
      isFromSelf: PropTypes.bool,
      readAt: PropTypes.string,
    }),
    mediaProjectChannel: PropTypes.shape({
      url: PropTypes.string,
      media: PropTypes.shape({
        id: PropTypes.string.isRequired,
        filename: PropTypes.string.isRequired,
        mimetype: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  scrollPosition: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
}

export default React.memo(ChatMessage, (prevProps, props) => {
  const { message: prevMsg } = prevProps
  const { message: newMsg } = props

  return (
    prevMsg.id === newMsg.id &&
    prevMsg.postedAt === newMsg.postedAt &&
    prevMsg.editedAt === newMsg.editedAt
  )
})
