import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import { makeStyles } from '@material-ui/core'

import PopButton from '@forms/PopButton'
import PopComboField from '@forms/PopComboField'

import { currentProject } from '@lib/apollo/apolloCache'
import { MOBILE_BREAKPOINT } from '@lib/theme'
import { serializeData } from '@lib/tracking'

import { GET_ACCOUNT_USERS_SIMPLE } from '@graphql/users/queries'

import { TAB_STATES } from '@components_pop/ProjectDetailsTeam/container'

const useStyles = makeStyles((theme) => ({
  userSelectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '15px 0',
  },
  form: {
    width: '100%',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      maxWidth: 420,
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const DEFAULT_MIN_SEARCH_VALUE_LENGTH = 2
const DEFAULT_DEBOUNCE_TIME = 500

export default function UserSelection({
  cancel,
  submitting,
  onSubmit,
  errors,
  setValue,
  userType,
  isValid,
}) {
  const classes = useStyles()
  const [userOptions, setUserOptions] = useState([])
  const [currentUser, setCurrentUser] = useState('')

  const { id: projectId } = useReactiveVar(currentProject)

  const [getEligibleUsers, { data: { accountUsers } = {} }] = useLazyQuery(
    GET_ACCOUNT_USERS_SIMPLE,
    {
      variables: { projectId },
      fetchPolicy: 'network-only',
    }
  )

  const handleSearch = (newValue, newUserType) => {
    getEligibleUsers({
      variables: {
        accountType: newUserType,
        search: newValue?.trim(),
        limit: 15,
      },
    })
  }

  const debounceSearch = useRef(debounce(handleSearch, DEFAULT_DEBOUNCE_TIME))

  const handleOnChange = (_e, user, reason) => {
    if (!['select-option', 'clear'].includes(reason)) {
      return
    }

    if (reason === 'clear') {
      setCurrentUser('')
      setValue('userId', '', { shouldValidate: true })
      return
    }

    setCurrentUser(user.name)
    setValue('userId', user.id, { shouldValidate: true })
    setUserOptions([])
  }

  const handleInputChange = (_e, newValue, reason) => {
    if (reason === 'reset') return

    setUserOptions([])

    if (newValue.length >= DEFAULT_MIN_SEARCH_VALUE_LENGTH) {
      debounceSearch.current(newValue, userType)
    }

    setCurrentUser(newValue)
  }

  const getCorrectLabel = () => {
    switch (userType) {
      case TAB_STATES.ADD_CUSTOMER:
        return 'Creator'
      case TAB_STATES.ATTACH_FACTORY:
        return 'Factory User'
      case TAB_STATES.ATTACH_CONTRACTOR:
        return 'Expert'
      case TAB_STATES.ATTACH_EMPLOYEE:
      default:
        return 'Gembah'
    }
  }

  useEffect(() => {
    if (accountUsers) {
      const newOptions = accountUsers?.accountUsers?.map((user) => {
        return {
          name: user?.fullName ?? 'No available name',
          email: user?.email,
          searchTerms: [user?.fullName?.toLowerCase(), user?.email?.toLowerCase()],
          id: user?.id,
        }
      })

      setUserOptions(newOptions)
    }
  }, [accountUsers, setUserOptions])

  return (
    <div className={classes.userSelectionWrapper}>
      <div className={classes.form}>
        <PopComboField
          name="userId"
          inputValue={currentUser}
          label={getCorrectLabel()}
          placeholder="Select a user"
          options={userOptions}
          onChange={handleOnChange}
          onInputChange={handleInputChange}
          error={!!errors.userId}
          helperText={errors.userId?.message}
          getOptionLabel={(option) => option.name}
          filterOptions={(options) => {
            return options.filter((option) => {
              return option.searchTerms.some((term) => term?.includes(currentUser.toLowerCase()))
            })
          }}
          getOptionSelected={(opt, selected) => opt.id === selected.id}
          getOptionDisabled={(opt) => opt.id === 'no_results'}
          freeSolo
          fullWidth
        />

        <div className={classes.actions}>
          <PopButton
            size="small"
            btnStyle="simple"
            disabled={submitting}
            onClick={cancel}
            data-tracking-info={serializeData({
              id: 'project_team-add-employee-cancel-button_click',
            })}
          >
            discard changes
          </PopButton>

          <PopButton
            loading={submitting}
            disabled={submitting || !isValid}
            size="small"
            onClick={onSubmit}
          >
            Save Changes
          </PopButton>
        </div>
      </div>
    </div>
  )
}

UserSelection.propTypes = {
  cancel: PropTypes.func,
  setValue: PropTypes.func,
  submitting: PropTypes.bool,
  isValid: PropTypes.bool,
  onSubmit: PropTypes.func,
  errors: PropTypes.object,
  userType: PropTypes.string,
}
