import React from 'react'

const FileUploadContext = React.createContext({
  projectId: null,
  channelId: null,
  files: [],
  onOpenFileSelectModal: () => {},
  onGetUploadUrl: () => {},
  onUploadFile: () => {},
  onConfirmUploadAndAttach: () => {},
  isDragActive: false,
  isUploadModalOpen: false,
})

export default FileUploadContext
