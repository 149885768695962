import PropTypes from 'prop-types'
import React from 'react'

import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'

import { COMMON_WHITE } from '@lib/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    margin: `0 ${theme.spacing(0.5)}px`,
    fontSize: '0.85rem',
    fontWeight: 500,
    color: COMMON_WHITE,
    backgroundColor: theme.palette.error.main,
  },
}))

const ChatChannelNotificationBadge = ({ count = 0 }) => {
  const classes = useStyles()

  if (!count) return null

  return <Avatar className={classes.root}>{count}</Avatar>
}

ChatChannelNotificationBadge.propTypes = {
  count: PropTypes.number,
}

export default ChatChannelNotificationBadge
