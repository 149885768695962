import { useMutation, useReactiveVar } from '@apollo/client'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { cache, currentAccountUser, currentProject } from '@lib/apollo/apolloCache'
import { MIME_TYPES_FOR_ICONS } from '@lib/constants'
import { normalizeStringCharsForUrl } from '@lib/dataUtils'
import tracking from '@lib/tracking'
import { getChatChannels } from '@lib/userAuth'

import { DELETE_MEDIA_PROJECT_CHANNEL } from '@graphql/project/attachments/mutators'
import { GET_ALL_ATTACHMENTS_ALL_CHANNELS } from '@graphql/project/attachments/queries'
import { GET_PROJECT_QUERY } from '@graphql/project/queries'

import { TOAST_TYPES } from '@components_pop/Toast'
import GembahConfirmDialog from '@components_pop/dialog/GembahConfirmDialog'

import useDownloadFile from '@hooks/useDownloadFile'
import useToast from '@hooks/useToast'

import ProjectAttachmentMenu from './view'

const ProjectAttachmentMenuContainer = ({
  anchorEl,
  attachment,
  onMenuClose,
  shareWith,
  onShareAttachmentToChannel,
  onSetProjectCoverImage,
}) => {
  const router = useRouter()
  const { projectSlug } = router.query

  const { downloadFile, isDownloading } = useDownloadFile()
  const { addToast } = useToast()
  const project = useReactiveVar(currentProject)
  const accountUser = useReactiveVar(currentAccountUser)
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const [deleteAttachment] = useMutation(DELETE_MEDIA_PROJECT_CHANNEL)
  const isGoogleDrive = attachment?.media?.mimetype === MIME_TYPES_FOR_ICONS.GOOGLE_DRIVE
  const channelPermissions = getChatChannels(project, accountUser)

  const handleDeleteConfirmation = () => {
    tracking.trackEvent({
      id: 'project_attachments-delete-confirmation-modal-confirm-button_click',
      attachmentId: attachment.id,
    })
    const ids = {
      ...(channelPermissions.externalChatChannel && {
        externalChannelId: project.externalChatChannel.id,
      }),
      ...(channelPermissions.internalChatChannel && {
        internalChannelId: project.internalChatChannel.id,
      }),
      ...(channelPermissions.designChatChannel && {
        designChannelId: project.designChatChannel.id,
      }),
    }

    deleteAttachment({
      refetchQueries: [
        {
          query: GET_ALL_ATTACHMENTS_ALL_CHANNELS,
          variables: ids,
        },
        {
          query: GET_PROJECT_QUERY,
          variables: { projectSlug },
        },
      ],
      variables: {
        mediaProjectChannelId: attachment.id,
      },
    }).then(
      (res) => {
        if (res.errors) {
          return
        }

        const trashedMediaProjectChannel =
          res.data?.deleteMediaProjectChannel?.trashedMediaProjectChannel?.message[0]

        if (trashedMediaProjectChannel) {
          cache.evict({ id: cache.identify(trashedMediaProjectChannel) })
          cache.gc()
        }

        setDeleteConfirmationOpen(false)
        onMenuClose()
        addToast({
          message: 'Attachment Successfully Deleted',
          type: TOAST_TYPES.SUCCESS,
        })
      },
      (/* err */) => {}
    )
  }

  const handleDownload = (event) => {
    event.stopPropagation()
    event.preventDefault()
    downloadFile({
      mediaProjectChannelId: attachment.id,
    })
  }

  const handleDeleteClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setDeleteConfirmationOpen(true)
  }

  const handleDeleteCancel = () => {
    tracking.trackEvent({
      id: 'project_attachments-delete-confirmation-modal-cancel-button_click',
      attachmentId: attachment.id,
    })
    setDeleteConfirmationOpen(false)
    onMenuClose()
  }

  const handleCopyUrlToClipboad = async () => {
    const url = normalizeStringCharsForUrl(isGoogleDrive ? attachment.media.url : attachment.url)

    try {
      await navigator.clipboard.writeText(url)

      addToast({
        message: 'Attachment URL Successfully Copied To Clipboard',
        type: TOAST_TYPES.SUCCESS,
      })
      onMenuClose()
    } catch (err) {
      addToast({
        message: 'Attachment URL Could Not Be Copied To Clipboard',
        type: TOAST_TYPES.ERROR,
      })
    }
  }

  return (
    <>
      <ProjectAttachmentMenu
        isGoogleDrive={isGoogleDrive}
        onCopyUrlToClipboad={handleCopyUrlToClipboad}
        isDownloading={isDownloading}
        shareWith={shareWith}
        anchorEl={anchorEl}
        onClose={onMenuClose}
        onHandleDownload={handleDownload}
        onDeleteClick={handleDeleteClick}
        attachment={attachment}
        onShareAttachmentToChannel={onShareAttachmentToChannel}
        onSetProjectCoverImage={onSetProjectCoverImage}
      />
      {Boolean(attachment.id) && deleteConfirmationOpen && (
        <GembahConfirmDialog
          isSuccess={false} // no modal success message to be shown upon deletion - handled by toast
          title={`Are you sure you want to delete ${attachment.media.filename}?`}
          description="This will also remove it from the Chat conversation"
          onAccept={handleDeleteConfirmation}
          onClose={handleDeleteCancel}
        />
      )}
    </>
  )
}

ProjectAttachmentMenuContainer.propTypes = {
  anchorEl: PropTypes.shape({}),
  shareWith: PropTypes.object,
  attachment: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    media: PropTypes.shape({
      id: PropTypes.string,
      filename: PropTypes.string,
      url: PropTypes.string,
      mimetype: PropTypes.string,
    }),
  }),
  onMenuClose: PropTypes.func.isRequired,
  onShareAttachmentToChannel: PropTypes.func,
  onSetProjectCoverImage: PropTypes.func,
}

export default ProjectAttachmentMenuContainer
