import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'

const ButtonSpinnerContent = ({ size, isLoading, children }) => {
  return isLoading ? <FontAwesomeIcon size={size} icon={faSpinner} spin /> : children
}

ButtonSpinnerContent.propTypes = {
  size: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.object,
}

export default ButtonSpinnerContent
