import gql from 'graphql-tag'

export const CHAT_MESSAGE_FRAGMENT = gql`
  fragment ChatMessageFragment on MessageType {
    id
    channel {
      id
    }
    postedAt
    editedAt
    messageBody
    senderUser {
      id
      availability
      firstName
      lastName
      isStaff
      profilePicture {
        url
      }
    }
    receipt {
      id
      isFromSelf
      readAt
      hasAtMention
    }
    thirdPartySenderUser {
      id
      thirdPartyAccountName
      user {
        id
        firstName
        lastName
        isStaff
      }
    }
    mediaProjectChannel {
      id
      url
      media {
        id
        filename
        mimetype
        url
        thumbnailUrl
      }
    }
  }
`

export const GET_CHAT_CHANNEL_MESSAGES = gql`
  query GetChatChannelMessages(
    $channelId: ID!
    $updatedAfter: String
    $postedBefore: String
    $limit: Int
  ) {
    allChannelMessages(
      channelId: $channelId
      updatedAfter: $updatedAfter
      postedBefore: $postedBefore
      limit: $limit
    ) {
      messages {
        ...ChatMessageFragment
      }
      haveOlderMessages
      areNewMessages
    }
    deletedMessages(channelId: $channelId, deletedAfter: $updatedAfter)
  }
  ${CHAT_MESSAGE_FRAGMENT}
`

export const GET_MESSAGE_CHANNEL = gql`
  query ($messageId: ID!) {
    messageChannel(messageId: $messageId) {
      id
    }
  }
`
