import { useQuery } from '@apollo/client'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { NOTIFICATION_TYPE } from '@lib/constants/notifications'

import { GET_NOTIFICATIONS_LOCAL } from '@graphql/notifications/queries'

import ChatChannelNotificationBadge from './view'

const ChatChannelNotificationBadgeContainer = ({ ids }) => {
  const {
    data: {
      notificationsWrapper: { notifications },
    },
  } = useQuery(GET_NOTIFICATIONS_LOCAL)

  const chatChannelNotificationCount = _.filter(notifications, (n) => {
    try {
      return (
        ids.includes(n.channelId) &&
        [NOTIFICATION_TYPE.AT_MENTION, NOTIFICATION_TYPE.CHAT_MESSAGE].includes(n.notificationType)
      )
    } catch (err) {
      return false
    }
  })

  return <ChatChannelNotificationBadge count={chatChannelNotificationCount.length} />
}

ChatChannelNotificationBadgeContainer.propTypes = {
  ids: PropTypes.array.isRequired,
}

export default ChatChannelNotificationBadgeContainer
