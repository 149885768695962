import { useReactiveVar } from '@apollo/client'
import { faMask } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { Button, makeStyles } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

import AddIcon from '@public/svg/icons/add.svg'
import EllipsisIcon from '@public/svg/icons/ellipsis-icon.svg'
import EllipsisVertivalIcon from '@public/svg/icons/ellipsis-vertical-icon.svg'

import { currentAccountUser, currentUser, featureFlags } from '@lib/apollo/apolloCache'
import { SECONDARY_BLUE_BACKGROUND, SECONDARY_GREY } from '@lib/colors'
import { MOBILE_BREAKPOINT } from '@lib/theme'
import { serializeData } from '@lib/tracking'
import { ACCOUNT_TYPES, hasPermission, isProjectManager, switchTo } from '@lib/userAuth'
import withPerms from '@lib/withPerms'

import ContentCard from '@components_pop/ContentCard'
import OutOfOfficeTooltip from '@components_pop/OutOfOfficeTooltip'
import SvgLoader from '@components_pop/SvgLoader'
import UserLetterAvatar from '@components_pop/users/UserLetterAvatar'

import useBreakpoint from '@hooks/useBreakpoint'

const useStyles = makeStyles((theme) => ({
  listItemAvatarRoot: {
    minWidth: 40,
    paddingRight: 10,
  },
  mobileListItemTextPrimary: {
    lineHeight: 'normal',
  },
  primaryText: {
    display: 'inline-block', // this is necessary for alignment with the desktop menu button
  },
  mobileListItemTextSecondary: {
    lineHeight: 'normal',
  },
  secondaryText: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: '0.75rem',
    display: 'flex',
    alignItems: 'center',
  },
  headerButton: {
    marginLeft: theme.spacing(1),
    '& > span > div': {
      width: 32,
      height: 32,
    },
  },
  icon: {
    width: 20,
    height: 20,
  },
  secondaryActionRoot: {
    right: 0,
  },
  nameText: {
    color: theme.palette.text.primary,
    display: 'inline-block',
    fontWeight: 700,
    marginRight: '.5rem',
  },
  emailText: {
    color: theme.palette.grayscale.third,
    fontSize: '0.875rem',
  },
  fab: {
    ...theme.palette.pop,
    position: 'fixed',
    fontSize: '1.5rem',
    bottom: 100,
    right: 24,
    zIndex: 1,
  },
  memberGroupTitle: {
    padding: `${theme.spacing(3)}px 0 0 ${theme.spacing(2)}`,
    '&:first-of-type': {
      paddingTop: 0,
    },
  },
  memberList: {
    paddingTop: 0,
  },
  listItem: {
    paddingLeft: theme.spacing(2),
    flexWrap: 'wrap',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      flexWrap: 'nowrap',
      height: '4rem',
    },
    '& .desktop-menu-button': {
      color: theme.palette.common.white,
    },
    '&:hover .desktop-menu-button': {
      color: SECONDARY_GREY,
    },
  },
  desktopMenuButtonRoot: {
    top: 'auto',
    left: 'auto',
    marginLeft: theme.spacing(),
    fontSize: '1.2rem',
  },
  mobileEllipsisText: {
    width: '68vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  memberMenuIconRoot: {
    minWidth: 0,
    paddingRight: 10,
  },
  // checkbox
  checkboxRoot: {
    color: SECONDARY_BLUE_BACKGROUND,
    '&$checked': {
      color: SECONDARY_BLUE_BACKGROUND,
    },
  },
  checkboxLabel: {
    textTransform: 'uppercase',
    fontSize: '0.8rem',
  },
  checked: {},
  btnSwitchUser: {
    padding: '0 10px',
    margin: 0,
    minWidth: 0,
    height: 17,
    marginLeft: theme.spacing(0.5),
  },
}))

const PrimaryText = ({ member, userType, isMobile }) => {
  const classes = useStyles()
  const rootClass = clsx(classes.primaryText, {
    [classes.mobileEllipsisText]: Boolean(isMobile),
  })

  return (
    <div className={rootClass}>
      <span
        className={classes.nameText}
        id={`project-team-member--${userType}--full-name--${member.id}`}
      >
        {member.fullName}
      </span>

      <span
        className={classes.emailText}
        data-test-id={`project-team-member--${userType}--email--${member.id}`}
      >
        {member.email}
      </span>
    </div>
  )
}

PrimaryText.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    title: PropTypes.string,
    staffUserId: PropTypes.string,
    customerId: PropTypes.string,
    contractorId: PropTypes.string,
  }),
  userType: PropTypes.string,
  isMobile: PropTypes.bool,
}

const SecondaryText = ({ member, isMobile }) => {
  const classes = useStyles()
  const { isMasqueradeActive } = featureFlags()

  let userTypeString = ''
  if (member.staffUserId) {
    userTypeString = 'Gembah Team'
  } else if (member.customerId) {
    userTypeString = 'Client'
  } else if (member.contractorId) {
    userTypeString = 'Contractor'
  }
  const className = clsx(classes.secondaryText, {
    [classes.mobileEllipsisText]: Boolean(isMobile),
  })

  return (
    <span className={className}>
      {userTypeString}
      {member.title ? ` - ${member.title}` : ''}
      {isMasqueradeActive && (
        <Button
          className={classes.btnSwitchUser}
          variant="outlined"
          data-tracking-info={serializeData({
            id: `project_team-masquerade-as_click`,
            teamMemberId: member.id,
          })}
          onClick={(event) => switchTo(event, member)}
        >
          <FontAwesomeIcon icon={faMask} />
        </Button>
      )}
    </span>
  )
}

SecondaryText.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    title: PropTypes.string,
    staffUserId: PropTypes.string,
    customerId: PropTypes.string,
    contractorId: PropTypes.string,
  }),
  isMobile: PropTypes.bool,
  user: PropTypes.shape({
    groups: PropTypes.any,
  }),
}

const AllowChatWithDesignForClientCheckbox = ({ member, toggleProjectDesignChatCustomer }) => {
  const classes = useStyles()
  const [checked, setChecked] = useState(member.designChatChannelSubscribed)
  const [isLoading, setIsLoading] = useState(false)

  const handleAllowChatWithStakeholders = () => {
    setIsLoading(true)
    toggleProjectDesignChatCustomer(member.userId, !checked, (isAuthorized) => {
      setChecked(isAuthorized)
      setIsLoading(false)
    })
  }

  return (
    <FormControlLabel
      classes={{ label: classes.checkboxLabel }}
      data-test-id={`project-details-team-toggle-chat-design-${member.email}`}
      control={
        <Checkbox
          disabled={isLoading}
          color="primary"
          classes={{ root: classes.checkboxRoot, checked: classes.checked }}
          checked={checked}
          onChange={handleAllowChatWithStakeholders}
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          data-tracking-info={serializeData({
            id: `project_team-allow-client-chat-design_click`,
            teamMemberId: member.id,
            wasChecked: checked,
          })}
        />
      }
      label="Allow chat with stakeholders"
    />
  )
}

AllowChatWithDesignForClientCheckbox.propTypes = {
  member: PropTypes.object,
  toggleProjectDesignChatCustomer: PropTypes.func,
}

const AllowChatWithClientCheckbox = ({
  member,
  grantExternalChannelAccess,
  revokeExternalChannelAccess,
}) => {
  const classes = useStyles()
  const [checked, setChecked] = useState(member.externalChatChannelSubscribed)

  const handleAllowClientChange = () => {
    const method = checked ? revokeExternalChannelAccess : grantExternalChannelAccess

    method(member.userId, () => setChecked(!checked))
  }

  return (
    <FormControlLabel
      classes={{ label: classes.checkboxLabel }}
      data-test-id={`project-details-team-toggle-chat-${member.email}`}
      control={
        <Checkbox
          color="primary"
          classes={{ root: classes.checkboxRoot, checked: classes.checked }}
          checked={checked}
          onChange={handleAllowClientChange}
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          data-tracking-info={serializeData({
            id: `project_team-allow-client-chat_click`,
            teamMemberId: member.id,
            wasChecked: checked,
          })}
        />
      }
      label="Allow chat with client"
    />
  )
}

AllowChatWithClientCheckbox.propTypes = {
  member: PropTypes.object,
  grantExternalChannelAccess: PropTypes.func,
  revokeExternalChannelAccess: PropTypes.func,
}

const MemberGroupList = ({
  group,
  userType,
  onMenuOpen,
  toggleProjectDesignChatCustomer,
  grantExternalChannelAccess,
  revokeExternalChannelAccess,
}) => {
  const classes = useStyles()
  const user = useReactiveVar(currentUser)
  const accountUser = useReactiveVar(currentAccountUser)
  const { isMobile } = useBreakpoint()

  if (!group) return null

  const isMe = (u) => accountUser.id === u.id

  const isMenuVisible = (member) =>
    hasPermission.GEMBAH_OR_EXPERT(accountUser) && isProjectManager(accountUser, member)

  const isClientCheckboxVisible = (member) =>
    hasPermission.GEMBAH(accountUser) && hasPermission.CREATOR(member)

  const isCheckboxVisible = (member) =>
    (hasPermission.EXPERT(member) || hasPermission.FACTORY(member)) &&
    !isMe(member) &&
    hasPermission.GEMBAH(accountUser) &&
    isProjectManager(accountUser, member)

  if (isMobile) {
    return group.map((member, i) => {
      return (
        <React.Fragment key={member.id}>
          <ListItem className={classes.listItem} key={member.id}>
            <div style={{ display: 'flex' }}>
              <ListItemAvatar classes={{ root: classes.listItemAvatarRoot }}>
                <OutOfOfficeTooltip user={member} isForAvatar>
                  <UserLetterAvatar
                    alt={member.fullName}
                    src={member.profilePicture?.url}
                    dimensions={5}
                    firstName={member.firstName}
                    lastName={member.lastName}
                  />
                </OutOfOfficeTooltip>
              </ListItemAvatar>
              <ListItemText
                classes={{
                  primary: classes.mobileListItemTextPrimary,
                  secondary: classes.mobileListItemTextSecondary,
                }}
                primary={<PrimaryText member={member} userType={userType} isMobile />}
                secondary={<SecondaryText user={user} member={member} isMobile />}
              />
              <ListItemSecondaryAction classes={{ root: classes.secondaryActionRoot }}>
                {isMenuVisible(member) && (
                  <IconButton
                    aria-label="options"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={onMenuOpen(member)}
                    data-tracking-info={serializeData({
                      id: `project_team-member-menu-button_click`,
                      teamMemberId: member.id,
                    })}
                  >
                    <SvgLoader {...EllipsisIcon} className={classes.icon} />
                  </IconButton>
                )}
                {isCheckboxVisible(member) && (
                  <AllowChatWithClientCheckbox
                    member={member}
                    grantExternalChannelAccess={grantExternalChannelAccess}
                    revokeExternalChannelAccess={revokeExternalChannelAccess}
                  />
                )}
              </ListItemSecondaryAction>
            </div>
            {isClientCheckboxVisible(member) && (
              <AllowChatWithDesignForClientCheckbox
                member={member}
                toggleProjectDesignChatCustomer={toggleProjectDesignChatCustomer}
              />
            )}
          </ListItem>
          {i < group.length - 1 ? <Divider /> : null}
        </React.Fragment>
      )
    })
  }

  return group.map((member, i) => {
    return (
      <React.Fragment key={member.id}>
        <ListItem
          className={classes.listItem}
          key={member.id}
          data-test-id={`project-team-member--${member.id}`}
        >
          <ListItemAvatar classes={{ root: classes.listItemAvatarRoot }}>
            <OutOfOfficeTooltip user={member} isForAvatar>
              <UserLetterAvatar
                alt={member.fullName}
                src={member.profilePicture?.url}
                dimensions={5}
                firstName={member.firstName}
                lastName={member.lastName}
              />
            </OutOfOfficeTooltip>
          </ListItemAvatar>
          <ListItemText
            primary={<PrimaryText member={member} userType={userType} />}
            secondary={<SecondaryText user={user} member={member} />}
          />
          {isMenuVisible(member) && (
            <IconButton
              className="desktop-menu-button"
              size="medium"
              classes={{ root: classes.desktopMenuButtonRoot }}
              aria-label="options"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={onMenuOpen(member)}
              data-tracking-info={serializeData({
                id: `project_team-member-menu-button_click`,
                teamMemberId: member.id,
              })}
              data-test-id={`project-team-member--menu-button--${member.id}`}
            >
              <SvgLoader {...EllipsisVertivalIcon} className={classes.icon} />
            </IconButton>
          )}
          {isClientCheckboxVisible(member) && (
            <AllowChatWithDesignForClientCheckbox
              member={member}
              toggleProjectDesignChatCustomer={toggleProjectDesignChatCustomer}
            />
          )}
          {isCheckboxVisible(member) && (
            <AllowChatWithClientCheckbox
              member={member}
              grantExternalChannelAccess={grantExternalChannelAccess}
              revokeExternalChannelAccess={revokeExternalChannelAccess}
            />
          )}
        </ListItem>
        {i < group.length - 1 ? <Divider /> : null}
      </React.Fragment>
    )
  })
}

MemberGroupList.propTypes = {
  group: PropTypes.array,
  userType: PropTypes.string,
  onMenuOpen: PropTypes.func,
  toggleProjectDesignChatCustomer: PropTypes.func,
  grantExternalChannelAccess: PropTypes.func,
  revokeExternalChannelAccess: PropTypes.func,
}

const ProjectDetailsTeamView = ({
  team,
  tabStateHandler: { goToSelectOption },
  onMenuOpen,
  toggleProjectDesignChatCustomer,
  grantExternalChannelToContractor,
  revokeExternalChannelFromContractor,
}) => {
  const classes = useStyles()

  const AddButton = () => (
    <IconButton
      className={classes.headerButton}
      onClick={goToSelectOption}
      data-tracking-info={serializeData({
        id: `project_team-add-button_click`,
      })}
      size="square"
      data-test-id="project-details--team--add-member-button"
    >
      <SvgLoader {...AddIcon} />
    </IconButton>
  )

  const ProtectedAddButton = withPerms(AddButton)
  const teamGroups = _.groupBy(team, (member) => member?.account?.type)

  return (
    <ContentCard
      action={<ProtectedAddButton permissionTest={hasPermission.GEMBAH} />}
      transparentHeader
    >
      <List className={classes.memberList}>
        <ListItem className={classes.memberGroupTitle} key="customer-group-title">
          CLIENTS
        </ListItem>
        <div data-test-id="project-details--team--client-list">
          <MemberGroupList
            group={teamGroups[ACCOUNT_TYPES.CREATOR]}
            userType={ACCOUNT_TYPES.CREATOR}
            onMenuOpen={onMenuOpen}
            toggleProjectDesignChatCustomer={toggleProjectDesignChatCustomer}
          />
        </div>

        <ListItem className={classes.memberGroupTitle} key="staff-group-title">
          GEMBAH TEAM
        </ListItem>
        <div data-test-id="project-details--team--staff-list">
          <MemberGroupList
            group={teamGroups[ACCOUNT_TYPES.GEMBAH]}
            userType={ACCOUNT_TYPES.GEMBAH}
            onMenuOpen={onMenuOpen}
          />
        </div>

        {Boolean(teamGroups[ACCOUNT_TYPES.EXPERT]) && (
          <ListItem className={classes.memberGroupTitle} key="contractor-group-title">
            EXPERTS
          </ListItem>
        )}
        <MemberGroupList
          group={teamGroups[ACCOUNT_TYPES.EXPERT]}
          userType={ACCOUNT_TYPES.EXPERT}
          grantExternalChannelAccess={grantExternalChannelToContractor}
          revokeExternalChannelAccess={revokeExternalChannelFromContractor}
          onMenuOpen={onMenuOpen}
        />

        {Boolean(teamGroups[ACCOUNT_TYPES.FACTORY]) && (
          <ListItem className={classes.memberGroupTitle} key="factory-group-title">
            FACTORY USERS
          </ListItem>
        )}
        <MemberGroupList
          group={teamGroups[ACCOUNT_TYPES.FACTORY]}
          userType={ACCOUNT_TYPES.FACTORY}
          grantExternalChannelAccess={grantExternalChannelToContractor}
          revokeExternalChannelAccess={revokeExternalChannelFromContractor}
          onMenuOpen={onMenuOpen}
        />
      </List>
    </ContentCard>
  )
}

ProjectDetailsTeamView.propTypes = {
  team: PropTypes.arrayOf(PropTypes.shape({})),
  tabStateHandler: PropTypes.shape({
    goToSelectOption: PropTypes.func.isRequired,
  }),
  onMenuOpen: PropTypes.func.isRequired,
  grantExternalChannelToContractor: PropTypes.func.isRequired,
  revokeExternalChannelFromContractor: PropTypes.func.isRequired,
  toggleProjectDesignChatCustomer: PropTypes.func.isRequired,
}

export default ProjectDetailsTeamView
