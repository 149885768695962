import {
  GRAY_WII,
  GREEN_MOON_SHOES,
  PURPLE_BOP_IT,
  RED_POWERRANGERS,
  RED_SURPRISE,
} from '@lib/colors'

export const NPI_VISUAL_DATE_FORMAT = 'MMM DD, YYYY'
export const EMPTY_STATE = '-'

export const DUE_DATE_UPDATE_REASON = {
  DELAY_CLIENT: 'DELAY_CLIENT',
  DELAY_EXPERT: 'DELAY_EXPERT',
  DELAY_FACTORY: 'DELAY_FACTORY',
  DELAY_GEMBAH: 'DELAY_GEMBAH',
  DELAY_SAMPLE: 'DELAY_SAMPLE',
  MOVED_UP: 'MOVED_UP',
  SCOPE_CHANGE: 'SCOPE_CHANGE',
}

export const DUE_DATE_UPDATE_REASON_LABELS = {
  [DUE_DATE_UPDATE_REASON.DELAY_CLIENT]: 'Delay - Client',
  [DUE_DATE_UPDATE_REASON.DELAY_EXPERT]: 'Delay - Expert',
  [DUE_DATE_UPDATE_REASON.DELAY_FACTORY]: 'Delay - Factory',
  [DUE_DATE_UPDATE_REASON.DELAY_GEMBAH]: 'Delay - Gembah',
  [DUE_DATE_UPDATE_REASON.DELAY_SAMPLE]: 'Delay - Sample',
  [DUE_DATE_UPDATE_REASON.MOVED_UP]: 'Moved Up',
  [DUE_DATE_UPDATE_REASON.SCOPE_CHANGE]: 'Scope Change',
}

export const NPI_MENU_TYPE_ITEMS = {
  MILESTONE: 'MILESTONE',
  DELIVERABLE: 'DELIVERABLE',
  TASK: 'TASK',
}

export const NPI_MENU_ACTION_TYPES = {
  EDIT: 'EDIT',
  EDIT_DATE: 'EDIT_DATE',
  MOVE: 'MOVE',
}

export const USUAL_ROLES_MAP = {
  Client: {
    code: 'Client',
    name: 'Client',
  },
  Supplier: {
    code: 'Supplier',
    name: 'Supplier',
  },
}

export const MANAGEMENT_ROLES_MAP = {
  CSM: {
    code: 'CSM',
    name: 'Client Success Manager',
  },
  CSTL: {
    code: 'CSTL',
    name: 'Client Success Team Lead',
  },
  DPM: {
    code: 'DPM',
    name: 'Design Project Manager',
  },
  VSM: {
    code: 'VSM',
    name: 'Vendor Success Manager / Specialist (JR)',
  },
  SPM: {
    code: 'SPM',
    name: 'Sourcing Project Manager',
  },
  OPM: {
    code: 'OPM',
    name: 'Operations Project Manager',
  },
  ASM: {
    code: 'ASM',
    name: 'Advanced Sourcing Project Manager',
  },
  LPM: {
    code: 'LPM',
    name: 'Logistics Project Manager',
  },
  SDPM: {
    code: 'SDPM',
    name: 'Senior Design Program Manager',
  },
  SOPM: {
    code: 'SOPM',
    name: 'Senior Operations Program Manager',
  },
  SVPM: {
    code: 'SVPM',
    name: 'Senior Vendor Program Manager',
  },
  HSC: {
    code: 'HSC',
    name: 'Head of Supply Chain',
  },
  GEM_MGT: {
    code: 'GEM_MGT',
    name: 'Gembah Management',
  },
}

export const MANAGEMENT_ROLES_SHORTHANDS = [...Object.keys(MANAGEMENT_ROLES_MAP)]

export const DELIVERABLE_REVIEW_RESULTS = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
}

export const DELIVERABLE_TYPES = {
  FILE_UPLOAD: 'FILE_UPLOAD',
  TEXT_SUMMARY: 'TEXT_SUMMARY',
  CERTIFY_COMPLETED: 'CERTIFY_COMPLETED',
}

export const DELIVERABLE_STATUSES = {
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  SUBMITTED: 'SUBMITTED',
  PENDING_REVIEW: 'PENDING_REVIEW',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
  REMOVED: 'REMOVED',
}

export const NPI_STATUS_HIERACHY = {
  DONE: 0,
  TO_DO: 1,
  IN_PROGRESS: 2,
  LATE: 3,
  VERY_LATE: 4,
}

export const NPI_STATUS_HIERARCHY_TEXT = {
  [NPI_STATUS_HIERACHY.TO_DO]: 'Not Started',
  [NPI_STATUS_HIERACHY.IN_PROGRESS]: 'In Progress',
  [NPI_STATUS_HIERACHY.DONE]: 'Completed',
  [NPI_STATUS_HIERACHY.LATE]: '1 day late',
  [NPI_STATUS_HIERACHY.VERY_LATE]: 'More than 1 day late',
}

export const NPI_STATUS_HIERARCHY_COLORS = {
  [NPI_STATUS_HIERACHY.LATE]: RED_SURPRISE,
  [NPI_STATUS_HIERACHY.VERY_LATE]: RED_POWERRANGERS,
  [NPI_STATUS_HIERACHY.IN_PROGRESS]: PURPLE_BOP_IT,
  [NPI_STATUS_HIERACHY.DONE]: GREEN_MOON_SHOES,
  [NPI_STATUS_HIERACHY.TO_DO]: GRAY_WII,
}

export const NPI_PROJECT_STATES_IDS = {
  NEW: 'new',
  ACTIVE: 'active',
  ON_HOLD: 'on_hold',
  ARCHIVED: 'archived',
}

export const NPI_PROJECT_STATES = [
  { name: 'New', id: NPI_PROJECT_STATES_IDS.NEW },
  { name: 'Active', id: NPI_PROJECT_STATES_IDS.ACTIVE },
  { name: 'On Hold', id: NPI_PROJECT_STATES_IDS.ON_HOLD },
  { name: 'Archived', id: NPI_PROJECT_STATES_IDS.ARCHIVED },
]
