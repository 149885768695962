/* eslint-disable react/jsx-props-no-spreading */
import { useMutation, useReactiveVar } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers'
import clsx from 'clsx'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { makeStyles } from '@material-ui/core/styles'

import TomorrowCallendarIcon from '@public/svg/icons/tomorrow-calendar-icon.svg'

import { RootDialog, RootDialogActions, RootDialogContent } from '@dialog/RootDialog'

import PopButton from '@forms/PopButton'
import PopDatePicker from '@forms/PopDatePicker'
import PopSelectField from '@forms/PopSelectField'

import { currentProject } from '@lib/apollo/apolloCache'
import { GRAY_GAMEBOY, GRAY_NES, GRAY_PLAYSTATION, TEAL_DOLLHOUSE, TEAL_ROBOTS } from '@lib/colors'
import { FONTS, MOBILE_BREAKPOINT } from '@lib/theme'
import { serializeData } from '@lib/tracking'

import { UPDATE_NPI_MILESTONE_DURATION } from '@graphql/npi/mutators'
import { GET_NPI_STEPS } from '@graphql/npi/queries'
import { GET_PROJECT_QUERY } from '@graphql/project/queries'

import {
  DUE_DATE_UPDATE_REASON,
  DUE_DATE_UPDATE_REASON_LABELS,
} from '@components_pop/ProjectDetailsNPI/constants'
import { TOAST_TYPES } from '@components_pop/Toast'
import GembahDialogTitle from '@components_pop/dialog/GembahDialogTitle'

import useToast from '@hooks/useToast'

const VALIDATION_SCHEMA = Yup.object().shape({
  newDueDate: Yup.string().trim().required('Due Date is required'),
  reason: Yup.string().required('Reason is required'),
})

const useModalStyles = makeStyles((theme) => ({
  datesDetailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    paddingBottom: 20,
    borderBottom: `1px solid ${GRAY_NES}`,
  },
  dateDetailsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
    fontSize: 16,
    flexDirection: 'column',
    '&:last-child': {
      marginBottom: 0,
    },
    '& > span:first-child': {
      fontFamily: FONTS.MERRI_WEATHER,
      color: TEAL_DOLLHOUSE,
    },
    '& > span:last-child': {
      color: GRAY_PLAYSTATION,
    },
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      flexDirection: 'row',
    },
  },
  fieldsWrapper: {
    display: 'flex',
    marginBottom: 20,
    paddingBottom: 20,
    borderBottom: `1px solid ${GRAY_NES}`,
    flexDirection: 'column',
    '& > div:first-child': {
      marginRight: 50,
    },
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      flexDirection: 'row',
      paddingBottom: 150,
    },
  },
  noValueSelect: {
    '& > select': {
      fontStyle: 'italic',
      fontWeight: 400,
      color: GRAY_GAMEBOY,
    },
  },
  newEstimateDate: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    fontSize: 16,
    '& > span:first-child': {
      fontFamily: FONTS.MERRI_WEATHER,
      color: TEAL_DOLLHOUSE,
    },
    '& > span:last-child': {
      color: TEAL_ROBOTS,
    },
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      flexDirection: 'row',
      fontSize: 20,
    },
  },
  newEstimateDateDisabled: {
    opacity: 0.5,
  },
}))

const NpiEditDateModal = ({ onCloseModal, itemOptions }) => {
  const classes = useModalStyles()
  const { addToast } = useToast()
  const {
    dueDate: projectDueDate,
    id: projectId,
    slug: projectSlug,
  } = useReactiveVar(currentProject)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const [callUpdateNpiMilestoneDuration] = useMutation(UPDATE_NPI_MILESTONE_DURATION, {
    refetchQueries: [
      { query: GET_NPI_STEPS, variables: { projectId } },
      { query: GET_PROJECT_QUERY, variables: { projectSlug } },
    ],
  })

  const currentItem = itemOptions.item

  const { handleSubmit, register, setValue, errors, watch, formState } = useForm({
    mode: 'onChange',
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: {
      newDueDate: null,
      reason: '',
    },
  })

  const { isValid } = formState

  const { reason, newDueDate } = watch()

  const handleSave = (formValues) => {
    setIsSubmitted(true)

    callUpdateNpiMilestoneDuration({
      variables: {
        milestoneId: currentItem.id,
        dueDate: formValues.newDueDate.split('T')[0],
        reason: formValues.reason,
      },
    })
      .then((res) => {
        if (res.errors) return

        addToast({
          message: 'Milestone due date has been successfully updated',
          type: TOAST_TYPES.SUCCESS,
        })

        onCloseModal()
      })
      .finally(() => {
        setIsSubmitted(false)
      })
  }

  useEffect(() => {
    register({ name: 'newDueDate' }, { required: 'Due Date is required' })
  }, [register])

  const calculateCorrectPossibleDueDate = () => {
    if (!currentItem?.dueAt || !newDueDate) return '-'

    const momentCurrentDueAt = moment(currentItem.dueAt).utcOffset(0, true)
    const momentNewDate = moment(newDueDate).utcOffset(0, true)
    const momentProjectDueDate = moment(projectDueDate).utcOffset(0, true)

    const diff = Math.round(momentCurrentDueAt.diff(momentNewDate, 'days', true))
    const newPossibleDate =
      diff < 0
        ? momentProjectDueDate.add(-diff, 'days')
        : momentProjectDueDate.subtract(diff, 'days')

    return newPossibleDate.format('MMMM DD, YYYY')
  }

  return (
    <RootDialog
      open
      fullWidth
      maxWidth="md"
      onClose={(__, modalReason) => {
        if (modalReason === 'backdropClick') return
        onCloseModal()
      }}
      data-test-id="npi--edit-milestone-date--dialog"
    >
      <GembahDialogTitle onClose={onCloseModal} disabled={isSubmitted} icon={TomorrowCallendarIcon}>
        Edit Date
      </GembahDialogTitle>
      <RootDialogContent>
        <div>
          <div className={classes.datesDetailsWrapper}>
            <div className={classes.dateDetailsRow}>
              <span>Current Milestone Due Date:</span>
              <span>
                {currentItem?.dueAt ? moment(currentItem?.dueAt).format('MMM DD, YYYY') : '-'}
              </span>
            </div>
            <div className={classes.dateDetailsRow}>
              <span>Current Project Due Date:</span>
              <span>{projectDueDate ? moment(projectDueDate).format('MMM DD, YYYY') : '-'}</span>
            </div>
          </div>

          <div className={classes.fieldsWrapper}>
            <PopDatePicker
              data-test-id="npi--edit-milestone-date-datepicker"
              format="MMM-DD-YYYY"
              onChange={(date) => {
                setValue('newDueDate', date.utcOffset(0, true).toISOString())
              }}
              value={newDueDate}
              label="New Milestone Due Date"
              initialFocusedDate={currentItem?.dueAt}
              inputProps={{
                placeholder: moment(currentItem?.dueAt).format('MMM-DD-YYYY'),
              }}
              name="newDueDate"
              disablePast
              autoOk
              fullWidth
              required
            />
            <PopSelectField
              data-test-id="npi--edit-milestone-date-reason"
              ref={register}
              label="Reason"
              name="reason"
              error={!!errors.reason}
              customClasses={clsx({
                [classes.noValueSelect]: !reason,
              })}
              required
              fullWidth
            >
              <option value="" disabled>
                Select a reason
              </option>
              {Object.keys(DUE_DATE_UPDATE_REASON).map((key) => (
                <option key={key} value={key}>
                  {DUE_DATE_UPDATE_REASON_LABELS[key]}
                </option>
              ))}
            </PopSelectField>
          </div>

          <div
            className={clsx(classes.newEstimateDate, {
              [classes.newEstimateDateDisabled]: !newDueDate,
            })}
          >
            <span>New Project Due Date</span>

            <span>{calculateCorrectPossibleDueDate()}</span>
          </div>
        </div>
      </RootDialogContent>
      <RootDialogActions>
        <PopButton
          onClick={onCloseModal}
          disabled={isSubmitted}
          data-tracking-info={serializeData({
            id: `npi_edit-item-modal-cancel-button_click`,
          })}
          size="small"
          btnStyle="simple"
        >
          discard changes
        </PopButton>
        <PopButton
          size="small"
          loading={isSubmitted}
          disabled={!isValid || isSubmitted}
          onClick={handleSubmit(handleSave)}
          data-tracking-info={serializeData({
            id: `npi_edit-item-modal-save-button_click`,
          })}
        >
          update project
        </PopButton>
      </RootDialogActions>
    </RootDialog>
  )
}

NpiEditDateModal.propTypes = {
  onCloseModal: PropTypes.func,
  itemOptions: PropTypes.shape({
    item: PropTypes.object,
    type: PropTypes.string,
  }),
}

export default NpiEditDateModal
