import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import { makeStyles } from '@material-ui/core/styles'

import { COMMON_WHITE, PRIMARY_GREY_BACKGROUND } from '@lib/colors'
import { MOBILE_BREAKPOINT } from '@lib/theme'

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    backgroundColor: (props) => (props.transparentHeader ? COMMON_WHITE : PRIMARY_GREY_BACKGROUND),
    minHeight: 56,
    padding: '0 .5rem 0 1rem',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      flexDirection: 'row',
    },
  },
  title: {
    fontSize: '1rem',
    lineHeight: '24px',
    fontWeight: 700,
  },
  card: {
    margin: 0,
    border: 'none',
  },
  action: {
    margin: 0,
    alignSelf: 'flex-end',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      alignSelf: 'auto',
    },
  },
  content: {
    alignSelf: 'baseline',
  },
}))

const ContentCard = ({
  children,
  title,
  action,
  className,
  transparentHeader,
  additionalContent,
  dataTestId,
}) => {
  const classes = useStyles({ transparentHeader })
  const cardClasses = clsx(classes.card, className)

  return (
    <Card data-test-id={dataTestId} className={cardClasses} variant="outlined">
      <CardHeader
        classes={{
          root: classes.cardHeader,
          title: classes.title,
          action: classes.action,
          content: classes.content,
        }}
        title={title}
        action={action}
        subheader={additionalContent}
      />
      <div>{children}</div>
    </Card>
  )
}

ContentCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  dataTestId: PropTypes.string,
  action: PropTypes.node,
  additionalContent: PropTypes.node,
  className: PropTypes.string,
  transparentHeader: PropTypes.bool,
}

export default ContentCard
