import { useReactiveVar } from '@apollo/client'
import _ from 'lodash'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from 'react'
import { trackWindowScroll } from 'react-lazy-load-image-component'

import { currentProject, currentUser } from '@lib/apollo/apolloCache'

import ChatItemMenu from '@components_pop/chat/ChatItemMenu'

import ChatChannelWindow from './view'

const CHAT_MESSAGE_LINE_HEIGHT = 50 // approx height of sender user name + one line of chat

// Although this ref isn't used, adding it silences a warning triggered because
// the `trackWindowScroll` HOC tries to pass a ref to this component
const ChatChannelWindowContainer = React.forwardRef(
  (
    {
      channelId,
      messages,
      isLoadingOlderMessages,
      messageCountPerBatch,
      fetchOlderMessages,
      onMarkMessageAsRead,
      hasOlderMessages,
    },
    // eslint-disable-next-line no-unused-vars
    ref
  ) => {
    const router = useRouter()
    const { id: projectId } = useReactiveVar(currentProject)
    const { id: userId } = useReactiveVar(currentUser)
    const queryMessageId = router.query.messageid
    const CHAT_WINDOW_ELEMENT_ID = `chat-channel-window-${channelId}`
    const [messageMenuAnchorEl, setMessageMenuAnchorEl] = useState(null)
    const [menuMessageId, setMenuMessageId] = useState(null)
    const [editMessageId, setEditMessageId] = useState(null)
    const earliestUnreadMessage = useRef(null)
    const [showUnreadMessagesPill, setShowUnreadMessagesPill] = useState(false)
    const firstMessageId = useRef(null)
    const latestMessageId = useRef(null)
    const scrolledToMessageId = useRef(null)
    const hasNewMessages = useRef(false)
    const wasLastScrolledByUser = useRef(false)
    const wasLastScrolledToMessageById = useRef(false)
    const isScrolledToBottom = useRef(true)

    const chatWinRef = React.createRef()

    const handleMessageMenuOpen = (messageId) => (event) => {
      setMenuMessageId(messageId)
      setMessageMenuAnchorEl(event.currentTarget)
    }

    const handleMessageMenuClose = () => {
      setEditMessageId(null)
      setMenuMessageId(null)
      setMessageMenuAnchorEl(null)
    }

    const handleMessageEditOptionClick = () => {
      setEditMessageId(menuMessageId)
    }

    const handleEditMessageClose = () => {
      setEditMessageId(null)
    }

    /**
     * Check if a message is in the viewport by message id
     */
    const isMessageInViewport = (messageId) => {
      const messageDOMRect = document
        .getElementById(`message-${messageId}`)
        ?.getBoundingClientRect()
      const windowMargin = 8 // Some reasonable margin for the window viewport calculations.

      if (chatWinRef.current && messageDOMRect) {
        const chatWinDOMRect = chatWinRef.current.getBoundingClientRect()
        const chatWindowTop = chatWinDOMRect.top - windowMargin
        const chatWindowBottom = chatWinDOMRect.bottom + windowMargin

        const firstLineMessageIsVisibleInChatViewport =
          messageDOMRect.top >= chatWindowTop &&
          messageDOMRect.top <= chatWindowBottom - CHAT_MESSAGE_LINE_HEIGHT

        return firstLineMessageIsVisibleInChatViewport
      }

      return false
    }

    /**
     * Check if a message is above the viewport by message id
     */
    const isMessageAboveViewport = (messageId) => {
      const messageDOMRect = document
        .getElementById(`message-${messageId}`)
        ?.getBoundingClientRect()
      const windowMargin = 8 // Some reasonable margin for the window viewport calculations.

      if (chatWinRef.current && messageDOMRect) {
        const chatWinDOMRect = chatWinRef.current.getBoundingClientRect()
        const chatWindowTop = chatWinDOMRect.top - windowMargin

        return messageDOMRect.top < chatWindowTop
      }

      return true
    }

    /**
     * Checks if a message is unread
     */
    const isMessageUnread = (message) => {
      // There is a separate, explicit check for receipt because
      // global users will not have a receipt for messages that they're just monitoring.
      return message?.receipt && !message?.receipt?.readAt
    }

    /**
     * Scrolls to bottom of message window
     */
    const scrollChatWindowToBottom = () => {
      if (chatWinRef.current) {
        chatWinRef.current.scrollTop = chatWinRef.current.scrollHeight
        wasLastScrolledByUser.current = false
        wasLastScrolledToMessageById.current = false
        isScrolledToBottom.current = true
      }
    }

    // This fixes the scrollbar getting stuck at the top when rendering older messages
    const keepScrollInTheMessage = () => {
      const firstMessage = messages[0]
      if (firstMessage?.id !== firstMessageId.current) {
        if (firstMessageId.current && chatWinRef.current && chatWinRef.current.scrollTop === 0) {
          const firstMessageEl = document.getElementById(`message-${firstMessageId.current}`)
            ?.parentElement?.parentElement
          if (firstMessageEl) {
            const position = firstMessageEl.getBoundingClientRect()
            chatWinRef.current.scrollTop = firstMessageEl.offsetTop - position.height
          }
        }
        firstMessageId.current = firstMessage.id
      }
    }

    /**
     * Checks if message window scroll position is at or close to the bottom (within 150px)
     */
    const calcIsScrolledToBottom = () => {
      if (chatWinRef.current) {
        const chatWinDOMRect = chatWinRef.current.getBoundingClientRect()
        return (
          chatWinRef.current.scrollTop >=
          chatWinRef.current.scrollHeight - chatWinDOMRect.height - 150
        )
      }

      return true // best to return true. otherwise, buggy behaviors. FIXME, use a ref and return last ref
    }

    /**
     * Iterates through messages in the viewport. If any are unread, mark them as read.
     * Then recalculate what is the earliest unread message.
     */
    const processUnreadMessages = () => {
      const messageCount = messages.length

      if (messageCount) {
        let idx = messages.findIndex((m) => isMessageInViewport(m.id))
        if (idx >= 0) {
          let message = messages[idx]
          while (idx < messageCount && isMessageInViewport(message.id)) {
            if (isMessageUnread(message)) {
              onMarkMessageAsRead(message.id)
            }
            idx += 1
            message = messages[idx]
          }
        }
      }

      earliestUnreadMessage.current = messages.find((m) => isMessageUnread(m)) || { id: 0 } // id:0 so that the useEffect works
      isScrolledToBottom.current = calcIsScrolledToBottom()
    }

    const loadOlderMessages = () => {
      if (hasOlderMessages) {
        let offsetToLoadOlderMessages = messageCountPerBatch / 2

        if (offsetToLoadOlderMessages > messages.length) {
          offsetToLoadOlderMessages = messages.length - 1
        }

        if (!isMessageAboveViewport(messages[offsetToLoadOlderMessages]?.id)) {
          fetchOlderMessages()
        }
      }
    }

    /**
     * Messages updated effect
     * @effect
     */
    useEffect(() => {
      if (messages.length) {
        // If the chat window is newly opened, scroll to bottom.
        if (!latestMessageId.current && !queryMessageId) {
          scrollChatWindowToBottom()
        }

        keepScrollInTheMessage()

        const latestMessage = messages.slice(-1)[0]
        if (latestMessage?.id !== latestMessageId.current) {
          latestMessageId.current = latestMessage.id
          hasNewMessages.current = true
        } else {
          hasNewMessages.current = false
        }

        const isFromSelf =
          latestMessage?.receipt?.isFromSelf || latestMessage?.senderUser?.id === userId // this OR condition handles global chatters

        /*
        (a) If current user just posted a new message, scroll to bottom.
        (b) If there are any new messages and the user is at or near the bottom, scroll to bottom.
        (c) If there are any new messages and the user has not scrolled (i.e. tab newly loaded), scroll to bottom.
            This handles new messages that are long or multiple new messages, because the isScrolledToBottom
            condition in those cases will not be met.

        Otherwise, the user is scrolled far enough up that we don't want to be disruptive and jump to bottom.
      */
        if (
          (hasNewMessages.current && isFromSelf) ||
          (hasNewMessages.current && isScrolledToBottom.current) ||
          (hasNewMessages.current && !wasLastScrolledByUser.current)
        ) {
          scrollChatWindowToBottom()
        }

        processUnreadMessages()
      }
    }, [messages])

    /**
     * Jump-to-message effect:
     * For deep links. If there is a 'messageid' query param, scroll to that message.
     * This effect needs to happen in this container, because this component contains the element.
     * @effect
     */
    useEffect(() => {
      /*
      (a) If there is a messageid in the query params and it wasn't scrolled to yet
      (b) If the scroll already happened but for some reason (i.e. race condition) the message is not in the viewport
    */
      if (
        (messages.length && queryMessageId && queryMessageId !== scrolledToMessageId.current) ||
        (wasLastScrolledToMessageById.current && !isMessageInViewport(queryMessageId))
      ) {
        const chatMessage = document.getElementById(`message-${queryMessageId}`)
        if (chatMessage) {
          setTimeout(() => {
            chatMessage.scrollIntoView({ behavior: 'smooth' })
            scrolledToMessageId.current = queryMessageId
            wasLastScrolledToMessageById.current = true
          }, 300)
        }
      }
    }, [messages, queryMessageId, projectId])

    /**
     * If an(y) unread message found, set the pill display state to show it.
     * Otherwise, don't show it.
     * @effect
     */
    useEffect(() => {
      if (earliestUnreadMessage.current?.id) {
        setShowUnreadMessagesPill(true)
      } else {
        setShowUnreadMessagesPill(false)
      }
    }, [earliestUnreadMessage.current])

    /**
     * Pill Click handlers
     * @handler
     */
    const handleUnreadMessagesPillClick = () => {
      // scroll to earliest unread message
      const messageElement = document.getElementById(`message-${earliestUnreadMessage.current?.id}`)
      if (messageElement) {
        messageElement.scrollIntoView({ behavior: 'smooth' })
      } else {
        setShowUnreadMessagesPill(false)
      }
    }

    /**
     * Chat window scroll handler
     * @handler
     */
    const handleScroll = () => {
      wasLastScrolledByUser.current = true
      wasLastScrolledToMessageById.current = false
      processUnreadMessages()
      loadOlderMessages()
    }

    const throttledHandleScroll = _.throttle(handleScroll, 1000)

    // RENDER
    const menuOpen = Boolean(messageMenuAnchorEl)

    return (
      <>
        <ChatChannelWindow
          ref={chatWinRef}
          channelId={channelId}
          userId={userId}
          elementId={CHAT_WINDOW_ELEMENT_ID}
          directMessageId={queryMessageId}
          editMessageId={editMessageId}
          isLoadingOlderMessages={isLoadingOlderMessages}
          messages={messages}
          showUnreadMessagesPill={showUnreadMessagesPill}
          onUnreadMessagesPillClick={handleUnreadMessagesPillClick}
          onScroll={throttledHandleScroll}
          onMessageMenuOpen={handleMessageMenuOpen}
          onMessageMenuClose={handleMessageMenuClose}
          onEditMessageClose={handleEditMessageClose}
        />
        {menuOpen && (
          <ChatItemMenu
            anchorEl={messageMenuAnchorEl}
            messageId={menuMessageId}
            onClose={handleMessageMenuClose}
            onEditClick={handleMessageEditOptionClick}
            isEdit={!!editMessageId}
          />
        )}
      </>
    )
  }
)

ChatChannelWindowContainer.propTypes = {
  channelId: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      receipt: PropTypes.shape({
        id: PropTypes.string.isRequired,
        readAt: PropTypes.string,
      }),
    })
  ).isRequired,
  onMarkMessageAsRead: PropTypes.func.isRequired,
  isLoadingOlderMessages: PropTypes.bool,
  messageCountPerBatch: PropTypes.number,
  fetchOlderMessages: PropTypes.func.isRequired,
  hasOlderMessages: PropTypes.bool,
}

export default trackWindowScroll(ChatChannelWindowContainer)
