import { gql } from '@apollo/client'

import { PRODUCT_FRAGMENT } from '@graphql/products/queries'

const getChecklistQuery = (reactiveVarFilter) => gql`
  query GetProjectChecklist($projectId: ID!, $checklistFilter: String) {
    ${reactiveVarFilter}
    projectChecklist(projectId: $projectId, checklistFilter: $checklistFilter) {
      id
      description
      dueDate
      completedAt
      assignee {
        id
        firstName
        lastName
        profilePicture {
          url
        }
        contractor {
          id
        }
        customer {
          id
        }
        staffUser {
          id
        }
      }
      postedBy {
        id
        firstName
        lastName
      }
      assignedAt
      postedAt
    }
  }
`

const getChecklistQueryAndGroups = (reactiveVarFilter) => gql`
  query ProjectChecklistItemsAndGroups($projectId: ID!, $itemStatusFilter: String) {
      ${reactiveVarFilter}
      projectChecklistItemsAndGroups(projectId: $projectId, itemStatusFilter: $itemStatusFilter) {
          items {
              id
              description
              dueDate
              completedAt
              assignee {
                  id
                  availability
                  fullName
                  firstName
                  lastName
                  groups
                  profilePicture {
                    url
                    filename
                  }
              }
              assignedAt
              postedBy {
                  id
              }
              postedAt
              order
              group {
                  id
                  name
                  order
              }
          }
          groups {
              id
              name
              order
              isCompleted
          }
      }
  }
`

export const GET_PROJECT_CHECKLIST = getChecklistQuery(
  'checklistFilter @client @export(as: "checklistFilter")'
)
export const GET_PROJECT_CHECKLIST_AND_GROUPS = getChecklistQueryAndGroups(
  'checklistGroupsFilter @client @export(as: "itemStatusFilter")'
)
export const GET_ALL_CHECKLIST = getChecklistQuery('')
export const GET_ALL_CHECKLISTS_AND_GROUPS = getChecklistQueryAndGroups('')

export const PROJECT_QUERY_FRAGMENT = gql`
  fragment ProjectQueryFragment on ProjectDetailsType {
    id
    name
    slug
    description
    product {
      ...ProductQueryFragment
    }
    status
    createdAt
    merchandisingCategory
    industry
    dueDate
    projectState
    scheduleDifference
    salesforceId
    complexity
    gpsdLink
    folderLink
    region
    resolutionType
    isTest
    accountClosureNotes
    npiTemplate {
      id
      name
    }
    clientPulse {
      calculatedStatus
      time
      cost
      spec
      communications
      needsHelpAt
    }
    mutedUsers {
      id
    }
    coverImage {
      id
      url
      thumbnailUrl
      filename
    }
    health {
      status
      reason
    }
    company {
      id
      name
    }
    externalChatChannel {
      id
      subscribed
    }
    internalChatChannel {
      id
      subscribed
    }
    designChatChannel {
      id
      subscribed
    }
    availableMentions {
      id
      fullName
      isGembah
      isCustomer
      isContractor
      availability
      groups
      coveredByEmail
      coveredByUser {
        id
        email
      }
    }
    team {
      id
      fullName
      availability
      firstName
      lastName
      email
      profilePicture {
        url
      }
      account {
        type
      }
      roles {
        role
      }
      phone
      groups
      title
      location
      userId
      customerId
      contractorId
      contractorType
      staffUserId
      staffUserType
      internalChatChannelSubscribed
      externalChatChannelSubscribed
      designChatChannelSubscribed
    }
  }
  ${PRODUCT_FRAGMENT}
`

export const GET_PROJECT_QUERY = gql`
  query GetProject($projectSlug: String!) {
    project(projectSlug: $projectSlug) {
      ...ProjectQueryFragment
    }
  }
  ${PROJECT_QUERY_FRAGMENT}
`

export const GET_PROJECT_QUERY_BY_ID = gql`
  query GetProject($projectId: ID!) {
    project(projectId: $projectId) {
      ...ProjectQueryFragment
    }
  }
  ${PROJECT_QUERY_FRAGMENT}
`

export const GET_PRODUCTION_SUMMARY_ISSUE_STATUS_STATS = gql`
  query GetProductionSummaryIssueStatusStats {
    productionSummaryIssueStatusStats {
      good
      medium
      bad
      total
    }
  }
`

export const GET_PRODUCTION_DASHBOARD_PROJECTS = gql`
  query GetProductionDashboardProjects(
    $search: String
    $orderBy: [String]
    $statusIn: [String]
    $issueStatusIn: [String]
    $issueCategory: String
    $productionStatus: String
    $offset: Int
    $limit: Int
  ) {
    projectsProductionDashboardSearch @client @export(as: "search")
    projectsOrderBy @client @export(as: "orderBy")
    paginationOffset @client @export(as: "offset")
    paginationLimit @client @export(as: "limit")
    dashboardProjects(
      search: $search
      orderBy: $orderBy
      statusIn: $statusIn
      issueStatusIn: $issueStatusIn
      issueCategory: $issueCategory
      productionStatus: $productionStatus
      offset: $offset
      limit: $limit
    ) {
      results {
        id
        name
        productionSummary {
          status
          issueStatus
          issueCategory
          updatedAt
        }
        coverImage {
          url
          thumbnailUrl
        }
        slug
        company {
          id
          name
        }
      }
      totalCount
    }
  }
`

export const GET_PROJECTS = gql`
  query GetProjects(
    $myProjects: Boolean
    $status: String
    $search: String
    $orderBy: [String]
    $offset: Int
    $limit: Int
    $category: ProjectCategory
    $filters: ProjectSearchFiltersInput
  ) {
    projectsSearch @client @export(as: "search")
    projectsOrderBy @client @export(as: "orderBy")
    projectsStatusFilter @client @export(as: "status")
    myProjectsFilter @client @export(as: "myProjects")
    paginationOffset @client @export(as: "offset")
    paginationLimit @client @export(as: "limit")
    projectFilters @client @export(as: "filters")
    projectCategory @client @export(as: "category")
    projects(
      myProjects: $myProjects
      status: $status
      search: $search
      orderBy: $orderBy
      offset: $offset
      limit: $limit
      category: $category
      filters: $filters
    ) {
      results {
        id
        name
        description
        status
        projectState
        region
        health {
          status
          reason
        }
        clientPulse {
          calculatedStatus
          time
          cost
          spec
          communications
          escalationId
        }
        coverImage {
          url
          thumbnailUrl
        }
        slug
        noGembahResponseSince
        createdAt
        company {
          id
          name
        }
        team {
          id
          firstName
          lastName
          profilePicture {
            url
          }
          userId
          customerId
          contractorId
          staffUserId
        }
        currentNpiMilestone {
          code
          name
        }
        projectSummary {
          recentActivity
          updatedAt
        }
      }
      totalCount
    }
  }
`

export const GET_AM_DASHBOARD_PROJECTS = gql`
  query GetAmDashboardProjects(
    $search: String
    $myProjects: Boolean
    $orderBy: [String]
    $statusIn: [String]
    $healthStatusIn: [String]
    $offset: Int
    $limit: Int
  ) {
    projectsAmDashboardSearch @client @export(as: "search")
    myProjectsFilter @client @export(as: "myProjects")
    projectsAmDashboardOrderBy @client @export(as: "orderBy")
    projectsStatusInFilter @client @export(as: "statusIn")
    projectsHealthFilter @client @export(as: "healthStatusIn")
    paginationOffset @client @export(as: "offset")
    paginationLimit @client @export(as: "limit")
    dashboardProjects(
      search: $search
      myProjects: $myProjects
      orderBy: $orderBy
      statusIn: $statusIn
      healthStatusIn: $healthStatusIn
      offset: $offset
      limit: $limit
    ) {
      results {
        id
        name
        description
        status
        nextChecklistItemDueDate
        health {
          status
          reason
        }
        coverImage {
          url
          thumbnailUrl
        }
        slug
        noGembahResponseSince
        createdAt
        company {
          id
          name
        }
        team {
          id
          firstName
          lastName
          profilePicture {
            url
          }
          userId
          customerId
          contractorId
          staffUserId
        }
        projectSummary {
          recentActivity
          updatedAt
        }
        nextChecklistItem {
          checklistItem {
            postedBy {
              isGembah
              isCustomer
              isContractor
            }
            assignee {
              fullName
              isGembah
              isCustomer
              isContractor
            }
            dueDate
            description
          }
          order
          totalCount
        }
      }
      totalCount
    }
  }
`

export const GET_PROJECT_LIST_STATUS_COUNTS = gql`
  query GetProjectListStatusCounts($myProjects: Boolean, $search: String) {
    projectsSearch @client @export(as: "search")
    myProjectsFilter @client @export(as: "myProjects")
    projectListStatusCounts(myProjects: $myProjects, search: $search) {
      status
      count
    }
  }
`

export const GET_DASHBOARD_PROJECT_STATUS_COUNTS = gql`
  query GetDashboardProjectStatusCounts($myProjects: Boolean) {
    myProjectsFilter @client @export(as: "myProjects")
    dashboardProjectStatusCounts(myProjects: $myProjects) {
      newRfq
      designQuote
      designViability
      designConcepting
      designEngineering
      designDiscoveryKickoff
      design
      discoveryOnboarding
      discovery
      preProduction
      preFlight
      priceQuote
      sampling
      production
      shipping
      fulfilled
      reorder
      research
      researchKickoff
      researchInProgress
      researchCompleted
      researchPresented
      onHold
      archived
    }
  }
`

export const GET_DASHBOARD_AM_HEALTH_STATUS_COUNTS = gql`
  query GetDashboardAmHealthStatusCounts($myProjects: Boolean) {
    myProjectsFilter @client @export(as: "myProjects")
    dashboardAmHealthStatusCounts(myProjects: $myProjects) {
      onTrack
      atRisk
      criticalProblem
      emergency
      total
    }
  }
`

export const GET_SUMMARY = gql`
  query GetProjectSummary($projectId: ID) {
    projectSummary(projectId: $projectId) {
      id
      updatedAt
      currentStatus
      projectHealth
      recentActivity
      needsHelp
      lastModifiedBy {
        id
      }
    }
  }
`

export const GET_PRESET_TEMPLATES = gql`
  query ChecklistTemplates {
    checklistTemplates {
      key
      label
    }
  }
`
