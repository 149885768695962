import { gql } from '@apollo/client'

// eslint-disable-next-line import/prefer-default-export
export const GET_CHANNEL_MEDIA_UPLOAD_URL = gql`
  mutation GetChannelMediaUploadUrl(
    $projectId: ID!
    $channelId: ID!
    $contentEncoding: String
    $contentSize: Int!
    $filename: String!
    $mimetype: String
  ) {
    getChannelMediaUploadUrl(
      projectId: $projectId
      channelId: $channelId
      contentEncoding: $contentEncoding
      contentSize: $contentSize
      filename: $filename
      mimetype: $mimetype
    ) {
      mediaUploadData {
        mediaId
        mediaProjectChannelId
        uploadUrlInfo {
          url
          fields {
            key
            value
          }
        }
      }
    }
  }
`

export const GET_CHANNEL_URL_UPLOAD_URL = gql`
  mutation GetChannelUrlUploadUrl(
    $projectId: ID!
    $channelId: ID!
    $filename: String!
    $externalUrl: String!
    $mimetype: String
  ) {
    getChannelUrlUploadUrl(
      projectId: $projectId
      channelId: $channelId
      filename: $filename
      externalUrl: $externalUrl
      mimetype: $mimetype
    ) {
      mediaUploadData {
        mediaId
        mediaProjectChannelId
        uploadUrlInfo {
          url
          fields {
            key
            value
          }
        }
      }
    }
  }
`

export const CONFIRM_CHANNEL_MEDIA_UPLOAD = gql`
  mutation ConfirmChannelMediaUpload($channelId: ID!, $mediaId: ID!, $responseJson: JSONString) {
    confirmChannelMediaUpload(
      channelId: $channelId
      mediaId: $mediaId
      responseJson: $responseJson
    ) {
      media {
        id
      }
    }
  }
`

export const DELETE_MEDIA_PROJECT_CHANNEL = gql`
  mutation DeleteMediaProjectChannel($mediaProjectChannelId: ID!) {
    deleteMediaProjectChannel(mediaProjectChannelId: $mediaProjectChannelId) {
      trashedMediaProjectChannel {
        id
        message {
          id
        }
      }
    }
  }
`

export const SHARE_ATTACHMENT_TO_CHANNEL = gql`
  mutation ShareAttachmentToChannel($mediaProjectChannelId: ID!, $channelId: ID!) {
    shareAttachmentToChannel(mediaProjectChannelId: $mediaProjectChannelId, channelId: $channelId) {
      sharedAttachment {
        id
      }
    }
  }
`
