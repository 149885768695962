import gql from 'graphql-tag'

import { NPI_STEP_FRAGMENT, NPI_SUBMISSION_FRAGMENT } from '@graphql/npi/queries'

export const TOGGLE_NPI_STEP_VISIBILITY = gql`
  mutation ToggleNpiStepVisibility($stepId: ID!, $visible: Boolean!) {
    toggleNpiStepVisibility(stepId: $stepId, visible: $visible) {
      npiStep {
        ...NpiStepFragment
      }
    }
  }
  ${NPI_STEP_FRAGMENT}
`

export const TOGGLE_NPI_MILESTONE_VISIBILITY = gql`
  mutation ToggleNpiMilestoneVisibility($milestoneId: ID!, $visible: Boolean!) {
    toggleNpiMilestoneVisibility(milestoneId: $milestoneId, visible: $visible) {
      npiMilestone {
        id
        disabled
      }
    }
  }
`

export const UPDATE_NPI_STEP_POSITION = gql`
  mutation UpdateNpiStepPosition($stepId: ID!, $milestoneId: ID!, $position: Int!) {
    updateNpiStepPosition(stepId: $stepId, milestoneId: $milestoneId, position: $position) {
      npiStep {
        position
      }
    }
  }
`

export const UPDATE_NPI_MILESTONE_POSITION = gql`
  mutation UpdateNpiMilestonePosition($milestoneId: ID!, $stageId: ID!, $position: Int!) {
    updateNpiMilestonePosition(milestoneId: $milestoneId, stageId: $stageId, position: $position) {
      npiMilestone {
        id
        name
      }
    }
  }
`

export const UPDATE_NPI_MILESTONE_DURATION = gql`
  mutation UpdateNpiMilestoneDueDate(
    $milestoneId: ID!
    $dueDate: Date!
    $reason: DueDateUpdateReasonChoices!
  ) {
    updateNpiMilestoneDueDate(milestoneId: $milestoneId, dueDate: $dueDate, reason: $reason) {
      npiMilestone {
        duration
        dueAt
      }
    }
  }
`

export const EDIT_NPI_STEP = gql`
  mutation EditNpiStep(
    $stepId: ID!
    $duration: Int
    $ownerRole: String
    $templateDocumentUrl: String
  ) {
    editNpiStep(
      stepId: $stepId
      duration: $duration
      ownerRole: $ownerRole
      templateDocumentUrl: $templateDocumentUrl
    ) {
      npiStep {
        ...NpiStepFragment
      }
    }
  }
  ${NPI_STEP_FRAGMENT}
`

export const APPLY_NPI_TEMPLATE = gql`
  mutation ApplyNpiTemplate($projectId: ID!, $templateId: ID!, $milestonePositionId: ID) {
    applyNpiTemplate(
      projectId: $projectId
      templateId: $templateId
      milestonePositionId: $milestonePositionId
    ) {
      project {
        id
        slug
      }
    }
  }
`

export const NEW_NPI_SUBMISSION = gql`
  mutation CreateNpiSubmission($stepId: ID!) {
    createNpiSubmission(stepId: $stepId) {
      npiDeliverable {
        id
        currentSubmission {
          id
        }
      }
    }
  }
`

export const DELIVER_NPI_SUBMISSION = gql`
  mutation DeliverNpiSubmission($submissionId: ID!) {
    deliverNpiSubmission(submissionId: $submissionId) {
      npiSubmission {
        ...NpiSubmissionFragment
      }
    }
  }
  ${NPI_SUBMISSION_FRAGMENT}
`

export const ADD_NPI_SUBMISSION_REVIEW = gql`
  mutation AddNpiSubmissionReview($submissionId: ID!, $result: String!, $comment: String) {
    addNpiSubmissionReview(submissionId: $submissionId, result: $result, comment: $comment) {
      npiSubmission {
        ...NpiSubmissionFragment
      }
    }
  }
  ${NPI_SUBMISSION_FRAGMENT}
`

export const ASSIGN_NPI_ROLE = gql`
  mutation AssignNpiRole($projectId: ID!, $roleId: ID!, $assigneeId: ID) {
    assignNpiRole(projectId: $projectId, roleId: $roleId, assigneeId: $assigneeId) {
      assignedNpiRole {
        role {
          id
          name
          shorthand
        }
        user {
          id
          email
          firstName
          lastName
          fullName
        }
      }
    }
  }
`

export const SET_NPI_STEP_DUE_DATE = gql`
  mutation SetNpiStepDueDate($stepId: ID!, $dueDate: DateTime!) {
    setNpiStepDueDate(stepId: $stepId, dueDate: $dueDate) {
      npiStep {
        ...NpiStepFragment
      }
    }
  }
  ${NPI_STEP_FRAGMENT}
`

export const START_NPI_STEP = gql`
  mutation StartNpiStep($stepId: ID!) {
    startNpiStep(stepId: $stepId) {
      npiStep {
        ...NpiStepFragment
      }
    }
  }
  ${NPI_STEP_FRAGMENT}
`

export const COMPLETE_NPI_STEP = gql`
  mutation CompleteNpiStep($stepId: ID!, $note: String) {
    completeNpiStep(stepId: $stepId, note: $note) {
      npiStep {
        ...NpiStepFragment
      }
    }
  }
  ${NPI_STEP_FRAGMENT}
`

export const GET_NPI_DELIVERABLE_MEDIA_UPLOAD_URL = gql`
  mutation GetNpiSubmissionMediaUploadUrl(
    $submissionId: ID!
    $filename: String!
    $contentSize: Int!
    $mimetype: String
    $contentEncoding: String
  ) {
    getNpiSubmissionMediaUploadUrl(
      submissionId: $submissionId
      filename: $filename
      contentSize: $contentSize
      mimetype: $mimetype
      contentEncoding: $contentEncoding
    ) {
      mediaUploadData {
        media {
          id
        }
        uploadUrlInfo {
          url
          fields {
            key
            value
          }
        }
      }
    }
  }
`

export const CONFIRM_NPI_DELIVERABLE_MEDIA_UPLOAD = gql`
  mutation ConfirmNpiDeliverableMediaUpload(
    $submissionId: ID!
    $mediaId: ID!
    $responseJson: JSONString
  ) {
    confirmNpiSubmissionMediaUpload(
      submissionId: $submissionId
      mediaId: $mediaId
      responseJson: $responseJson
    ) {
      media {
        id
      }
    }
  }
`

export const RECALL_NPI_SUBMISSION = gql`
  mutation RecallNpiSubmission($submissionId: ID!) {
    recallNpiSubmission(submissionId: $submissionId) {
      npiSubmission {
        ...NpiSubmissionFragment
      }
    }
  }
  ${NPI_SUBMISSION_FRAGMENT}
`

export const TRASH_NPI_SUBMISSION = gql`
  mutation TrashNpiSubmission($submissionId: ID!) {
    trashNpiSubmission(submissionId: $submissionId) {
      npiSubmission {
        ...NpiSubmissionFragment
      }
    }
  }
  ${NPI_SUBMISSION_FRAGMENT}
`

export const SET_PROJECT_STATE = gql`
  mutation SetProjectState($projectId: ID!, $projectState: String!) {
    setProjectState(projectId: $projectId, projectState: $projectState) {
      project {
        id
        projectState
      }
    }
  }
`

export const EDIT_PROJECT_HEALTH = gql`
  mutation UpdateHealthScores(
    $projectId: ID!
    $time: String
    $cost: String
    $spec: String
    $communications: String
    $escalationId: String!
    $changeReason: String!
  ) {
    updateProjectClientPulseScores(
      projectId: $projectId
      time: $time
      cost: $cost
      spec: $spec
      communications: $communications
      escalationId: $escalationId
      changeReason: $changeReason
    ) {
      clientPulse {
        calculatedStatus
        time
        cost
        spec
        communications
        needsHelpAt
      }
    }
  }
`
