import { gql } from '@apollo/client'

export const GET_NPI_TEMPLATES = gql`
  query NpiTemplates {
    npiTemplates {
      id
      name
      milestonePositions {
        id
        position
        milestone {
          name
        }
      }
    }
  }
`

export const NPI_ROLES_BY_PROJECT = gql`
  query NpiRolesByProject($projectId: ID!) {
    npiRolesByProject(projectId: $projectId) {
      role {
        id
        name
        shorthand
      }
      user {
        id
        email
      }
    }
  }
`

export const NPI_SUBMISSION_FRAGMENT = gql`
  fragment NpiSubmissionFragment on NpiDeliverableSubmissionType {
    deletedAt
    deliverable {
      name
      step {
        id
      }
    }
    reviews {
      createdAt
      comments {
        comment
      }
    }
    id
    status
    deliveredToClientAt
    gembahVersion
    media {
      id
      filename
      mimetype
      contentSize
      thumbnailFilename
      uploadConfirmedAt
      thumbnailUrl
      url
      uploadUser {
        firstName
        lastName
        fullName
      }
    }
    submittedBy {
      id
      firstName
      lastName
      fullName
    }
    comments {
      id
    }
    reviews {
      id
      comments {
        id
      }
    }
  }
`

export const NPI_STEP_FRAGMENT = gql`
  fragment NpiStepFragment on NpiStepType {
    id
    position
    completedAt
    dueDate
    startedAt
    disabled
    assignee {
      id
      firstName
      lastName
      fullName
    }
    stage {
      id
      name
      code
      hexColor
    }
    milestone {
      id
      faIconName
      name
      code
      startedAt
      dueAt
      completedAt
      duration
      disabled
      description
    }
    process {
      id
      name
      code
    }
    task {
      id
      name
      description
      npiEscalations {
        ownerRole {
          name
          shorthand
          id
        }
        duration
      }
      ownerRole {
        id
        name
        userAccountType
        shorthand
      }
      duration
      isReview
      code
      templateDocumentUrl
    }
    deliverable {
      id
      code
      name
      isForClient
      deliverableType
      submissions {
        ...NpiSubmissionFragment
      }
      notes {
        text
        submittedAt
        submittedBy {
          fullName
        }
      }
    }
  }
  ${NPI_SUBMISSION_FRAGMENT}
`

export const GET_NPI_STEPS = gql`
  query NpiSteps($projectId: ID!) {
    npiSteps(projectId: $projectId, showHidden: true) {
      ...NpiStepFragment
    }
  }
  ${NPI_STEP_FRAGMENT}
`

export const GET_CURRENT_MILESTONE = gql`
  query CurrentNpiMilestone($projectId: ID!) {
    currentNpiMilestone(projectId: $projectId) {
      id
    }
  }
`

export const GET_NPI_MILESTONE_NAMES = gql`
  query GetNpiMilestoneNames {
    npiMilestoneNames {
      name
    }
  }
`
