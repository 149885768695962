import { useLazyQuery } from '@apollo/client'
import { zip } from 'fflate'
import { saveAs } from 'file-saver'
import { useState, useRef } from 'react'

import { MIME_TYPES_FOR_ICONS } from '@lib/constants'
import useRoute from '@lib/useRoute'

import { GET_ATTACHMENT } from '@graphql/project/attachments/queries'

import { TOAST_TYPES } from '@components_pop/Toast'

import useToast from '@hooks/useToast'

export const ATTACHMENT_BATCH_LIMIT = 5
export const FORCE_DOWNLOAD_FILE_TYPES = ['ai']
const ALREADY_COMPRESSED = [
  'zip',
  'gz',
  'png',
  'jpg',
  'jpeg',
  'pdf',
  'doc',
  'docx',
  'ppt',
  'pptx',
  'xls',
  'xlsx',
  'heic',
  'heif',
  '7z',
  'bz2',
  'rar',
  'gif',
  'webp',
  'webm',
  'mp4',
  'mov',
  'mp3',
  'aifc',
]

export const IMAGE_FILE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'svg']

export const PREVIEWABLE_FILE_TYPES = [
  ...IMAGE_FILE_TYPES,
  'pdf',
  'txt',
  'mp4',
  'mov',
  MIME_TYPES_FOR_ICONS.GOOGLE_DRIVE,
]

const BATCH_DOWNLOAD_INFO_DEFAULT = {
  fileCount: 0,
  filesDownloaded: 0,
}

const useDownloadFile = () => {
  const { addToast } = useToast()
  const downloadStopped = useRef(false)
  const [state, setState] = useState({
    ...BATCH_DOWNLOAD_INFO_DEFAULT,
    isDownloading: false,
    isDownloadComplete: false,
    mediaId: null,
  })
  const [goTo] = useRoute()
  const isPreviewEvent = useRef(false)
  const shouldOpenInSelf = useRef(false)
  const attachmentProjectSlug = useRef('')

  const { isDownloading, isDownloadComplete, mediaId, ...batchDownloadInfo } = state

  const aggregateState = (newPartialState) => {
    setState((prevState) => ({
      ...prevState,
      ...(typeof newPartialState === 'function' ? newPartialState(prevState) : newPartialState),
    }))
  }

  const [getAttachment] = useLazyQuery(GET_ATTACHMENT, {
    onCompleted(data) {
      const { media } = data.attachment
      const fileType = media.filename.split('.').pop().toLowerCase()
      const hasThumbnail = Boolean(media?.thumbnailUrl)
      const showPreview = isPreviewEvent.current
        ? hasThumbnail || PREVIEWABLE_FILE_TYPES.includes(fileType)
        : false
      const isForceDownload = !showPreview || FORCE_DOWNLOAD_FILE_TYPES.includes(fileType)
      if (isForceDownload) {
        aggregateState({
          isDownloading: true,
          mediaId: media.id,
        })
        fetch(media.url)
          .then((r) => r.blob())
          .then((blob) => {
            aggregateState({
              isDownloadComplete: true,
            })
            saveAs(blob, media.filename)
          })
          .catch(() => {
            addToast({
              message: `Could not download file ${media.filename}`,
              type: TOAST_TYPES.ERROR,
            })
          })
          .finally(() => {
            aggregateState({
              isDownloadComplete: false,
              isDownloading: false,
              mediaId: null,
            })
            setTimeout(() => {
              // Closes the window once download has started.
              window.close()
              // If this is opened via a direct/shared link, window.close() may not work since the window
              // wasn't opened by a user event. In that case, instead of remaining on a blank screen,
              // open the project details page.
              if (attachmentProjectSlug.current) {
                goTo(`/project/${attachmentProjectSlug.current}`)
              }
            }, 0)
          })
      } else if (shouldOpenInSelf.current) {
        window.open(media.url, '_self')
      } else {
        window.open(media.url, '_blank')
      }
    },
  })

  const downloadFile = ({
    mediaProjectChannelId,
    isPreview = false,
    openInSelf = false,
    projectSlug = '',
  }) => {
    // The isPreview argument tells us if the user is explicitly downloading a file, or attempting to preview it in-browser.
    // I.e. clicking the "download" attachment option vs. clicking the link to open the image.

    isPreviewEvent.current = isPreview
    shouldOpenInSelf.current = openInSelf
    attachmentProjectSlug.current = projectSlug

    getAttachment({
      variables: {
        mediaProjectChannelId,
        // There is a a bug with apollo that isn't fixed for almost 3 years, in which
        // 'onCompleted' is not called again when fetching from the cache.
        // (https://github.com/apollographql/apollo-client/issues/6636).
        // A fix is a random variable name with random value to force the cache first to
        // retrigger the 'onComplete'
        random: Math.random(),
      },
    })
  }

  const stopZipAndDownloadAll = () => {
    downloadStopped.current = true
  }

  const createBatchDownloads = (media) =>
    Array.from({ length: Math.ceil(media.length / ATTACHMENT_BATCH_LIMIT) }, (v, i) =>
      media.slice(i * ATTACHMENT_BATCH_LIMIT, i * ATTACHMENT_BATCH_LIMIT + ATTACHMENT_BATCH_LIMIT)
    )

  const downloadZippedFiles = (fileName) => (err, result) => {
    const content = new Blob([result?.buffer], { type: 'application/zip' })

    try {
      saveAs(content, `${fileName}-attachments.zip`)
    } catch (e) {
      addToast({
        message: 'There was an error while downloading your files, please try again.',
        type: TOAST_TYPES.ERROR,
      })
    } finally {
      aggregateState({
        ...BATCH_DOWNLOAD_INFO_DEFAULT,
        isDownloading: false,
      })
      downloadStopped.current = false
    }
  }

  const downloadAndZip = async (batchedDownloads, fileName) => {
    const files = {}

    // we create this for..of loop that acts like a generator
    // so we can await on batches of N requests to be resolved
    /* eslint-disable no-restricted-syntax */
    for (const batch of batchedDownloads) {
      // if the user stopped the download we exit on the next batch and cancel
      // all upcoming ones
      if (downloadStopped.current) break

      // we await on the batch promises to finish before jumping on the next one
      // until we finish all
      /* eslint-disable no-await-in-loop */
      await Promise.all(
        batch.map(async ({ media }) => {
          const fileType = media.filename.split('.').pop().toLowerCase()

          const fetchedMedia = await fetch(media.url)
          const fetchedArrayBuffer = await fetchedMedia.arrayBuffer()

          // we add to the files dict, [filename] => array_buffer, settings
          files[media.filename] = [
            new Uint8Array(fetchedArrayBuffer),
            {
              level: ALREADY_COMPRESSED.includes(fileType) ? 0 : 6,
            },
          ]

          // we increment by 1 when each file resolves
          aggregateState((prevState) => ({
            filesDownloaded: prevState.filesDownloaded + 1,
          }))
        })
      )
    }

    // we reset the state if the download was stopped
    if (downloadStopped.current) {
      aggregateState({
        ...BATCH_DOWNLOAD_INFO_DEFAULT,
        isDownloading: false,
      })
      downloadStopped.current = false
      return
    }

    zip(files, downloadZippedFiles(fileName))
  }

  const zipAndDownloadAll = async ({ allMedia, fileName }) => {
    const batchedDownloads = createBatchDownloads(allMedia)

    aggregateState({
      isDownloading: true,
      fileCount: allMedia.length,
    })

    await downloadAndZip(batchedDownloads, fileName)
  }

  return {
    batchDownloadInfo,
    stopZipAndDownloadAll,
    zipAndDownloadAll,
    downloadFile,
    isDownloadComplete,
    isDownloading,
    mediaId,
  }
}

export default useDownloadFile
