import { useMutation, useReactiveVar } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import GembahConfirmDialog from '@dialog/GembahConfirmDialog'

import { currentProject } from '@lib/apollo/apolloCache'
import tracking from '@lib/tracking'
import { ACCOUNT_TYPES, hasPermission } from '@lib/userAuth'

import { GET_PROJECT_QUERY } from '@graphql/project/queries'
import {
  REMOVE_CONTRACTOR_FROM_PROJECT,
  REMOVE_STAFF_USER_FROM_PROJECT,
} from '@graphql/users/mutators'

import { TOAST_TYPES } from '@components_pop/Toast'

import useToast from '@hooks/useToast'

import TeamMemberMenu from './menu'

const TeamMemberMenuContainer = ({ anchorEl, member, onMenuClose, team }) => {
  const { id: projectId, slug: projectSlug } = useReactiveVar(currentProject)
  const { addToast } = useToast()
  const [removeConfirmationOpen, setRemoveConfirmationOpen] = useState(false)

  const [removeStaffUser] = useMutation(REMOVE_STAFF_USER_FROM_PROJECT, {
    refetchQueries: [{ query: GET_PROJECT_QUERY, variables: { projectSlug } }],
  })

  const [removeContractor] = useMutation(REMOVE_CONTRACTOR_FROM_PROJECT, {
    refetchQueries: [{ query: GET_PROJECT_QUERY, variables: { projectSlug } }],
  })

  const handleRemoveClick = () => {
    if (hasPermission.CREATOR(member)) {
      addToast({
        message: 'Customers cannot be removed from their own Project.',
        type: TOAST_TYPES.ERROR,
      })
      return
    }

    if (hasPermission.GEMBAH(member) && team.filter(hasPermission.GEMBAH).length === 1) {
      addToast({
        message: 'Team member removal failed. At least 1 Gembah user must assigned to the project.',
        type: TOAST_TYPES.ERROR,
      })
      return
    }
    setRemoveConfirmationOpen(true)
  }

  const handleRemoveConfirmationCancel = () => {
    tracking.trackEvent({
      id: `project_team-delete-confirmation-modal-cancel-button_click`,
      teamMemberId: member.staffUserId || member.contractorId,
    })
    setRemoveConfirmationOpen(false)
    onMenuClose()
  }

  const handleRemoveConfirm = () => {
    tracking.trackEvent({
      id: `project_team-delete-confirmation-modal-confirm-button_click`,
      teamMemberId: member.staffUserId || member.contractorId,
    })

    switch (member?.account?.type) {
      case ACCOUNT_TYPES.GEMBAH:
        removeStaffUser({
          variables: {
            projectId,
            staffUserId: member.staffUserId,
          },
        }).then(
          (res) => {
            setRemoveConfirmationOpen(false)
            if (!res.errors) {
              onMenuClose()
              addToast({
                message: `${member.fullName} has been removed from this Project`,
                type: TOAST_TYPES.SUCCESS,
              })
            }
          },
          (/* err */) => {}
        )
        break
      case ACCOUNT_TYPES.EXPERT:
      case ACCOUNT_TYPES.FACTORY:
        removeContractor({
          variables: {
            projectId,
            contractorId: member.id,
          },
        }).then(
          (res) => {
            if (!res.errors) {
              setRemoveConfirmationOpen(false)
              onMenuClose()
              addToast({
                message: `${member.fullName} has been removed from this Project`,
                type: TOAST_TYPES.SUCCESS,
              })
            }
          },
          (/* err */) => {}
        )
        break
      default:
        break
    }
  }

  return (
    <>
      <TeamMemberMenu
        anchorEl={anchorEl}
        onMenuClose={onMenuClose}
        onRemoveClick={handleRemoveClick}
      />
      {removeConfirmationOpen && (
        <GembahConfirmDialog
          title="Remove from Project"
          description={
            <div>
              Are you sure you want to remove <strong>{member.fullName}</strong> from this project?
            </div>
          }
          onAccept={handleRemoveConfirm}
          onClose={handleRemoveConfirmationCancel}
          isSuccess={false} // no success message to be shown upon deletion
        />
      )}
    </>
  )
}

TeamMemberMenuContainer.propTypes = {
  anchorEl: PropTypes.shape({}),
  team: PropTypes.arrayOf(PropTypes.shape({})),
  member: PropTypes.shape({
    id: PropTypes.string,
    account: PropTypes.shape({
      type: PropTypes.string,
    }),
    staffUserId: PropTypes.string,
    contractorId: PropTypes.string,
    fullName: PropTypes.string,
  }),
  onMenuClose: PropTypes.func.isRequired,
}

export default TeamMemberMenuContainer
