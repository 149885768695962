import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'

import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import EllipsisIcon from '@public/svg/icons/ellipsis-icon.svg'
import TrashIcon from '@public/svg/icons/trash.svg'
import UndoIcon from '@public/svg/icons/undo-icon.svg'

import GembahConfirmDialog from '@dialog/GembahConfirmDialog'

import { currentProjectNpiSteps } from '@lib/apollo/apolloCache'
import { serializeData } from '@lib/tracking'

import { RECALL_NPI_SUBMISSION, TRASH_NPI_SUBMISSION } from '@graphql/npi/mutators'

import SvgLoader from '@components_pop/SvgLoader'
import { TOAST_TYPES } from '@components_pop/Toast'

import useToast from '@hooks/useToast'

import { modifyNpiStepsBySubmission } from './utils'

const useMenuStyles = makeStyles((theme) => ({
  iconButton: {
    marginLeft: '4px',
  },
  iconRoot: {
    minWidth: 30,
    '& > div': {
      width: 20,
      height: 20,
    },
  },
  messageBox: {
    marginTop: theme.spacing(),
    padding: theme.spacing(),
    border: `1px solid ${theme.palette.grayscale.light}`,
    borderRadius: theme.spacing(),
  },
}))

const DeliveredSubmissionMenu = ({
  open,
  anchorEl,
  onClose,
  submissionId,
  openRecallConfirm,
  openTrashConfirm,
}) => {
  const classes = useMenuStyles()
  return (
    <Menu open={open} anchorEl={anchorEl} keepMounted onClose={onClose}>
      <MenuItem
        onClick={openRecallConfirm}
        data-tracking-info={serializeData({
          id: 'npi-deliverable_delivered-submission-option_click',
          option: 'recall',
          submissionId,
        })}
      >
        <ListItemIcon classes={{ root: classes.iconRoot }}>
          <SvgLoader {...UndoIcon} />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          Recall
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={openTrashConfirm}
        data-tracking-info={serializeData({
          id: 'npi-deliverable_delivered-submission-option_click',
          option: 'delete',
          submissionId,
        })}
      >
        <ListItemIcon classes={{ root: classes.iconRoot }}>
          <SvgLoader {...TrashIcon} />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          Delete
        </Typography>
      </MenuItem>
    </Menu>
  )
}

DeliveredSubmissionMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.shape({}),
  submissionId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  openRecallConfirm: PropTypes.func.isRequired,
  openTrashConfirm: PropTypes.func.isRequired,
}

const useButtonStyles = makeStyles(() => ({
  iconButton: {
    marginLeft: '4px',
    '& > span > div': {
      width: 20,
      height: 20,
    },
  },
}))

const DeliveredSubmissionButton = ({ submissionId }) => {
  const styles = useButtonStyles()
  const { addToast } = useToast()
  const [menuOpen, setMenuOpen] = useState(false)
  const [confirmRecallOpen, setConfirmRecallOpen] = useState(false)
  const [confirmTrashOpen, setConfirmTrashOpen] = useState(false)
  const buttonRef = useRef()

  const [recallSubmission] = useMutation(RECALL_NPI_SUBMISSION, {
    variables: { submissionId },
  })
  const [trashSubmission] = useMutation(TRASH_NPI_SUBMISSION, {
    variables: { submissionId },
  })

  const onConfirmOpen = (dialog) => {
    if (dialog === 'trash') {
      setConfirmTrashOpen(true)
      return
    }
    setConfirmRecallOpen(true)
  }

  const onConfirm = () => {
    if (confirmRecallOpen) {
      recallSubmission().then(({ data, errors }) => {
        if (errors) {
          return
        }

        modifyNpiStepsBySubmission(data.recallNpiSubmission.npiSubmission, currentProjectNpiSteps)
        addToast({
          message: 'Submission recalled successfully',
          type: TOAST_TYPES.SUCCESS,
        })
      })
    } else if (confirmTrashOpen) {
      trashSubmission().then(({ data, errors }) => {
        if (errors) {
          return
        }

        modifyNpiStepsBySubmission(data.trashNpiSubmission.npiSubmission, currentProjectNpiSteps)
        addToast({
          message: 'Submission deleted successfully',
          type: TOAST_TYPES.SUCCESS,
        })
      })
    }

    setMenuOpen(false)
    setConfirmRecallOpen(false)
    setConfirmTrashOpen(false)
  }

  const onClose = () => {
    setConfirmRecallOpen(false)
    setConfirmTrashOpen(false)
  }

  return (
    <>
      <IconButton
        className={styles.iconButton}
        size="small"
        ref={buttonRef}
        onClick={() => setMenuOpen(true)}
        data-tracking-info={serializeData({
          id: 'npi-deliverable_delivered-submission-menu_click',
          submissionId,
        })}
      >
        <SvgLoader {...EllipsisIcon} />
      </IconButton>
      <DeliveredSubmissionMenu
        open={menuOpen}
        anchorEl={buttonRef?.current}
        onClose={() => setMenuOpen(false)}
        submissionId={submissionId}
        openRecallConfirm={() => onConfirmOpen('recall')}
        openTrashConfirm={() => onConfirmOpen('trash')}
      />
      {(confirmRecallOpen || confirmTrashOpen) && (
        <GembahConfirmDialog
          title={`${confirmRecallOpen ? 'Recall' : 'Delete'} Submission`}
          description={
            <>
              <div>
                Are you sure you want to {confirmRecallOpen ? 'recall' : 'delete'} this submission?
              </div>
            </>
          }
          onAccept={onConfirm}
          onClose={onClose}
          isSuccess={false} // no success message to be shown upon deletion
        />
      )}
    </>
  )
}

DeliveredSubmissionButton.propTypes = {
  submissionId: PropTypes.string,
}

export default DeliveredSubmissionButton
