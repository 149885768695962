/* eslint-disable react/prop-types */
import { useReactiveVar } from '@apollo/client'
import React from 'react'

import { currentAccountUser } from '@lib/apollo/apolloCache'

const withPerms =
  (Component) =>
  ({ permissionTest, alwaysAllow, ...props }) => {
    const accountUser = useReactiveVar(currentAccountUser)
    if (!alwaysAllow && permissionTest && !permissionTest(accountUser)) return null
    return <Component {...props} />
  }

export default withPerms
