/* eslint-disable react/jsx-props-no-spreading */
import { useMutation, useReactiveVar } from '@apollo/client'
import { faDownload } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

import DownloadFileIcon from '@public/svg/icons/download-file.svg'

import PopButton from '@forms/PopButton'
import PopTextField from '@forms/PopTextField'

import { currentProject } from '@lib/apollo/apolloCache'
import { PRIMARY_TEXT_GREY } from '@lib/colors'
import { makeEllipsisText, MOBILE_BREAKPOINT } from '@lib/theme'
import { serializeData } from '@lib/tracking'

import { ADD_NPI_SUBMISSION_REVIEW } from '@graphql/npi/mutators'
import { GET_CURRENT_MILESTONE, GET_NPI_STEPS } from '@graphql/npi/queries'

import {
  DELIVERABLE_REVIEW_RESULTS,
  DELIVERABLE_STATUSES,
} from '@components_pop/ProjectDetailsNPI/constants'
import { isSubmissionReviewed } from '@components_pop/ProjectDetailsNPI/utils'
import SvgLoader from '@components_pop/SvgLoader'
import { TOAST_TYPES } from '@components_pop/Toast'
import FileList from '@components_pop/UploadFilesList'
import GembahDialogTitle from '@components_pop/dialog/GembahDialogTitle'

import useBreakpoint from '@hooks/useBreakpoint'
import { IMAGE_FILE_TYPES, PREVIEWABLE_FILE_TYPES } from '@hooks/useDownloadFile'
import useToast from '@hooks/useToast'

const useModalStyles = makeStyles((theme) => ({
  modalSubHeader: {
    ...makeEllipsisText(400),
    fontSize: '0.65rem',
    color: PRIMARY_TEXT_GREY,
    marginTop: theme.spacing(0.5),
  },
  dialogContent: {
    padding: theme.spacing(2),
    display: 'flex',
    flexWrap: 'noWrap',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      flexDirection: 'row',
      '& > div:first-child': {
        marginRight: 20,
      },
    },
  },
  actionsWrapper: {
    display: 'flex',
  },
  iconBtn: {
    padding: 0,
    '& svg': {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },
  downloadFileFileBtn: {
    '& > span > div': {
      width: 25,
      height: 25,
    },
  },
  denyTextBoxWrapper: {
    display: 'flex',
    width: '100%',
    marginBottom: 15,
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      marginLeft: 15,
      marginBottom: 0,
    },
  },
  changesActionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 20,
    '& > button': {
      marginTop: 0,
    },
  },
  notesTextarea: {
    '& > div': {
      height: '100%',
      '& > textarea': {
        height: 'inherit',
        maxHeight: 'inherit',
        padding: 10,
      },
    },
  },
  dialogActions: {
    justifyContent: 'space-between',
    '& > div > button:first-of-type': {
      marginRight: 15,
    },
    '& a': {
      marginRight: 15,
    },
  },
  iframePreview: {
    width: '100%',
    height: 500,
    border: 'none',
  },
  previewImage: {
    maxWidth: '100%',
  },
  previewImageWithRequest: {
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      maxWidth: '50%',
    },
  },
}))

const RequestChanges = ({
  submission,
  notes,
  setNotes,
  onCloseRequestChanges,
  onAddNpiSubmissionReview,
  isViewMode,
}) => {
  const classes = useModalStyles()

  return (
    <div className={classes.denyTextBoxWrapper}>
      <PopTextField
        className={classes.notesTextarea}
        id={`${submission.id}-document-notes`}
        data-test-id={`${submission.id}-document-notes`}
        name="documentNotes"
        data-tracking-info={serializeData({
          id: `${submission.id}_document-notes-textarea_click`,
        })}
        placeholder="Please let us know what changes you would like..."
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        disabled={isViewMode}
        rows={4}
        multiline
        fullWidth
        disableHelperText
      />
      {!isViewMode && (
        <div className={classes.changesActionWrapper}>
          <PopButton
            data-test-id="npi-file-upload-modal-upload-btn"
            loading={false}
            disabled={!notes.length}
            size="small"
            onClick={() => onAddNpiSubmissionReview(DELIVERABLE_REVIEW_RESULTS.REJECTED)}
            data-tracking-info={serializeData({
              id: `npi_upload-modal-upload-button_click`,
            })}
          >
            Send
          </PopButton>
          <PopButton
            size="small"
            btnStyle="simple"
            onClick={onCloseRequestChanges}
            data-tracking-info={serializeData({
              id: `npi_upload-modal-cancel-button_click`,
            })}
          >
            Cancel
          </PopButton>
        </div>
      )}
    </div>
  )
}

RequestChanges.propTypes = {
  submission: PropTypes.object,
  notes: PropTypes.string,
  setNotes: PropTypes.func,
  onCloseRequestChanges: PropTypes.func,
  onAddNpiSubmissionReview: PropTypes.func,
  isViewMode: PropTypes.bool,
}

const NpiDeliverableFilesModal = ({ onCloseModal, submission }) => {
  const classes = useModalStyles()
  const { addToast } = useToast()
  const { isDesktop } = useBreakpoint()

  const { id: projectId } = useReactiveVar(currentProject)
  const isRejected = submission?.status === DELIVERABLE_STATUSES.REJECTED
  const isViewMode = isSubmissionReviewed(submission?.status)
  const isReadOnly = submission?.isReadOnly
  const comment = submission?.reviews?.[0]?.comments?.[0].comment

  const [isRequestChangesOpen, setIsRequestChangesOpen] = useState(isRejected)
  const [notes, setNotes] = useState(isViewMode ? comment : '')

  const { media } = submission
  const { filename, url: mediaUrl } = media[0]
  const fileType = filename.split('.').pop().toLowerCase()

  const isPreviewable = PREVIEWABLE_FILE_TYPES.includes(fileType)
  const isPreviewableImage = IMAGE_FILE_TYPES.includes(fileType)

  const [callAddNpiSubmissionReview] = useMutation(ADD_NPI_SUBMISSION_REVIEW, {
    refetchQueries: [
      {
        query: GET_NPI_STEPS,
        variables: {
          projectId,
        },
      },
      {
        query: GET_CURRENT_MILESTONE,
        variables: {
          projectId,
        },
      },
    ],
  })

  const handleOpenRequestChanges = () => {
    setIsRequestChangesOpen(true)
    setNotes('')
  }

  const handleCloseRequestChanges = () => {
    setIsRequestChangesOpen(false)
    setNotes('')
  }

  const handleAddNpiSubmissionReview = (result) => {
    const isAccepting = result === DELIVERABLE_REVIEW_RESULTS.ACCEPTED

    callAddNpiSubmissionReview({
      variables: {
        result,
        submissionId: submission.id,
        comment: notes,
      },
    }).then((res) => {
      if (res.errors) {
        // error toasts handled from the backend
        onCloseModal()
        return
      }

      addToast({
        message: isAccepting
          ? 'Deliverable submission successfully approved'
          : 'Request for changes succesfully sent',
        type: TOAST_TYPES.SUCCESS,
      })

      onCloseModal()
    })
  }

  const renderPreviewContent = () =>
    isPreviewableImage ? (
      <img
        className={clsx(classes.previewImage, {
          [classes.previewImageWithRequest]: isRequestChangesOpen,
        })}
        src={mediaUrl}
        alt={filename}
      />
    ) : (
      <iframe title="preview-media" className={classes.iframePreview} src={mediaUrl} />
    )

  return (
    <Dialog
      open
      fullWidth
      onClose={onCloseModal}
      maxWidth={isDesktop && (isPreviewable || isRequestChangesOpen) ? 'md' : 'xs'}
      disableBackdropClick
      data-test-id="npi--complete-task-file-dialog"
    >
      {isReadOnly ? (
        <GembahDialogTitle onClose={onCloseModal}>{filename}</GembahDialogTitle>
      ) : (
        <GembahDialogTitle onClose={onCloseModal}>
          {isViewMode ? 'View File Status' : 'Review File'}
        </GembahDialogTitle>
      )}
      <DialogContent className={classes.dialogContent}>
        {isPreviewable ? (
          renderPreviewContent()
        ) : (
          <FileList files={media}>
            {({ file }) => (
              <>
                <div className={classes.actionsWrapper}>
                  <Grid item>
                    <IconButton
                      component={Link}
                      href={file.path}
                      target="_blank"
                      rel="noreferrer"
                      className={clsx(classes.downloadFileFileBtn, classes.iconBtn)}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </IconButton>
                  </Grid>
                </div>
              </>
            )}
          </FileList>
        )}
        {isRequestChangesOpen && (
          <RequestChanges
            isViewMode={isViewMode}
            submission={submission}
            onCloseRequestChanges={handleCloseRequestChanges}
            notes={notes}
            setNotes={setNotes}
            onAddNpiSubmissionReview={handleAddNpiSubmissionReview}
          />
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button
          onClick={onCloseModal}
          data-tracking-info={serializeData({
            id: `npi_review-deliverable-modal-cancel-button_click`,
          })}
        >
          Cancel
        </Button>

        <div>
          {isPreviewable && (
            <IconButton
              component={Link}
              href={mediaUrl}
              target="_blank"
              rel="noreferrer"
              className={classes.downloadFileFileBtn}
            >
              <SvgLoader {...DownloadFileIcon} />
            </IconButton>
          )}
          {!isViewMode && !isReadOnly && (
            <>
              <PopButton
                size="small"
                btnStyle="simple"
                disabled={isRequestChangesOpen}
                onClick={handleOpenRequestChanges}
                data-tracking-info={serializeData({
                  id: `npi_review-deliverable-modal-request-change-button_click`,
                })}
              >
                Request Changes
              </PopButton>
              <PopButton
                size="small"
                disabled={isRequestChangesOpen}
                onClick={() => handleAddNpiSubmissionReview(DELIVERABLE_REVIEW_RESULTS.ACCEPTED)}
                data-tracking-info={serializeData({
                  id: `npi_review-deliverable-modal-approve-button_click`,
                })}
              >
                Approve
              </PopButton>
            </>
          )}
        </div>
      </DialogActions>
    </Dialog>
  )
}

NpiDeliverableFilesModal.propTypes = {
  onCloseModal: PropTypes.func,
  submission: PropTypes.object,
}

export default NpiDeliverableFilesModal
