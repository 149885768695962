import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import OutOfOfficeTooltip from '@components_pop/OutOfOfficeTooltip'
import UserLetterAvatar from '@components_pop/users/UserLetterAvatar'

const useStyles = makeStyles((theme) => ({
  messageSender: {
    fontWeight: 600,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(),
  },
}))

const Sender = ({ message }) => {
  const classes = useStyles()

  const { senderUser, thirdPartySenderUser } = message
  const firstName = senderUser?.firstName ?? thirdPartySenderUser?.thirdPartyAccountName ?? ''
  const lastName = senderUser?.lastName ?? ''
  const profilePicture = senderUser?.profilePicture?.url ?? ''

  return (
    <>
      <OutOfOfficeTooltip user={senderUser} isForAvatar>
        <UserLetterAvatar
          alt={`${firstName} ${lastName}`}
          src={profilePicture}
          firstName={firstName}
          lastName={lastName}
        />
      </OutOfOfficeTooltip>
      <span className={classes.messageSender}>{`${firstName} ${lastName}`}</span>
    </>
  )
}

Sender.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    senderUser: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      profilePicture: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    thirdPartySenderUser: PropTypes.shape({
      id: PropTypes.string.isRequired,
      thirdPartyAccountName: PropTypes.string.isRequired,
      user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
}

export default Sender
