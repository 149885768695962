import { useMutation, useApolloClient } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { channelMediaHasChanged } from '@lib/apollo/apolloCache'
import usePusherHelpers from '@lib/pusher/usePusherHelper'
import tracking from '@lib/tracking'

import { TRASH_MESSAGE } from '@graphql/chat/mutators'
import { CHAT_MESSAGE_FRAGMENT } from '@graphql/chat/queries'

import ChatItemMenu from './view'

const ChatItemMenuContainer = ({ anchorEl, messageId, onClose, onEditClick, isEdit }) => {
  const client = useApolloClient()
  const { removePollingGraph } = usePusherHelpers()
  const [message, setMessage] = useState({})
  const [trashConfirmOpen, setTrashConfirmOpen] = useState(false)
  const [trashMessage] = useMutation(TRASH_MESSAGE, {
    refetchQueries: removePollingGraph(['GetChatChannelMessages', 'GetAllChannelMedia']),
  })

  useEffect(() => {
    if (messageId) {
      const selectedMessage = client.readFragment({
        id: `MessageType:${messageId}`,
        fragment: CHAT_MESSAGE_FRAGMENT,
        fragmentName: 'ChatMessageFragment',
      })

      setMessage(selectedMessage || {})
    }
  }, [messageId])

  const handleTrashClick = () => {
    setTrashConfirmOpen(true)
  }

  const handleTrashConfirmationClose = () => {
    tracking.trackEvent({
      id: 'project_chat-message-delete-modal-cancel_click',
      messageId: message.id,
    })

    setTrashConfirmOpen(false)
    onClose()
  }

  const handleTrashMessage = () => {
    tracking.trackEvent({
      id: 'project_chat-message-delete-modal-confirm_click',
      messageId: message.id,
    })

    if (messageId) {
      // https://github.com/apollographql/react-apollo/issues/3781
      trashMessage({
        variables: {
          messageId,
        },
      }).then(
        (res) => {
          if (res.errors) {
            return
          }
          channelMediaHasChanged(true)
        },
        (/* err */) => {}
      )
    }
    // Because we are showing an error toast, go ahead and close it for all cases.
    setTrashConfirmOpen(false)
    onClose()
  }

  const handleEditClick = () => {
    onClose()
    onEditClick()
  }

  if (message.id === messageId) {
    return (
      <ChatItemMenu
        anchorEl={trashConfirmOpen ? null : anchorEl}
        message={message}
        onClose={onClose}
        onEditClick={handleEditClick}
        onTrashClick={handleTrashClick}
        trashConfirmOpen={trashConfirmOpen}
        onTrashCancel={handleTrashConfirmationClose}
        onTrashConfirm={handleTrashMessage}
        isEdit={isEdit}
      />
    )
  }

  return null
}

ChatItemMenuContainer.propTypes = {
  anchorEl: PropTypes.shape({}),
  messageId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
}

export default ChatItemMenuContainer
