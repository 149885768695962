import PropTypes from 'prop-types'
import React from 'react'

import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

import { GRAYSCALE_LIGHTEST } from '@lib/colors'
import { MIME_TYPES_FOR_ICONS } from '@lib/constants'
import { normalizeStringCharsForUrl } from '@lib/dataUtils'
import { MOBILE_BREAKPOINT } from '@lib/theme'

import MediaIcon from '@components_pop/attachments/MediaIcon'

const useStyles = makeStyles((theme) => ({
  media: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 8,
    marginBottom: theme.spacing(2),
    borderColor: GRAYSCALE_LIGHTEST,
    color: theme.palette.common.black,
    cursor: 'pointer',
  },
  mediaContainer: {
    display: 'flex',
  },
  mediaIconItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: theme.spacing(6),
    minWidth: theme.spacing(6),
    '& img': {
      width: '100%',
      height: 200,
      objectFit: 'cover',
      [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
        height: 240,
      },
    },
    '& svg': {
      height: '100%',
      width: '100%',
      maxHeight: theme.spacing(5),
      maxWidth: theme.spacing(5),
    },
    borderRight: `1px solid ${GRAYSCALE_LIGHTEST}`,
  },
  fileNameItem: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    padding: theme.spacing(),
  },
  fileNameText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'pre-wrap',
  },
  attachmentLink: {
    '&:hover': {
      textDecoration: 'none',
    },
    '&:hover *': {
      color: 'inherit',
    },
  },
}))

const Media = ({ url, media }) => {
  const classes = useStyles()
  const isGoogleDrive = media.mimetype === MIME_TYPES_FOR_ICONS.GOOGLE_DRIVE

  return (
    <Link
      href={normalizeStringCharsForUrl(isGoogleDrive ? media.url : url)}
      target="_blank"
      rel="noreferrer"
      className={classes.attachmentLink}
    >
      <div className={classes.media}>
        <Grid container direction="row" wrap="nowrap" className={classes.mediaContainer}>
          <Grid item className={classes.mediaIconItem}>
            <MediaIcon mimetype={media.mimetype} />
          </Grid>
          <Grid item className={classes.fileNameItem} zeroMinWidth>
            <span className={classes.fileNameText}>{media.filename}</span>
          </Grid>
        </Grid>
      </div>
    </Link>
  )
}

Media.propTypes = {
  url: PropTypes.string,
  media: PropTypes.shape({
    id: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    mimetype: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string,
  }).isRequired,
}

export default Media
