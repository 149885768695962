import clsx from 'clsx'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React from 'react'

import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

import EllipsisIcon from '@public/svg/icons/ellipsis-icon.svg'

import {
  BOX_BORDER_COLOR,
  THIRD_TEXT_COLOR,
  THIRD_GREY_BACKGROUND,
  CHAT_DIRECT_LINK_BACKGROUND,
  CHAT_UNREAD_MESSAGE_BACKGROUND,
} from '@lib/colors'
import { serializeData } from '@lib/tracking'

import SvgLoader from '@components_pop/SvgLoader'
import ChatItemEditor from '@components_pop/chat/ChatItemEditor'
import ChatMessage from '@components_pop/chat/ChatMessage'

import EmptyState from './empty'

const today = moment()
const yesterday = moment().subtract(1, 'days')

const useStyles = makeStyles((theme) => ({
  '@global': {
    '@keyframes messageFlash': {
      '0%': {
        opacity: 1,
      },
      '30%': {
        backgroundColor: CHAT_DIRECT_LINK_BACKGROUND,
      },
      '100%': {
        backgroundColor: 'inherit',
      },
    },
  },
  messageFlash: {
    animationName: 'messageFlash',
    animationDuration: '2000ms',
    animationIterationCount: 1,
    animationTimingFunction: 'ease-in-out',
  },
  unread: {
    backgroundColor: CHAT_UNREAD_MESSAGE_BACKGROUND,
  },
  messageWindow: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    paddingBottom: 4, // minimal bottom padding required so that bottom-most message meets
    // boundary requirements for getting marked as read
    width: '100%',
    overflow: 'auto',
    '& .loading-chat-history': {
      color: THIRD_TEXT_COLOR,
      textAlign: 'center',
    },
  },
  messageItemContainer: {
    position: 'relative',
    padding: 5,
    borderRadius: 10,
    '& button [class*="MuiSvgIcon-root"]': {
      fill: 'transparent',
    },
    '&:hover': {
      backgroundColor: THIRD_GREY_BACKGROUND,
      outline: `1px solid #000`,
    },
    '&:hover button': {
      display: 'block',
    },
  },
  messageItemMenuButtonRoot: {
    padding: 15,
    display: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    '& > span > div': {
      width: 20,
      height: 20,
    },
  },
  dateHr: {
    height: '1.5em',
    lineHeight: '1.5em',
    position: 'relative',
    outline: 0,
    border: 0,
    textAlign: 'center',
    '&::before': {
      content: '""',
      background: BOX_BORDER_COLOR,
      position: 'absolute',
      left: 0,
      top: '50%',
      width: '100%',
      height: 1,
    },
    '&::after': {
      content: 'attr(data-content)',
      position: 'relative',
      display: 'inline-block',
      color: theme.palette.text.secondary,
      padding: `0 ${theme.spacing()}px`,
      lineHeight: '1.5em',
      backgroundColor: 'white',
    },
  },
  newMessagesChip: {
    position: 'absolute',
    zIndex: 999,
    top: theme.spacing(2),
    left: '50%',
    transform: 'translate(-50%, 0)',
    cursor: 'pointer',
  },
}))

const ChatChannelWindow = React.forwardRef(
  (
    {
      channelId,
      userId,
      elementId,
      directMessageId,
      editMessageId,
      isLoadingOlderMessages,
      messages,
      showUnreadMessagesPill,
      onUnreadMessagesPillClick,
      onScroll,
      onMessageMenuOpen,
      onEditMessageClose,
    },
    ref
  ) => {
    const classes = useStyles()
    const zone = moment.tz.guess(true)

    let prevMessageMoment
    let dateLabel = ''
    let displayDateLabel = true

    if (!messages.length) {
      return <EmptyState channelId={channelId} />
    }

    return (
      <div ref={ref} id={elementId} className={classes.messageWindow} onScroll={onScroll}>
        {isLoadingOlderMessages && <div className="loading-chat-history">Loading history...</div>}
        {showUnreadMessagesPill && (
          <Chip
            className={classes.newMessagesChip}
            label="Unread Messages"
            color="secondary"
            onClick={onUnreadMessagesPillClick}
            data-tracking-info={serializeData({
              id: 'project_chat-unread-messages-chip_click',
            })}
          />
        )}
        {messages.map((message, idx) => {
          const isFromSelf = message?.receipt?.isFromSelf || message?.senderUser?.id === userId // handles global chatters
          const messageMoment = moment(message.postedAt).tz(zone)
          const hasBeenRead = !message?.receipt || Boolean(message?.receipt?.readAt) || false

          // ===========================================================================================
          // Date group divider (with horizontal rule)
          // ===========================================================================================
          if (!prevMessageMoment || messageMoment.isAfter(prevMessageMoment, 'day')) {
            if (messageMoment.isSame(today, 'day')) {
              dateLabel = 'TODAY'
            } else if (messageMoment.isSame(yesterday, 'day')) {
              dateLabel = 'YESTERDAY'
            } else {
              dateLabel = messageMoment.format('MMM Do, YYYY')
            }
            displayDateLabel = true
            prevMessageMoment = messageMoment
          } else {
            displayDateLabel = false
          }

          // ===========================================================================================
          // If a message is in edit mode:
          // ===========================================================================================
          if (editMessageId && editMessageId === message.id) {
            return (
              <ChatItemEditor
                key={`edit-message-${message.id}`}
                channelId={channelId}
                message={message}
                onClose={onEditMessageClose}
              />
            )
          }

          // ===========================================================================================
          // Message
          // ===========================================================================================
          const messageClass = clsx(classes.messageItemContainer, {
            [classes.unread]: !hasBeenRead,
            [classes.messageFlash]: message.id === directMessageId,
          })

          return (
            <div key={`message-${message.id}`} data-test-id={`chat-message-${idx + 1}`}>
              {displayDateLabel && <hr className={classes.dateHr} data-content={dateLabel} />}
              <div className={messageClass}>
                <ChatMessage message={message} />
                {isFromSelf && (
                  <IconButton
                    classes={{ root: classes.messageItemMenuButtonRoot }}
                    size="small"
                    aria-label="options"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={onMessageMenuOpen(message.id)}
                    data-tracking-info={serializeData({
                      id: 'project_chat-message-menu_click',
                      messageId: message.id,
                    })}
                  >
                    <SvgLoader {...EllipsisIcon} />
                  </IconButton>
                )}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
)

ChatChannelWindow.propTypes = {
  channelId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
  directMessageId: PropTypes.string,
  editMessageId: PropTypes.string,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      postedAt: PropTypes.string.isRequired,
      editedAt: PropTypes.string,
      receipt: PropTypes.shape({
        isFromSelf: PropTypes.bool,
        readAt: PropTypes.string,
      }),
    })
  ).isRequired,
  showUnreadMessagesPill: PropTypes.bool.isRequired,
  onUnreadMessagesPillClick: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
  onMessageMenuOpen: PropTypes.func.isRequired,
  onEditMessageClose: PropTypes.func.isRequired,
  isLoadingOlderMessages: PropTypes.bool,
}

export default ChatChannelWindow
