/* eslint-disable jsx-a11y/control-has-associated-label */
import { useReactiveVar } from '@apollo/client'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { InputLabel, Select } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

import SortDownIcon from '@public/svg/icons/sort-down-icon.svg'
import SortUpIcon from '@public/svg/icons/sort-up-icon.svg'

import { channelMediaHasChanged } from '@lib/apollo/apolloCache'
import { PRIMARY_TEXT_COLOR, THIRD_BLUE_BACKGROUND } from '@lib/colors'
import { MOBILE_BREAKPOINT } from '@lib/theme'
import tracking, { serializeData } from '@lib/tracking'

import SvgLoader from '@components_pop/SvgLoader'

import useBreakpoint from '@hooks/useBreakpoint'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      marginTop: theme.spacing(2),
    },
  },
  outlined: {
    transform: 'translate(14px, 16px) scale(1)',
    fontSize: '0.9rem',
  },
  select: {
    padding: theme.spacing(1.5),
    minWidth: 70,
  },
  label: {
    display: 'none',
    fontSize: '0.85rem',
    color: PRIMARY_TEXT_COLOR,
    textTransform: 'uppercase',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      display: 'block',
    },
  },
  clearButton: {
    minWidth: 0,
    padding: 0,
    color: THIRD_BLUE_BACKGROUND,
    '&:hover': {
      background: 'transparent',
    },
  },
  sortfilter: {
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
      background: 'transparent',
    },
    '& > span > div': {
      marginRight: theme.spacing(1),
      width: 25,
      height: 25,
    },
  },
}))

const DEFAULT_FILTERS = {
  fileType: '',
  uploader: '',
  descendingDate: true,
}

const Filters = ({ mediaList, setFilteredMedia }) => {
  const classes = useStyles()
  const channelMediaHasChangedContext = useReactiveVar(channelMediaHasChanged)
  const aggregatedMedia = _.union(...Object.values(mediaList || {}).map((value) => value.media))
  const [filters, setFilters] = useState(DEFAULT_FILTERS)

  const { isDesktop, isMobile } = useBreakpoint()

  const filterByFileType = (list) => {
    if (!filters.fileType.length) return list
    return list.filter(({ media }) => media.mimetype === filters.fileType)
  }

  const filterByUploadType = (list) => {
    if (!filters.uploader.length) return list
    return list.filter(({ media }) => {
      const name = `${media?.uploadUser?.firstName} ${media?.uploadUser?.lastName}`
      return name === filters.uploader
    })
  }

  const sortByDate = (list) => {
    return [...list].sort((mediaA, mediaB) => {
      const mediaADate = new Date(mediaA.media.uploadConfirmedAt).getTime()
      const mediaBDate = new Date(mediaB.media.uploadConfirmedAt).getTime()

      return filters.descendingDate ? mediaBDate - mediaADate : mediaADate - mediaBDate
    })
  }

  const filterMethods = [filterByFileType, filterByUploadType, sortByDate]

  const changeFilterValue = (e) => {
    const { value, name } = e.target
    const trackingId = name === 'uploader' ? 'uploader' : 'file-type'
    tracking.trackEvent({
      id: `project_attachments-${trackingId}-option_click`,
      [name]: e.target.value,
    })
    setFilters({ ...filters, [name]: value })
  }

  const changeSortFilterValue = () => {
    setFilters({ ...filters, descendingDate: !filters.descendingDate })
  }

  const handleClearFilters = () => {
    const { descendingDate, ...filtersToClear } = DEFAULT_FILTERS
    setFilters({ descendingDate: filters.descendingDate, ...filtersToClear })
  }

  const extractMediaTypes = () => {
    return [...new Set(aggregatedMedia.map(({ media }) => media?.mimetype))]
  }

  const extractUploaders = () => {
    return [
      ...new Set(
        aggregatedMedia.map(
          ({ media }) => `${media?.uploadUser?.firstName} ${media?.uploadUser?.lastName}`
        )
      ),
    ]
  }

  useEffect(() => {
    if (channelMediaHasChangedContext) {
      handleClearFilters()
    }
  }, [channelMediaHasChangedContext])

  useEffect(() => {
    let filteredMedia = { ...mediaList }

    filterMethods.forEach((method) => {
      filteredMedia = Object.entries(filteredMedia).reduce((soFar, [category, props]) => {
        return {
          ...soFar,
          [category]: {
            ...filteredMedia[category],
            // the linter is acting up like this is some kind of component
            // eslint-disable-next-line react/prop-types
            media: method(props.media),
          },
        }
      }, filteredMedia)
    })

    setFilteredMedia({ ...filteredMedia })
  }, [filters, setFilteredMedia, mediaList])

  const mediaTypes = extractMediaTypes()
  const uploaders = extractUploaders()

  const renderSortDateBtn = () => (
    <IconButton
      data-test-id="attachments-filter-sort-date"
      className={classes.sortfilter}
      onClick={changeSortFilterValue}
      disableRipple
      data-tracking-info={serializeData({
        id: 'project_attachments-filter-date-sort_click',
        newOrder: filters.descendingDate ? 'asc' : 'desc',
      })}
      disableFocusRipple
      disableTouchRipple
    >
      <SvgLoader {...(filters.descendingDate ? SortDownIcon : SortUpIcon)} />
    </IconButton>
  )

  return (
    <>
      {isMobile && renderSortDateBtn()}
      <div className={classes.root}>
        <span className={classes.label}>filter by</span>
        <FormControl variant="outlined">
          <InputLabel classes={{ outlined: classes.outlined }}>File Type</InputLabel>
          <Select
            name="fileType"
            classes={{ root: classes.select }}
            data-test-id="attachments-filter-file-type"
            value={filters.fileType}
            onChange={changeFilterValue}
            label="File Type"
            disabled={!mediaTypes.length}
            data-tracking-info={serializeData({
              id: 'project_attachments-file-type-dropdown_click',
            })}
            native
          >
            <>
              <option value="" />
              {mediaTypes.map((v) => (
                <option key={v} value={v}>
                  {v}
                </option>
              ))}
            </>
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel classes={{ outlined: classes.outlined }}>Uploader</InputLabel>
          <Select
            name="uploader"
            classes={{ root: classes.select }}
            data-test-id="attachments-filter-uploader"
            value={filters.uploader}
            onChange={changeFilterValue}
            label="Uploader"
            disabled={!uploaders.length}
            data-tracking-info={serializeData({
              id: 'project_attachments-uploader-dropdown_click',
            })}
            native
          >
            <>
              <option value="" />
              {uploaders.map((v) => (
                <option key={v} value={v}>
                  {v}
                </option>
              ))}
            </>
          </Select>
        </FormControl>
        <Button
          data-test-id="attachments-filter-clear"
          onClick={handleClearFilters}
          className={classes.clearButton}
          data-tracking-info={serializeData({
            id: 'project_attachments-clear-filter_click',
          })}
        >
          Clear
        </Button>
        {isDesktop && renderSortDateBtn()}
      </div>
    </>
  )
}

Filters.propTypes = {
  mediaList: PropTypes.shape({
    externalChannelAttachment: PropTypes.shape({
      media: PropTypes.array,
    }),
    internalChannelAttachment: PropTypes.shape({
      media: PropTypes.array,
    }),
    designChannelAttachment: PropTypes.shape({
      media: PropTypes.array,
    }),
  }),
  setFilteredMedia: PropTypes.func,
}

export default Filters
