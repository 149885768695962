import { useMutation } from '@apollo/client'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'

import usePusherHelpers from '@lib/pusher/usePusherHelper'
import tracking from '@lib/tracking'

import { SEND_MESSAGE } from '@graphql/chat/mutators'

import ChatEditor from '@components_pop/chat/ChatEditor'
import { clearQueryString } from '@components_pop/chat/utils'

const ChatWindowEditor = ({ channelId, isInternal }) => {
  const router = useRouter()
  const { removePollingGraph } = usePusherHelpers()
  const refetchQueries = removePollingGraph(['GetChatChannelMessages'])
  const sendMessageOptions = refetchQueries.length
    ? {
        refetchQueries,
      }
    : {}

  const [sendMessage] = useMutation(SEND_MESSAGE, {
    ...sendMessageOptions,
    variables: { channelId },
    onCompleted(res) {
      // Due to how the Markdown Editor component we use works, we can't access the updated
      // state inside the handleSendMessage function, and therefore can't get the correct channelId
      // Hence why the logic is being called here.

      tracking.trackEvent({
        id: 'project_chat-message_sent',
        messageId: res.data?.sendMessageToChannel?.message?.id,
      })
      localStorage.removeItem(`chatWindowMessage${channelId}`)
      // By clearing direct links, this prevents the chat from scrolling to the message every time the user sends a message
      clearQueryString(router)
    },
  })

  const handleSendMessage = ({ messageBody }) => {
    // Can't call onCompleted here because of
    // https://github.com/apollographql/react-apollo/issues/3781
    // So it was declared inside the mutation
    sendMessage({
      variables: {
        messageBody,
      },
    })
  }

  return (
    <ChatEditor channelId={channelId} isInternal={isInternal} onSendMessage={handleSendMessage} />
  )
}

ChatWindowEditor.propTypes = {
  channelId: PropTypes.string.isRequired,
  isInternal: PropTypes.bool,
}

export default ChatWindowEditor
