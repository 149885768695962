import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import CalendarIcon from '@public/svg/icons/calendar.svg'

import { COMMON_WHITE, TEAL_DOLLHOUSE, TEAL_LITEBRITE } from '@lib/colors'

import {
  EMPTY_STATE,
  NPI_STATUS_HIERACHY,
  NPI_VISUAL_DATE_FORMAT,
} from '@components_pop/ProjectDetailsNPI/constants'
import SvgLoader from '@components_pop/SvgLoader'

const useStyles = makeStyles(() => ({
  statusAndAction: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    fontSize: 10,
    background: ({ status }) => status.color,
    color: ({ status }) =>
      status.position === NPI_STATUS_HIERACHY.IN_PROGRESS ? COMMON_WHITE : TEAL_DOLLHOUSE,
    '& > *': {
      marginRight: 10,
    },
  },
  statusWrapper: {
    '& $headerBox': {
      borderRadius: '0 0 10px 0',
    },
  },
  icon: {
    width: 20,
    height: 20,
  },
  datesWrapper: {
    display: 'flex',
    '& $headerBox': {
      borderRight: 'none',
      minWidth: 250,
      color: TEAL_DOLLHOUSE,
      '&:first-child': {
        borderRadius: '0 0 0 10px',
        background: ({ status }) =>
          status.position !== NPI_STATUS_HIERACHY.TO_DO ? TEAL_LITEBRITE : 'transparent',
      },
      '&:last-child': {
        background: ({ status }) =>
          status.position === NPI_STATUS_HIERACHY.DONE ? TEAL_LITEBRITE : 'transparent',
      },
    },
  },
}))

const NpiMilestoneHeader = ({ milestone }) => {
  const { status, startedAt, completedAt } = milestone
  const { text: milestoneStatus } = status

  const classes = useStyles({ status })

  return (
    <div className={classes.statusAndAction}>
      <div className={classes.statusWrapper}>
        <div className={classes.headerBox}>
          <span>Status:</span> <strong>{milestoneStatus}</strong>
        </div>
      </div>
      <div className={classes.datesWrapper}>
        <div className={classes.headerBox}>
          <SvgLoader {...CalendarIcon} className={classes.icon} />
          <span>Started:</span>{' '}
          <strong>
            {startedAt ? moment(startedAt).format(NPI_VISUAL_DATE_FORMAT) : EMPTY_STATE}
          </strong>
        </div>
        <div className={classes.headerBox}>
          <SvgLoader {...CalendarIcon} className={classes.icon} />
          <span>Completed:</span>{' '}
          <strong>
            {completedAt ? moment(completedAt).format(NPI_VISUAL_DATE_FORMAT) : EMPTY_STATE}
          </strong>
        </div>
      </div>
    </div>
  )
}

NpiMilestoneHeader.propTypes = {
  milestone: PropTypes.object,
}

export default NpiMilestoneHeader
