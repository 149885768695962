import { useReactiveVar } from '@apollo/client'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { Tooltip } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

import PaperClipIcon from '@public/svg/icons/attachment-pin-icon.svg'
import InfoIcon from '@public/svg/icons/info-icon.svg'
import PaperPlaneIcon from '@public/svg/icons/paper-plane.svg'

import { currentAccountUser, currentProject, currentUser } from '@lib/apollo/apolloCache'
import {
  THIRD_GREY_BACKGROUND,
  PRIMARY_GREY_BORDER,
  GRAYSCALE_LIGHTEST,
  ERROR_LIGHT,
  BACKGROUND_DEFAULT,
} from '@lib/colors'
import { serializeData } from '@lib/tracking'
import { hasPermission } from '@lib/userAuth'

import SvgLoader from '@components_pop/SvgLoader'

import useBreakpoint from '@hooks/useBreakpoint'

import { MarkdownEditor } from './MarkdownEditor'
import { MAX_MSG_LENGTH } from './constants'

const useStyles = makeStyles((theme) => ({
  '@global': {
    '#block-menu-container': {
      zIndex: theme.zIndex.modal + 1,
    },
  },
  outerRoot: {
    width: '100%',
    position: 'relative',
  },
  root: {
    position: 'relative',
    border: `1px solid ${theme.palette.grayscale.light}`,
    borderRadius: 5,
  },
  rootMobile: {
    display: 'flex',
    flexDirection: 'row',
  },
  editRoot: {
    position: 'relative',
    borderRadius: 5,
    border: `3px solid ${PRIMARY_GREY_BORDER}`,
    backgroundColor: THIRD_GREY_BACKGROUND,
    marginBottom: theme.spacing(2),
    '& .ProseMirror': {
      fontSize: 16, // must be at least 16 or mobile breaks
      fontFamily: 'Roboto',
      backgroundColor: THIRD_GREY_BACKGROUND,
      '& h1': {
        fontFamily: 'Poppins !important',
      },
    },
  },
  editorContainer: {
    position: 'relative', // necessary for responsive button placement
    padding: '2px',
    minHeight: theme.spacing(7),
    maxHeight: '50vh',
    overflow: 'auto',
    borderRadius: 3,
    '& .ProseMirror': {
      backgroundColor: THIRD_GREY_BACKGROUND,
      fontSize: 16, // must be at least 16 or mobile breaks
      fontFamily: 'Roboto',
      minHeight: `${theme.spacing(7)}px !important`,
      boxShadow: 'none !important',
      border: `1px solid ${GRAYSCALE_LIGHTEST}`,
      padding: `${theme.spacing()}px !important`,
      '& button.block-menu-trigger': {
        // this is the editor library's poorly placed and unnecessary '+' button
        display: 'none',
      },
      '& h1': {
        fontFamily: 'Poppins !important',
      },
    },
    '& .remirror-theme': {
      '& .remirror-toolbar': {
        position: 'sticky',
        zIndex: '1',
        backgroundColor: BACKGROUND_DEFAULT,
        top: '-2px',
        paddingBottom: theme.spacing(),
      },
      '& .remirror-editor-wrapper': {
        paddingTop: '0',
      },
      '& .remirror-floating-popover': {
        zIndex: '999',
        '& .remirror-toolbar': {
          paddingBottom: '0',
        },
        '& .link-editor': {
          boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
          padding: '2px 6px',
          border: `1px solid ${PRIMARY_GREY_BORDER}`,
          marginLeft: '4px',
        },
      },
    },
  },
  editorContainerMobile: {
    flex: 1,
  },
  mobileActionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  editButtonsContainer: {
    padding: 5,
    textAlign: 'right',
    borderTop: `3px solid ${PRIMARY_GREY_BORDER}`,
    '& button': {
      margin: `0 5px 0 0`,
    },
  },
  hidden: {
    display: 'none !important',
  },
  editorHints: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.75rem',
    '& > span:not(:first-child)': {
      marginLeft: theme.spacing(1.5),
    },
  },
  chatButton: {
    fontSize: theme.spacing(2), // icon size
    padding: theme.spacing(),
  },
  iconWrapper: {},
  notification: {
    display: 'flex',
    alignItems: 'center',
    '& $iconWrapper': {
      marginLeft: theme.spacing(1),
    },
    '& > strong': {
      marginRight: 3,
    },
  },
  maximumExceeded: {
    backgroundColor: ERROR_LIGHT,
  },
  icon: {
    width: 20,
    height: 20,
  },
}))

const ChatEditor = React.forwardRef(
  (
    { isEdit, editValue, onEditCancel, onChange, onUploadFiles, onSendMessage, markdownEditorRef },
    ref
  ) => {
    const classes = useStyles()
    const user = useReactiveVar(currentUser)
    const accountUser = useReactiveVar(currentAccountUser)
    const { isUserOnProjectTeam } = useReactiveVar(currentProject)
    const { isMobile } = useBreakpoint()

    // ============================================================================
    // Chat Editor (instance of remirror)
    // ============================================================================

    const editor = (
      <MarkdownEditor
        placeholder="Write your message"
        maxLength={MAX_MSG_LENGTH}
        maximumExceededClassName={classes.maximumExceeded}
        initialContent={isEdit ? editValue : ''}
        onChange={onChange}
        isMobile={isMobile}
        enterKeyPressHandler={onSendMessage}
        ref={markdownEditorRef}
        disableToolbar={isMobile}
      />
    )

    // ============================================================================
    // Edit message buttons: save/cancel
    // ============================================================================

    const editMessageButtons = (
      <Grid container className={classes.editButtonsContainer}>
        <Grid item>
          <Button onClick={onEditCancel} variant="outlined">
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              onSendMessage()
            }}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    )

    // ============================================================================
    // File upload button
    // (only for non-edit mode)
    // ============================================================================

    const canUploadFiles = Boolean(isUserOnProjectTeam || user.staffUser)
    const fileUploadButton = canUploadFiles ? (
      <IconButton
        id="chat-editor-attach-button"
        className={classes.chatButton}
        size="small"
        color="primary"
        onClick={onUploadFiles}
        title="Upload Files"
        data-test-id="chat-editor-attach-button"
        data-tracking-info={serializeData({
          id: 'project_chat-send-button_click',
        })}
      >
        <SvgLoader className={classes.icon} {...PaperClipIcon} />
      </IconButton>
    ) : (
      <Tooltip arrow placement="top" title="You must be on the project team to upload.">
        <span>
          <IconButton
            id="chat-editor-attach-button"
            className={classes.chatButton}
            size="small"
            color="primary"
            title="Upload Files"
            data-test-id="chat-editor-attach-button"
            data-tracking-info={serializeData({
              id: 'project_chat-send-button_click',
              disabled: true,
            })}
            disabled
          >
            <SvgLoader className={classes.icon} {...PaperClipIcon} />
          </IconButton>
        </span>
      </Tooltip>
    )

    // ============================================================================
    // Send message button
    // (only for non-edit mode)
    // ============================================================================

    const sendMessageButton = (
      <IconButton
        id="chat-editor-send-button"
        className={classes.chatButton}
        size="small"
        color="primary"
        onClick={onSendMessage}
        title="Send"
        data-tracking-info={serializeData({
          id: 'project_chat-send-button_click',
        })}
      >
        <SvgLoader className={classes.icon} {...PaperPlaneIcon} />
      </IconButton>
    )

    // ============================================================================
    // Chat helper text
    // (only for desktop, non-edit mode)
    // ============================================================================

    const ChatNotificationInfo = () => (
      <span className={classes.notification}>
        <strong>@yourClient</strong> to send them a notification.
        <Tooltip
          placement="top"
          arrow
          title="They only get an email if they did not view your message."
        >
          <span className={classes.iconWrapper}>
            <SvgLoader className={classes.icon} {...InfoIcon} />
          </span>
        </Tooltip>
      </span>
    )

    const chatHelperText = (
      <div id="chat-editor-hint-text" className={classes.editorHints}>
        <span>
          <strong>Return</strong> to send
        </span>
        <span>
          <strong>Shift + Return</strong> to add a new line
        </span>
        {hasPermission.GEMBAH(accountUser) && <ChatNotificationInfo />}
      </div>
    )

    // ============================================================================
    // Render logic
    // ============================================================================

    if (isEdit) {
      return (
        <Box className={classes.outerRoot} ref={ref}>
          <div className={classes.editRoot}>
            <Box id="chat-editor-box" className={classes.editorContainer}>
              {editor}
            </Box>
            {editMessageButtons}
          </div>
        </Box>
      )
    }

    if (isMobile) {
      return (
        <Box className={classes.outerRoot} ref={ref}>
          <div className={clsx(classes.root, classes.rootMobile)}>
            <Box
              id="chat-editor-box"
              className={clsx(classes.editorContainer, classes.editorContainerMobile)}
            >
              {editor}
            </Box>
            <div className={classes.mobileActionsContainer}>
              {fileUploadButton}
              {sendMessageButton}
            </div>
          </div>
        </Box>
      )
    }

    return (
      <Box className={classes.outerRoot} ref={ref}>
        <div className={classes.root}>
          <Box
            id="chat-editor-box"
            data-test-id="chat-editor-box-input"
            data-tracking-info={serializeData({
              id: isEdit ? 'project_chat-msg-edit-input_click' : 'project_chat-editor-input_click',
            })}
            className={classes.editorContainer}
          >
            {editor}
          </Box>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>{chatHelperText}</Grid>
            <Grid item>
              {fileUploadButton}
              {sendMessageButton}
            </Grid>
          </Grid>
        </div>
      </Box>
    )
  }
)

ChatEditor.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  editValue: PropTypes.string.isRequired,
  onEditCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onUploadFiles: PropTypes.func.isRequired,
  markdownEditorRef: PropTypes.shape({
    current: PropTypes.shape({}),
  }),
  onSendMessage: PropTypes.func.isRequired,
}

export default ChatEditor
