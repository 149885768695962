import gql from 'graphql-tag'

export const SEND_MESSAGE = gql`
  mutation SendMessageToChannel($channelId: ID!, $messageBody: String!) {
    sendMessageToChannel(channelId: $channelId, messageBody: $messageBody) {
      message {
        id
      }
    }
  }
`

export const TRASH_MESSAGE = gql`
  mutation TrashMessage($messageId: ID!) {
    trashMessage(messageId: $messageId) {
      trashedMessage {
        id
      }
    }
  }
`

export const MARK_MESSAGE_AS_READ = gql`
  mutation MarkMessageAsRead($messageId: ID!) {
    markMessageAsRead(messageId: $messageId) {
      readReceipt {
        id
        readAt
      }
    }
  }
`

export const EDIT_MESSAGE = gql`
  mutation EditMessage($messageId: ID!, $messageBody: String!) {
    editMessage(messageId: $messageId, messageBody: $messageBody) {
      message {
        id
        messageBody
      }
    }
  }
`
