/* eslint-disable react/jsx-props-no-spreading */
import { useMutation, useReactiveVar } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { makeStyles } from '@material-ui/core/styles'

import PopButton from '@forms/PopButton'
import PopSelectField from '@forms/PopSelectField'

import { currentProject, editingJourneyTabElementId } from '@lib/apollo/apolloCache'
import { PRIMARY_TEXT_GREY } from '@lib/colors'
import { makeEllipsisText } from '@lib/theme'
import { serializeData } from '@lib/tracking'

import { UPDATE_NPI_MILESTONE_POSITION, UPDATE_NPI_STEP_POSITION } from '@graphql/npi/mutators'
import { GET_NPI_STEPS } from '@graphql/npi/queries'

import { NPI_MENU_TYPE_ITEMS } from '@components_pop/ProjectDetailsNPI/constants'
import {
  extractTasksForClient,
  extractTasksForInternal,
} from '@components_pop/ProjectDetailsNPI/utils'
import { TOAST_TYPES } from '@components_pop/Toast'
import GembahDialogTitle from '@components_pop/dialog/GembahDialogTitle'

import useToast from '@hooks/useToast'

const useModalStyles = makeStyles((theme) => ({
  modalSubHeader: {
    ...makeEllipsisText(400),
    fontSize: '0.65rem',
    color: PRIMARY_TEXT_GREY,
    marginTop: theme.spacing(0.5),
  },
  dialogContent: {
    padding: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    '& > div': {
      flex: 1,
    },
  },
  actionsWrapper: {
    display: 'flex',
  },
}))

const generatePositionOptions = (items, currentId, type) => {
  return items.map((item, i) => {
    const isSamePosition = item.id === currentId
    const isMilestone = type === NPI_MENU_TYPE_ITEMS.MILESTONE
    const isDeliverable = type === NPI_MENU_TYPE_ITEMS.DELIVERABLE
    const isDisabled = isSamePosition || (isMilestone && (item.startedAt || item.completedAt))

    const itemName = isDeliverable ? item?.deliverable?.name : item.name

    return {
      key: isMilestone ? item.milestoneIndex : item.position,
      value: `${i + 1} - ${itemName}${isSamePosition ? ' (current)' : ''}`,
      disabled: isDisabled,
    }
  })
}

const generateMilestoneOptions = (itemsWithMilestones) => {
  return itemsWithMilestones.reduce((milestones, currentItem, i) => {
    return {
      ...milestones,
      [currentItem.id]: {
        milestoneName: `${i + 1} - ${currentItem.name}`,
        items: Object.values(currentItem.children),
        disabled: currentItem?.completedAt || currentItem?.startedAt,
      },
    }
  }, {})
}

const generateStageOptions = (itemsWithStages) => {
  return itemsWithStages.reduce((stages, currentItem, i) => {
    const isLastItem = i === itemsWithStages.length - 1
    const isLastMilestoneInStage =
      isLastItem || currentItem.stageId !== itemsWithStages[i + 1].stepId
    const mergedMilestones = [...(stages[currentItem.stageId]?.items ?? []), currentItem]

    const mergedStages = {
      ...stages,
      [currentItem.stageId]: {
        ...(stages[currentItem.stageId] ?? {}),
        stageName: `${Object.keys(stages).length} - ${currentItem.stageName}`,
        items: mergedMilestones,
      },
    }

    // we verify if it should disabled if it is the last milestone in that stage collection
    // so we don't run this check on every pass
    if (isLastMilestoneInStage) {
      mergedStages[currentItem.stageId].disabled = mergedMilestones.every(
        (milestone) => milestone.completedAt || milestone?.startedAt
      )
    }

    return mergedStages
  }, {})
}

const filterSelectableOptions = (items, type) => {
  if (type === NPI_MENU_TYPE_ITEMS.MILESTONE) return items

  return type === NPI_MENU_TYPE_ITEMS.DELIVERABLE
    ? extractTasksForClient(items)
    : extractTasksForInternal(items)
}

const NpiMoveModal = ({ onCloseModal, itemOptions, npiSteps }) => {
  const classes = useModalStyles()
  const { addToast } = useToast()

  const project = useReactiveVar(currentProject)

  const isMilestone = itemOptions.type === NPI_MENU_TYPE_ITEMS.MILESTONE
  const currentItem = itemOptions.item

  const { handleSubmit, register, watch, trigger, setValue } = useForm()

  const [callUpdateNpiStepPosition, { loading: isUpdatingStepPosition }] = useMutation(
    UPDATE_NPI_STEP_POSITION,
    {
      refetchQueries: [{ query: GET_NPI_STEPS, variables: { projectId: project?.id } }],
    }
  )

  const [callUpdateNpiMilestonePosition, { loading: isUpdatingMilestonePosition }] = useMutation(
    UPDATE_NPI_MILESTONE_POSITION,
    {
      refetchQueries: [{ query: GET_NPI_STEPS, variables: { projectId: project?.id } }],
    }
  )

  const handleSave = (values) => {
    const position = parseInt(values.position, 10)
    editingJourneyTabElementId(isMilestone ? currentItem.id : currentItem.npiStepId)

    const method =
      itemOptions.type === NPI_MENU_TYPE_ITEMS.MILESTONE
        ? callUpdateNpiMilestonePosition({
            variables: {
              milestoneId: currentItem.id, // milestone id
              stageId: values.stage,
              position,
            },
          })
        : callUpdateNpiStepPosition({
            variables: {
              stepId: currentItem.npiStepId,
              milestoneId: values.milestone,
              position,
            },
          })

    method.then((res) => {
      editingJourneyTabElementId(null)
      if (res.errors) return

      addToast({
        message: 'Item has been successfully moved',
        type: TOAST_TYPES.SUCCESS,
      })

      onCloseModal()
    })
  }

  const parentOptions = isMilestone
    ? generateStageOptions(npiSteps)
    : generateMilestoneOptions(npiSteps)
  const { stage: selectedStage, milestone: selectedMilestone } = watch(['stage', 'milestone'])

  const correctParentId = isMilestone ? selectedStage : selectedMilestone
  const positionItems = parentOptions[correctParentId]?.items
    ? filterSelectableOptions(parentOptions[correctParentId]?.items, itemOptions.type)
    : []
  const positionOptions = generatePositionOptions(positionItems, currentItem.id, itemOptions.type)

  const handleFindFirstAvailablePosition = () => {
    if (positionOptions.length) {
      const firstAvailableOption = positionOptions.find((option) => !option.disabled)?.key
      setValue('position', firstAvailableOption)
    }
  }

  useEffect(() => {
    trigger()
  }, [trigger])

  const filteredParentOptions = Object.entries(parentOptions).filter(
    ([, { items }]) => items.length > 1
  )

  const isLoading = isUpdatingStepPosition || isUpdatingMilestonePosition

  return (
    <Dialog
      open
      fullWidth
      onClose={onCloseModal}
      disableBackdropClick
      data-test-id="npi--move-item--dialog"
    >
      <GembahDialogTitle onClose={onCloseModal}>
        Move {itemOptions.type.toLocaleLowerCase()}
      </GembahDialogTitle>
      <DialogContent className={classes.dialogContent}>
        {isMilestone ? (
          <PopSelectField
            data-test-id="npi--move-item--stage"
            ref={register}
            label="Stage"
            name="stage"
            onChange={handleFindFirstAvailablePosition}
            defaultValue={
              filteredParentOptions.find(
                ([stageId, { disabled }]) => stageId === currentItem.stageId && !disabled
              )?.[0]
            }
          >
            {filteredParentOptions.map(([stageId, { stageName, disabled }]) => (
              <option key={stageId} value={stageId} disabled={disabled}>
                {stageName}
              </option>
            ))}
          </PopSelectField>
        ) : (
          <PopSelectField
            data-test-id="npi--move-item--milestone"
            ref={register}
            label="Milestone"
            name="milestone"
            onChange={handleFindFirstAvailablePosition}
            defaultValue={
              filteredParentOptions.find(
                ([milestoneId, { disabled }]) =>
                  milestoneId === currentItem.milestoneId && !disabled
              )?.[0]
            }
          >
            {filteredParentOptions.map(([milestoneId, { milestoneName, disabled }]) => (
              <option key={milestoneId} value={milestoneId} disabled={disabled}>
                {milestoneName}
              </option>
            ))}
          </PopSelectField>
        )}
        <PopSelectField
          data-test-id="npi--move-item--position"
          ref={register}
          label={`${itemOptions.type[0]}${itemOptions.type.slice(1).toLocaleLowerCase()} Position`}
          name="position"
        >
          {positionOptions.map((option) => (
            <option key={option.key} value={option.key} disabled={option.disabled}>
              {option.value}
            </option>
          ))}
        </PopSelectField>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button
          onClick={onCloseModal}
          data-tracking-info={serializeData({
            id: `npi_move-item-modal-cancel-button_click`,
          })}
        >
          Cancel
        </Button>
        <PopButton
          size="small"
          onClick={handleSubmit(handleSave)}
          loading={isLoading}
          disabled={isLoading}
          data-tracking-info={serializeData({
            id: `npi_move-item-modal-save-button_click`,
          })}
        >
          Save
        </PopButton>
      </DialogActions>
    </Dialog>
  )
}

NpiMoveModal.propTypes = {
  onCloseModal: PropTypes.func,
  npiSteps: PropTypes.array,
  itemOptions: PropTypes.shape({
    item: PropTypes.object,
    items: PropTypes.array,
    type: PropTypes.string,
  }),
}

export default NpiMoveModal
