import {
  DELIVERABLE_STATUSES,
  DELIVERABLE_TYPES,
  MANAGEMENT_ROLES_SHORTHANDS,
} from '@components_pop/ProjectDetailsNPI/constants'

export const extractTasksForClient = (tasks) => {
  return tasks.filter((task) => task.deliverable?.isForClient)
}

export const extractTasksForInternal = (tasks) => {
  return tasks.filter((task) => !task.deliverable?.isForClient)
}

export const isUserRoleManagement = (rolesShorthands) => {
  return rolesShorthands.some((roleShorthand) => {
    return MANAGEMENT_ROLES_SHORTHANDS.includes(roleShorthand)
  })
}

export const extractAssignedRolesShortHands = (user, roles) => {
  return roles
    .filter((role) => role?.user?.id === user.id)
    .map((roleWithUser) => roleWithUser?.role?.shorthand)
}

export const hasNpiRole = (roleToCheck, userRoles) => {
  return userRoles.includes(roleToCheck)
}

export const hasNpiRoles = (rolesToCheck, userRoles) => {
  return rolesToCheck.every((role) => userRoles.includes(role))
}

export const modifyNpiStepsWithChangedOne = (newNpiStep, stepCache) => {
  const clonedNpiSteps = [...stepCache()]
  const toChangeNpiStepIndex = clonedNpiSteps.findIndex((npiStep) => npiStep.id === newNpiStep.id)

  clonedNpiSteps.splice(toChangeNpiStepIndex, 1, newNpiStep)

  stepCache([...clonedNpiSteps])
}

export const modifyNpiStepsBySubmission = (newSubmission, stepCache) => {
  const stepId = newSubmission.deliverable.step.id
  const clonedTask = { ...(stepCache().find((step) => step.id === stepId) ?? {}) }
  const taskSubmissions = [...(clonedTask?.deliverable?.submissions ?? [])]
  const newSubmissionIndex = taskSubmissions.findIndex(
    (submission) => submission.id === newSubmission.id
  )

  if (newSubmissionIndex !== -1) {
    taskSubmissions.splice(newSubmissionIndex, 1, newSubmission)
  } else {
    taskSubmissions.push(newSubmission)
  }

  modifyNpiStepsWithChangedOne(
    {
      ...clonedTask,
      deliverable: {
        ...clonedTask.deliverable,
        submissions: [...taskSubmissions],
      },
    },
    stepCache
  )
}

export const isSubmissionStatusDelivered = (status) => {
  return [
    DELIVERABLE_STATUSES.PENDING_REVIEW,
    DELIVERABLE_STATUSES.SUBMITTED,
    DELIVERABLE_STATUSES.REJECTED,
    DELIVERABLE_STATUSES.ACCEPTED,
  ].includes(status)
}

export const isSubmissionReviewed = (status) => {
  return [DELIVERABLE_STATUSES.REJECTED, DELIVERABLE_STATUSES.ACCEPTED].includes(status)
}

export const isSubmissionDeleted = (status) => {
  return status === DELIVERABLE_STATUSES.REMOVED
}

export const isUploadDeliverableType = (type) => {
  return type === DELIVERABLE_TYPES.FILE_UPLOAD
}

// gets the color at a specified percentage of a gradient between color1 and color2
// inspiration from https://jsfiddle.net/dj0xmazk/13/
export const getGradientColorAtPercentage = (color1, color2, percentage) => {
  const w = percentage * 2 - 1
  const w1 = (w / 1 + 1) / 2
  const w2 = 1 - w1
  const rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2),
  ]
  return rgb
}

// inpiration from https://convertingcolors.com/blog/article/convert_hex_to_rgb_with_javascript.html
export const hex2RGB = (hex) => {
  if (!hex) return [0, 0, 0]

  if (hex.length !== 7) {
    throw new Error('Only six-digit hex colors are allowed.')
  }

  const rgbHexArr = hex.slice(1).match(/.{1,2}/g)
  const rgbArr = [
    parseInt(rgbHexArr[0], 16),
    parseInt(rgbHexArr[1], 16),
    parseInt(rgbHexArr[2], 16),
  ]
  return rgbArr
}
