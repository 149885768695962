import { useReactiveVar } from '@apollo/client'
import PropTypes from 'prop-types'
import React from 'react'

import Divider from '@material-ui/core/Divider'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import DownloadIcon from '@public/svg/icons/download-file.svg'
import ImageIcon from '@public/svg/icons/image-icon.svg'
import LinkIcon from '@public/svg/icons/link.svg'
import ShareIcon from '@public/svg/icons/share-icon.svg'
import TrashIcon from '@public/svg/icons/trash.svg'

import { currentAccountUser, currentProject, currentUser } from '@lib/apollo/apolloCache'
import { mediaHasAcceptedMimetype } from '@lib/dataUtils'
import { serializeData } from '@lib/tracking'
import { hasPermission } from '@lib/userAuth'

import SvgLoader from '@components_pop/SvgLoader'

const useStyles = makeStyles((theme) => ({
  iconRoot: {
    minWidth: 20,
    '& > div': {
      width: 15,
      height: 15,
    },
  },
  menuBtn: {
    paddingTop: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menu: {
    maxWidth: 170,
  },
  menuItem: {
    fontSize: '0.75rem',
    justifyContent: 'left',
  },
  customDivider: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
}))

const ProjectAttachmentMenu = ({
  anchorEl,
  isGoogleDrive,
  onCopyUrlToClipboad,
  isDownloading,
  onClose,
  onHandleDownload,
  onDeleteClick,
  attachment,
  shareWith,
  onShareAttachmentToChannel,
  onSetProjectCoverImage,
}) => {
  const classes = useStyles()
  const user = useReactiveVar(currentUser)
  const accountUser = useReactiveVar(currentAccountUser)
  const { isUserOnProjectTeam, coverImage } = useReactiveVar(currentProject)
  const isMe = attachment?.media?.uploadUser ? attachment.media.uploadUser.id === user.id : false
  const hasShares = Object.values(shareWith).some((item) => !!item.isSharable)

  const isSameAsCoverPhoto = coverImage?.id === attachment?.media?.id
  const isRightMimeType = mediaHasAcceptedMimetype(attachment?.media?.mimetype)

  const canManageImage = Boolean(isUserOnProjectTeam || hasPermission.GEMBAH(accountUser))
  if (!attachment.id) return null

  return (
    <>
      <Menu
        classes={{ paper: classes.menu }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted
        onClose={onClose}
      >
        {!isGoogleDrive && (
          <MenuItem
            className={classes.menuItem}
            onClick={onHandleDownload}
            data-tracking-info={serializeData({
              id: `project_attachments-attachment-menu-option_click`,
              attachmentId: attachment.id,
              option: 'download',
            })}
          >
            <ListItemIcon classes={{ root: classes.iconRoot }}>
              <SvgLoader {...DownloadIcon} isLoading={isDownloading} />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Download
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          className={classes.menuItem}
          onClick={onCopyUrlToClipboad}
          data-tracking-info={serializeData({
            id: `project_attachments-attachment-menu-option_click`,
            attachmentId: attachment.id,
            option: 'copy_link',
          })}
        >
          <ListItemIcon classes={{ root: classes.iconRoot }}>
            <SvgLoader {...LinkIcon} />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Copy Link
          </Typography>
        </MenuItem>
        {!isSameAsCoverPhoto && (
          <div>
            {(canManageImage || isMe) && (
              <MenuItem
                className={classes.menuItem}
                onClick={onDeleteClick}
                data-tracking-info={serializeData({
                  id: `project_attachments-attachment-menu-option_click`,
                  attachmentId: attachment.id,
                  option: 'delete',
                })}
              >
                <ListItemIcon classes={{ root: classes.iconRoot }}>
                  <SvgLoader {...TrashIcon} />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                  Delete {isGoogleDrive ? 'Link' : ''}
                </Typography>
              </MenuItem>
            )}
            {isRightMimeType && canManageImage && (
              <>
                <Divider classes={{ root: classes.customDivider }} />
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => onSetProjectCoverImage(attachment.media.id)}
                  data-tracking-info={serializeData({
                    id: `project_attachments-attachment-menu-option_click`,
                    attachmentId: attachment.id,
                    option: 'set cover photo',
                  })}
                >
                  <ListItemIcon classes={{ root: classes.iconRoot }}>
                    <SvgLoader {...ImageIcon} />
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    Set Cover Photo
                  </Typography>
                </MenuItem>
              </>
            )}
            {hasShares && (
              <>
                <Divider classes={{ root: classes.customDivider }} />
                {Object.values(shareWith).map(
                  ({ isSharable, id, color, with: group }) =>
                    isSharable && (
                      <MenuItem
                        key={id}
                        className={classes.menuItem}
                        onClick={() => onShareAttachmentToChannel(attachment.id, id)}
                        data-tracking-info={serializeData({
                          id: `project_attachments-attachment-menu-option_click`,
                          attachmentId: attachment.id,
                          option: `share with ${group.toLowerCase()}`,
                        })}
                      >
                        <ListItemIcon classes={{ root: classes.iconRoot }}>
                          <SvgLoader {...ShareIcon} />
                        </ListItemIcon>
                        <Typography variant="inherit" style={{ color }} noWrap>
                          Share with {group}
                        </Typography>
                      </MenuItem>
                    )
                )}
              </>
            )}
          </div>
        )}
      </Menu>
    </>
  )
}

ProjectAttachmentMenu.propTypes = {
  shareWith: PropTypes.object,
  isDownloading: PropTypes.bool,
  isGoogleDrive: PropTypes.bool,
  onCopyUrlToClipboad: PropTypes.func,
  anchorEl: PropTypes.shape({}),
  onClose: PropTypes.func,
  onHandleDownload: PropTypes.func,
  onDeleteClick: PropTypes.func,
  attachment: PropTypes.object,
  onShareAttachmentToChannel: PropTypes.func,
  onSetProjectCoverImage: PropTypes.func,
}

export default ProjectAttachmentMenu
