import { useState } from 'react'

const useMentionSearch = ({ onClose }) => {
  const [isSearchOpen, setSearchOpen] = useState(false)

  const openSearch = () => setSearchOpen(true)
  const closeSearch = () => {
    setSearchOpen(false)
    onClose()
  }

  return {
    isSearchOpen,
    openSearch,
    closeSearch,
  }
}

export default useMentionSearch
