import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import { makeStyles } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'

import ArrowIcon from '@public/svg/icons/arrow-alternate-icon.svg'
import CalendarIcon from '@public/svg/icons/calendar.svg'

import SvgLoader from '@components_pop/SvgLoader'

const useStyles = makeStyles((theme) => ({
  ...theme.formInputStyles,
  arrowButton: {
    '&:hover': {
      background: 'none',
    },
  },
  arrowIcon: {
    width: 12,
    height: 33,
  },
  rightArrowIcon: {
    transform: 'rotate(180deg)',
  },
}))

const PopDatePicker = React.forwardRef((props, ref) => {
  const {
    error,
    label,
    id,
    helperText,
    FormLabelProps,
    name,
    fullWidth,
    required,
    ...remainingProps
  } = props
  const classes = useStyles()

  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <FormLabel className={classes.label} htmlFor={id} error={error} {...FormLabelProps}>
          {label}
          {!!required && <span>*</span>}
        </FormLabel>
      )}
      <DatePicker
        InputProps={{
          ...remainingProps.inputProps,
          classes: { root: clsx(classes.root, classes.input) },
          endAdornment: (
            <div className={clsx(classes.iconWrapper, classes.endIconWrapper)}>
              <SvgLoader {...CalendarIcon} />
            </div>
          ),
          name,
          ref,
        }}
        leftArrowIcon={<SvgLoader {...ArrowIcon} className={classes.arrowIcon} />}
        rightArrowButtonProps={{
          disableRipple: true,
          classes: {
            root: classes.arrowButton,
          },
        }}
        rightArrowIcon={
          <SvgLoader {...ArrowIcon} className={clsx(classes.arrowIcon, classes.rightArrowIcon)} />
        }
        leftArrowButtonProps={{
          disableRipple: true,
          classes: {
            root: classes.arrowButton,
          },
        }}
        id={id}
        error={error}
        helperText=""
        views={['year', 'month', 'date']}
        {...remainingProps}
      />
      {helperText && (
        <FormHelperText className={classes.helperText} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
})

PopDatePicker.defaultProps = {
  label: '',
  error: false,
  FormLabelProps: {},
  format: 'DD/MM/YYYY',
  orientation: 'landscape',
  inputVariant: 'outlined',
  variant: 'inline',
}

PopDatePicker.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  FormLabelProps: PropTypes.object,
  label: PropTypes.string,
  format: PropTypes.string,
  orientation: PropTypes.string,
  inputVariant: PropTypes.string,
  variant: PropTypes.string,
  // Essentially most props that MUI's TextField supports
}

export default PopDatePicker
