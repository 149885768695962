import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { Tab } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { BOX_BORDER_COLOR, COMMON_WHITE } from '@lib/colors'
import { MOBILE_APPBAR_HEIGHT, MOBILE_BREAKPOINT } from '@lib/theme'
import { serializeData } from '@lib/tracking'

import ChatChannelNotificationBadge from '@components_pop/chat/ChatChannelNotificationBadge'
import ChatChannelWindow from '@components_pop/chat/ChatChannelWindow'
import ChatWindowEditor from '@components_pop/chat/ChatWindowEditor'

import useBreakpoint from '@hooks/useBreakpoint'

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 20,
    height: 20,
  },
  cardRoot: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
    height: '65vh',
    marginBottom: 40,
  },
  dialogCardRoot: {
    width: '100%',
  },
  mobileCardRoot: {
    position: 'fixed',
    zIndex: 1,
    paddingTop: MOBILE_APPBAR_HEIGHT,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: '100%',
  },
  cardHeaderRootSimple: {
    padding: theme.spacing(),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#98D0F0',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.tertiary.main,
      '& h2, & button': {
        color: theme.palette.common.white,
      },
    },
  },
  cardHeaderRoot: {
    padding: 0,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
  },
  cardTitleGridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    '& h2': {
      margin: 0,
    },
  },
  cardHeaderAction: {
    alignSelf: 'center',
    margin: 0,
  },
  cardContentRoot: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'auto',
    padding: 0,
    width: 'inherit',
    position: 'relative',
    borderBottom: `1px solid ${BOX_BORDER_COLOR}`,
  },
  dialogCardContentRoot: {
    minWidth: 'unset',
  },
  mobileCardContentRoot: {
    minWidth: 'unset',
  },
  cardActionsRoot: {
    display: 'block',
    position: 'relative',
    backgroundColor: COMMON_WHITE,
  },
  dialogPaper: {
    maxWidth: 'none',
    maxHeight: 'none',
    height: '85vh',
    width: '85vw',
    overflow: 'visible',
  },
  expandContractIcon: {
    width: 15,
    height: 24,
  },
  slideContainer: {
    height: '100%',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  chatWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minHeight: 1, // this fixes several issues with flex/flex-grow and with scrolling
  },
  tabName: {
    display: 'flex',
    textAlign: 'left',
    flexDirection: 'column',
    textTransform: 'uppercase',
    '& > span:first-child': {
      fontSize: 14,
      fontWeight: 400,
      [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
        marginBottom: -theme.spacing(1),
        fontSize: 19,
      },
    },
    '& > span:last-child': {
      fontSize: 8,
      [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
        fontSize: 10,
      },
    },
  },
  tabsRoot: {
    overflow: 'visible',
    '& .MuiTabs-scroller': {
      overflow: 'visible!important', // only to make the tab go outside it s limits
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
  },
  tabRoot: {
    margin: 0,
    minWidth: 50,
    '& > span:first-child': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  tabSelected: {
    marginTop: -theme.spacing(1),
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    '& $tabName > span:first-child': {
      marginBottom: -theme.spacing(1),
    },
  },
  tabWrapper: {
    alignItems: 'start',
  },
  tabContentWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const TabDetails = ({ tab }) => {
  return (
    <>
      <span style={tab.style.title}>{tab.name}</span>
      <span style={tab.style.subtitle}>{tab.subName}</span>
    </>
  )
}

TabDetails.propTypes = {
  tab: PropTypes.shape({
    style: PropTypes.shape({
      title: PropTypes.object,
      subtitle: PropTypes.object,
    }),
    name: PropTypes.string,
    subName: PropTypes.string,
  }),
}

const ChatTabs = ({ tabIndex, onChatTabChange, chatChannels }) => {
  const classes = useStyles()

  return (
    <Tabs
      classes={{ root: classes.tabsRoot }}
      value={tabIndex}
      onChange={onChatTabChange}
      data-test-id="project-details-chat-tabs"
    >
      {chatChannels.map(
        (tab) =>
          !tab.hide && (
            <Tab
              key={tab.channelId}
              disabled={!tab.visible}
              data-test-id={`project-details-chat-tab-${tab.key}`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              label={
                <div className={classes.tabContentWrapper}>
                  <div className={classes.tabName}>
                    <TabDetails tab={tab} />
                  </div>
                  <ChatChannelNotificationBadge ids={[tab.channelId]} />
                </div>
              }
              style={tab.style.main || {}}
              data-tracking-info={serializeData({
                id: 'project_chat-tab_click',
                tabChannelId: tab.channelId,
                tab: tab.name,
              })}
            />
          )
      )}
    </Tabs>
  )
}

ChatTabs.propTypes = {
  onChatTabChange: PropTypes.func.isRequired,
  chatChannels: PropTypes.array.isRequired,
  tabIndex: PropTypes.number,
}
const ChatBody = ({ chatChannels, tabIndex }) => {
  const classes = useStyles()
  const channel = chatChannels[tabIndex]

  const cardContentRootClass = clsx(classes.cardContentRoot, {
    [classes.dialogCardContentRoot]: true,
  })

  return (
    <div style={channel?.style?.body ?? {}} className={classes.chatWrapper}>
      <CardContent classes={{ root: cardContentRootClass }}>
        <ChatChannelWindow channelId={channel.channelId} />
      </CardContent>
      {channel.visible && (
        <CardActions
          data-test-id={`project-details-actions-${channel.key}`}
          classes={{ root: classes.cardActionsRoot }}
          disableSpacing
        >
          <ChatWindowEditor channelId={channel.channelId} isInternal={channel.isInternal} />
        </CardActions>
      )}
    </div>
  )
}

ChatBody.propTypes = {
  chatChannels: PropTypes.array.isRequired,
  tabIndex: PropTypes.number,
}

const ChatCard = ({ chatChannels, onChatTabChange, projectName, tabIndex }) => {
  const classes = useStyles()

  const cardHeaderClass = clsx({
    [classes.cardHeaderRoot]: chatChannels.length > 1,
    [classes.cardHeaderRootSimple]: chatChannels.length === 1,
  })

  if (tabIndex < 0) {
    return null
  }

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{ root: cardHeaderClass, action: classes.cardHeaderAction }}
        disableTypography
        title={
          chatChannels.length === 1 ? (
            <Grid container>
              <Grid item className={classes.cardTitleGridItem}>
                <Typography data-test-id="project-chat-tabless" variant="h2">
                  Project Chat: {projectName}
                </Typography>
              </Grid>
              <Grid item className={classes.cardTitleGridItem}>
                <ChatChannelNotificationBadge ids={[chatChannels[0].channelId]} />
              </Grid>
            </Grid>
          ) : (
            <ChatTabs
              tabIndex={tabIndex}
              onChatTabChange={onChatTabChange}
              chatChannels={chatChannels}
            />
          )
        }
      />
      <ChatBody chatChannels={chatChannels} tabIndex={tabIndex} />
    </Card>
  )
}

ChatCard.propTypes = {
  projectName: PropTypes.string.isRequired,
  chatChannels: PropTypes.array.isRequired,
  tabIndex: PropTypes.number,
  onChatTabChange: PropTypes.func,
}

const ChatPopper = ({
  chatTab: tabIndex,
  onChatTabChange,
  projectName,
  chatChannels,
  onClose,
  onFullScreenToggle,
}) => {
  const { isDesktop, isMobile } = useBreakpoint()

  if (tabIndex < 0) {
    return null
  }

  return (
    <ChatCard
      isDesktop={isDesktop}
      isMobile={isMobile}
      tabIndex={tabIndex}
      onChatTabChange={onChatTabChange}
      onFullScreenToggle={onFullScreenToggle}
      onClose={onClose}
      chatChannels={chatChannels}
      projectName={projectName}
    />
  )
}

ChatPopper.propTypes = {
  projectName: PropTypes.string.isRequired,
  chatChannels: PropTypes.array.isRequired,
  onFullScreenToggle: PropTypes.func,
  onClose: PropTypes.func,
  chatTab: PropTypes.number,
  onChatTabChange: PropTypes.func,
}

export default ChatPopper
