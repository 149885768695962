import { faCheck, faStop } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'

import { LinearProgress, Snackbar } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

import { BRAND_GREEN, COMMON_WHITE, PRIMARY_RED, TEAL_ROBOTS, THIRD_GREY } from '@lib/colors'

const useStyles = makeStyles((theme) => ({
  snackBarRoot: {
    minWidth: 300,
    padding: `${theme.spacing(1.2)}px ${theme.spacing(1)}px`,
    background: COMMON_WHITE,
    border: `1px solid ${PRIMARY_RED}`,
    fontSize: '1rem',
    fontWeight: 'bold',
    borderRadius: 3,
    boxShadow: '3px 4px 4px rgba(0, 0, 0, 0.1)',
    '& > div': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  iconBtn: {
    padding: 0,
  },
  iconBtnDisabled: {
    color: THIRD_GREY,
  },
  loaderRoot: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    height: '100%',
    backgroundColor: 'transparent',
  },
  loaderBar: ({ isDownloadDone }) => ({
    ...(isDownloadDone ? { backgroundColor: BRAND_GREEN } : { backgroundColor: TEAL_ROBOTS }),
    opacity: 0.4,
  }),
}))

const BatchDownloadProgress = ({
  position = 'bottom',
  filesDownloaded,
  fileCount,
  onStopDownload,
}) => {
  const [hasClickedStop, setHasClickedStop] = useState(false)
  const progress = useMemo(
    () => Math.floor((100 * filesDownloaded) / fileCount),
    [filesDownloaded, fileCount]
  )
  const isDownloadDone = filesDownloaded === fileCount && !hasClickedStop
  const classes = useStyles({ isDownloadDone })

  useEffect(() => {
    if (fileCount > 0 && hasClickedStop) setHasClickedStop(false)
  }, [isDownloadDone, fileCount, setHasClickedStop])

  return (
    <Snackbar
      anchorOrigin={{ vertical: position, horizontal: 'center' }}
      classes={{
        root: classes.snackBarRoot,
      }}
      open={!!fileCount}
    >
      <div>
        <LinearProgress
          classes={{
            root: classes.loaderRoot,
            bar: classes.loaderBar,
          }}
          variant="determinate"
          value={progress}
        />
        {isDownloadDone ? (
          <>
            Please wait while we zip your files
            <FontAwesomeIcon icon={faCheck} color="green" />
          </>
        ) : (
          <>
            Downloading {filesDownloaded} of {fileCount} files
            <IconButton
              disabled={hasClickedStop}
              classes={{
                root: classes.iconBtn,
                disabled: classes.iconBtnDisabled,
              }}
              onClick={() => {
                setHasClickedStop(true)
                onStopDownload()
              }}
            >
              <FontAwesomeIcon icon={faStop} color={PRIMARY_RED} />
            </IconButton>
          </>
        )}
      </div>
    </Snackbar>
  )
}

BatchDownloadProgress.propTypes = {
  position: PropTypes.string,
  filesDownloaded: PropTypes.number,
  fileCount: PropTypes.number,
  onStopDownload: PropTypes.func,
}

export default BatchDownloadProgress
