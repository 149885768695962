import { useReactiveVar } from '@apollo/client'
import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import ChatBubble from '@public/svg/icons/chat-bubble.svg'

import { currentAccountUser, currentProject } from '@lib/apollo/apolloCache'
import { PRIMARY_GREY } from '@lib/colors'
import { hasPermission } from '@lib/userAuth'

import SvgLoader from '@components_pop/SvgLoader'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  icon: {
    width: 160,
    height: 130,
  },
  chatPrompt: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    color: PRIMARY_GREY,
  },
}))

const EmptyState = ({ channelId }) => {
  const classes = useStyles()
  const accountUser = useReactiveVar(currentAccountUser)
  const project = useReactiveVar(currentProject)
  const isExternalChat = project?.externalChatChannel?.id === channelId

  let startChatPrompt

  if (isExternalChat) {
    startChatPrompt = (
      <div className={classes.chatPrompt}>
        <div>Hello!</div>
        <div>How can we help you?</div>
      </div>
    )

    if (hasPermission.GEMBAH_OR_EXPERT(accountUser)) {
      startChatPrompt = (
        <div className={classes.chatPrompt}>
          <div>It&apos;s quiet here.</div>
          <div>
            {project.isUserOnProjectTeam
              ? 'Update the client on your progress!'
              : 'There are no messages yet'}
          </div>
        </div>
      )
    }
  } else {
    startChatPrompt = (
      <div className={classes.chatPrompt}>
        <div>Hey there!</div>
        <div>
          {project.isUserOnProjectTeam
            ? 'Write something to get things started'
            : 'There are no messages yet'}
        </div>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <SvgLoader {...ChatBubble} className={classes.icon} />
      {startChatPrompt}
    </div>
  )
}

EmptyState.propTypes = {
  channelId: PropTypes.string,
}

export default EmptyState
