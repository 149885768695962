import { useReactiveVar } from '@apollo/client'
import PropTypes from 'prop-types'
import React from 'react'

import { Tooltip } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import EyeClosedIcon from '@public/svg/icons/eye-closed.svg'
import EyeIcon from '@public/svg/icons/eye.svg'
import EditIcon from '@public/svg/icons/pencil-edit.svg'
import MoveIcon from '@public/svg/icons/up-down-icon.svg'

import { currentAccountUser, currentMilestone } from '@lib/apollo/apolloCache'
import tracking from '@lib/tracking'
import { hasPermission } from '@lib/userAuth'

import {
  NPI_MENU_ACTION_TYPES,
  NPI_MENU_TYPE_ITEMS,
} from '@components_pop/ProjectDetailsNPI/constants'
import SvgLoader from '@components_pop/SvgLoader'

const useStyles = makeStyles((theme) => ({
  iconRoot: {
    minWidth: 20,
    '& > div': {
      width: 15,
      height: 15,
    },
  },
  menuBtn: {
    paddingTop: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menu: {
    maxWidth: 170,
  },
  menuItem: {
    fontSize: '0.75rem',
    justifyContent: 'left',
  },
  customDivider: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
}))

const NpiMenu = ({ menuOptions, onClose, onToggleHide, onMoveOrEdit }) => {
  const classes = useStyles()
  const currentStep = useReactiveVar(currentMilestone)
  const accountUser = useReactiveVar(currentAccountUser)

  const currentMilestoneIndex = currentStep?.milestonePosition ?? 0
  const isGembahSuper = hasPermission.GEMBAH_SUPER(accountUser)

  const currentItem = menuOptions.item
  const isMilestone = menuOptions.type === NPI_MENU_TYPE_ITEMS.MILESTONE
  const id = isMilestone ? currentItem.id : currentItem.npiStepId
  const isActiveMilestone = currentItem.milestoneIndex - 1 === currentMilestoneIndex

  const shouldDisableShowForMilestone =
    isMilestone && currentMilestoneIndex > currentItem?.milestoneIndex - 1

  const renderShowOption = () => (
    <MenuItem
      disabled={shouldDisableShowForMilestone || (isActiveMilestone && !isGembahSuper)}
      className={classes.menuItem}
      data-test-id={`project-details-npi--${id}-toggle-hide`}
      onClick={() => {
        tracking.trackEvent({
          id: `project-npi_${id}-toggle-hide`,
          itemId: currentItem?.id,
          type: menuOptions?.type,
        })

        onToggleHide(id, currentItem.visible, menuOptions?.type)
      }}
    >
      <ListItemIcon classes={{ root: classes.iconRoot }}>
        <SvgLoader {...(currentItem.visible ? EyeClosedIcon : EyeIcon)} />
      </ListItemIcon>
      <Typography variant="inherit" noWrap>
        {currentItem.visible ? 'Hide' : 'Show'}
      </Typography>
    </MenuItem>
  )

  return (
    <Menu
      classes={{ paper: classes.menu }}
      open={!!menuOptions.anchorEl}
      anchorEl={menuOptions.anchorEl}
      keepMounted
      onClose={onClose}
    >
      {(!isMilestone || (isActiveMilestone && isGembahSuper)) && (
        <MenuItem
          className={classes.menuItem}
          data-test-id={`project-details-npi--${id}-edit`}
          onClick={() => {
            tracking.trackEvent({
              id: `project-npi_${id}-edit`,
              itemId: currentItem?.id,
              type: menuOptions?.type,
            })

            onMoveOrEdit({
              action: isMilestone ? NPI_MENU_ACTION_TYPES.EDIT_DATE : NPI_MENU_ACTION_TYPES.EDIT,
              item: currentItem,
              type: menuOptions.type,
            })
          }}
        >
          <ListItemIcon classes={{ root: classes.iconRoot }}>
            <SvgLoader {...EditIcon} />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Edit {isMilestone ? 'Date' : ''}
          </Typography>
        </MenuItem>
      )}
      {(!isActiveMilestone || !isGembahSuper) && (
        <>
          <MenuItem
            className={classes.menuItem}
            data-test-id={`project-details-npi--${id}-move`}
            disabled={isActiveMilestone && !isGembahSuper}
            onClick={() => {
              tracking.trackEvent({
                id: `project-npi_${id}-move`,
                itemId: currentItem?.id,
                type: menuOptions?.type,
              })

              onMoveOrEdit({
                action: NPI_MENU_ACTION_TYPES.MOVE,
                item: currentItem,
                type: menuOptions.type,
              })
            }}
          >
            <ListItemIcon classes={{ root: classes.iconRoot }}>
              <SvgLoader {...MoveIcon} />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Move
            </Typography>
          </MenuItem>
          <Divider classes={{ root: classes.customDivider }} />
          {shouldDisableShowForMilestone ? (
            <Tooltip
              title="Cannot show or hide milestones that are behind the current milestone"
              placement="top-start"
              arrow
            >
              <div>{renderShowOption()}</div>
            </Tooltip>
          ) : (
            renderShowOption()
          )}
        </>
      )}
    </Menu>
  )
}

NpiMenu.propTypes = {
  menuOptions: PropTypes.shape({
    anchorEl: PropTypes.object,
    item: PropTypes.object,
    type: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onToggleHide: PropTypes.func,
  onMoveOrEdit: PropTypes.func,
}

export default NpiMenu
