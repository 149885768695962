import { useMutation } from '@apollo/client'
import moment from 'moment-timezone'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import tracking from '@lib/tracking'

import { EDIT_MESSAGE } from '@graphql/chat/mutators'

import ChatEditor from '@components_pop/chat/ChatEditor'
import ChatMessageSender from '@components_pop/chat/ChatMessage/Sender'
import { clearQueryString } from '@components_pop/chat/utils'

const ChatItemEditorContainer = ({ channelId, message, onClose }) => {
  const router = useRouter()
  const [editMessage] = useMutation(EDIT_MESSAGE)

  useEffect(() => {
    localStorage.removeItem('chatItemMessage')
  }, [])

  const handleSendMessage = ({ messageBody }) => {
    tracking.trackEvent({
      id: 'project_chat-message-edit-save_click',
      messageId: message.id,
    })
    // https://github.com/apollographql/react-apollo/issues/3781
    editMessage({
      variables: {
        messageId: message.id,
        messageBody,
      },
    }).then(
      (/* res */) => {
        localStorage.removeItem('chatItemMessage')
        // By clearing direct links, this prevents the chat from scrolling to the message every time the user edits a message
        clearQueryString(router)
      },
      (/* err */) => {}
    )
    onClose()
  }

  const handleClose = () => {
    localStorage.removeItem('chatItemMessage')
    tracking.trackEvent({
      id: 'project_chat-message-edit-cancel_click',
      messageId: message.id,
    })
    onClose()
  }

  const zone = moment.tz.guess(true)
  const { postedAt } = message
  const messageMoment = moment(postedAt).tz(zone)
  const datetimeFormat = 'MMM Do [at] h:mm A'

  return (
    <>
      <ChatMessageSender message={message} />
      <span>&nbsp;{messageMoment.format(datetimeFormat)}</span>
      <ChatEditor
        channelId={channelId}
        isEdit
        editValue={message.messageBody}
        onEditCancel={handleClose}
        onSendMessage={handleSendMessage}
      />
    </>
  )
}

ChatItemEditorContainer.propTypes = {
  channelId: PropTypes.string.isRequired,
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    messageBody: PropTypes.string.isRequired,
    postedAt: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ChatItemEditorContainer
