import PropTypes from 'prop-types'
import React from 'react'

import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import EditIcon from '@public/svg/icons/pencil-edit.svg'
import TrashIcon from '@public/svg/icons/trash.svg'

import GembahConfirmDialog from '@dialog/GembahConfirmDialog'

import { serializeData } from '@lib/tracking'

import SvgLoader from '@components_pop/SvgLoader'
import ChatMessage from '@components_pop/chat/ChatMessage'

const useStyles = makeStyles((theme) => ({
  iconRoot: {
    minWidth: theme.spacing(5),
    '& > div': {
      width: 20,
      height: 20,
    },
  },
  messageBox: {
    marginTop: theme.spacing(),
    padding: theme.spacing(),
    border: `1px solid ${theme.palette.grayscale.light}`,
    borderRadius: theme.spacing(),
  },
}))

const ChatItemMenu = ({
  anchorEl,
  message,
  onClose,
  onEditClick,
  onTrashClick,
  trashConfirmOpen,
  onTrashCancel,
  onTrashConfirm,
  isEdit,
}) => {
  const classes = useStyles()

  return (
    <>
      {Boolean(anchorEl) && (
        <Menu open anchorEl={anchorEl} keepMounted onClose={onClose}>
          {!message?.mediaProjectChannel && (
            <MenuItem
              onClick={onEditClick}
              disabled={isEdit}
              data-tracking-info={serializeData({
                id: 'project_chat-message-menu-option_click',
                option: 'edit',
                messageId: message.id,
              })}
            >
              <ListItemIcon classes={{ root: classes.iconRoot }}>
                <SvgLoader {...EditIcon} />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Edit
              </Typography>
            </MenuItem>
          )}
          <MenuItem
            onClick={onTrashClick}
            data-tracking-info={serializeData({
              id: 'project_chat-message-menu-option_click',
              option: 'delete',
              messageId: message.id,
            })}
          >
            <ListItemIcon classes={{ root: classes.iconRoot }}>
              <SvgLoader {...TrashIcon} />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Delete
            </Typography>
          </MenuItem>
        </Menu>
      )}

      {trashConfirmOpen && (
        <GembahConfirmDialog
          title="Delete Message"
          description={
            <>
              <div>Are you sure you want to delete this message?</div>
              <div className={classes.messageBox}>
                <ChatMessage showDate message={message} />
              </div>
            </>
          }
          onAccept={onTrashConfirm}
          onClose={onTrashCancel}
          isSuccess={false} // no success message to be shown upon deletion
        />
      )}
    </>
  )
}

ChatItemMenu.propTypes = {
  anchorEl: PropTypes.shape({}),
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    mediaProjectChannel: PropTypes.shape({
      media: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onTrashClick: PropTypes.func.isRequired,
  trashConfirmOpen: PropTypes.bool.isRequired,
  onTrashCancel: PropTypes.func.isRequired,
  onTrashConfirm: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
}

export default ChatItemMenu
