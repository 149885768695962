import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import { makeStyles } from '@material-ui/core'
import { createFilterOptions } from '@material-ui/lab'

import { THIRD_GREY } from '@lib/colors'

import { TOAST_TYPES } from '@components_pop/Toast'
import PopComboField from '@components_pop/forms/PopComboField'
import { AVAILABILITY_CHOICES } from '@components_pop/profile/UserProfileMenu/container'

import useToast from '@hooks/useToast'

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    bottom: 'calc(100% - 38px)',
    width: '300px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    zIndex: '2',
  },
  list: {
    width: '100%',
    maxHeight: '120px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  inputContainer: {
    width: '100%',
    height: '40px',
  },
  row: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    userSelect: 'none',
  },
  mentionUserDisabledStatus: {
    fontSize: '0.75rem',
    marginLeft: 10,
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  popper: {
    width: '300px',
    height: '160px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '& li': {
      border: 'solid transparent',
      borderWidth: '1px 0',
      '& > span': {
        display: 'flex',
        alignItems: 'center',
      },
      '&[data-focus="true"]': {
        border: `solid ${THIRD_GREY}`,
        borderWidth: '1px 0',
      },
    },
  },
  paper: {
    border: '1px solid #eee',
    borderBottom: 'none',
  },
  listbox: {
    maxHeight: '160px',
  },
}))

const isUserOOO = (user) => user.availability === AVAILABILITY_CHOICES.OOO

const HERE_OPTION = { id: 'all-here', fullName: 'All members', filter: 'here' }

const ChatMentionSearch = React.forwardRef(({ team, onSelect, closeSearch }, ref) => {
  const { addToast } = useToast()
  const classes = useStyles()
  const comboRef = useRef()
  // we need to use a state item because on hightlight ref returns the input
  // aka source of the event
  const [highLightedItem, setHighLightedItem] = useState()

  useEffect(() => {
    if (!comboRef.current) return
    comboRef.current.focus()
  }, [])

  const handleHighlightChange = (_, v) => {
    setHighLightedItem(v)
  }

  const selectOption = (e, value) => {
    if (value.id === 'all-here') {
      // There's a small difference between the @here select option and what the BE is expecting
      onSelect({ id: 'all-here', fullName: 'here' })
      return
    }

    if (value.availability === AVAILABILITY_CHOICES.OOO) {
      const coveredByMail = value.coveredByUser?.email ?? value.coveredByEmail

      addToast({
        message: `
          The team member you are trying to contact is currently out of the office.
          Your message will be forwarded to "${coveredByMail}" while they are away.
        `,
        type: TOAST_TYPES.INFO,
        noAutoClose: true,
        customId: 'data-test-ooo-info',
      })
    }

    onSelect(value)
  }

  const keyDownHandler = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault()
      selectOption(e, highLightedItem)
      return true
    }

    if (e.key === 'Escape') {
      closeSearch()
      return true
    }
    return false
  }

  return (
    <div ref={ref} className={classes.root}>
      <PopComboField
        classes={{ listbox: classes.listbox, paper: classes.paper, popper: classes.popper }}
        data-test-id="at-mention-combo"
        getOptionLabel={(option) => option.fullName || ''}
        getOptionSelected={() => false}
        id="at-mention-combo"
        inputRef={comboRef}
        limitTags={4}
        ListboxProps={{ 'data-test-id': 'at-mention-combo-list' }}
        name="at-mention-combo"
        noOptionsText="No users found."
        onChange={selectOption}
        onKeyDown={keyDownHandler}
        onHighlightChange={handleHighlightChange}
        options={[HERE_OPTION].concat(team)}
        placeholder="Type to search..."
        popupIcon={null}
        renderTags={() => null}
        renderOption={(user) => (
          <>
            <span>{user.fullName}</span>
            {isUserOOO(user) && (
              <span className={classes.mentionUserDisabledStatus}>(Out Of the Office)</span>
            )}
          </>
        )}
        filterOptions={createFilterOptions({
          matchFrom: 'start',
          stringify: (option) => option.filter || option.fullName,
        })}
        open
        fullWidth
        clearOnBlur
        autoHighlight
        disableClearable
        disableHelperText
      />
    </div>
  )
})

ChatMentionSearch.propTypes = {
  team: PropTypes.arrayOf(PropTypes.shape({})),
  onSelect: PropTypes.func.isRequired,
  closeSearch: PropTypes.func.isRequired,
  selectedUser: PropTypes.shape({}),
}

export default ChatMentionSearch
