import { useQuery, useReactiveVar } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useMemo } from 'react'

import {
  currentProject,
  currentProjectNpiSteps,
  currentMilestone,
  isEditingJourneyTab,
} from '@lib/apollo/apolloCache'

import { GET_CURRENT_MILESTONE } from '@graphql/npi/queries'

import {
  NPI_STATUS_HIERACHY,
  NPI_STATUS_HIERARCHY_COLORS,
  NPI_STATUS_HIERARCHY_TEXT,
} from '@components_pop/ProjectDetailsNPI/constants'

import NpiTabs from './NpiTabs'

const calculateStatus = (startedAt, completedAt) => {
  if (!startedAt) return NPI_STATUS_HIERACHY.TO_DO

  return completedAt ? NPI_STATUS_HIERACHY.DONE : NPI_STATUS_HIERACHY.IN_PROGRESS
}

const calculateStatusColor = (startedAt, dueDate, currentStatus) => {
  let color = NPI_STATUS_HIERARCHY_COLORS[currentStatus]
  const overdueDate = moment(startedAt).diff(dueDate, 'days')

  if (overdueDate === 1) {
    color = NPI_STATUS_HIERARCHY_COLORS[NPI_STATUS_HIERACHY.LATE]
  } else if (overdueDate > 1) {
    color = NPI_STATUS_HIERARCHY_COLORS[NPI_STATUS_HIERACHY.VERY_LATE]
  }

  return color
}

const checkNpiEntityStatus = ({ startedAt, dueDate, completedAt }) => {
  const currentStatus = calculateStatus(startedAt, completedAt)
  const currentStatusColor = calculateStatusColor(startedAt, dueDate, currentStatus)

  return {
    position: currentStatus,
    text: NPI_STATUS_HIERARCHY_TEXT[currentStatus],
    color: currentStatusColor,
  }
}

export const generateNpiNestedModel = (npiSteps) => {
  return npiSteps.reduce((npiMap, npiStep) => {
    const {
      stage,
      milestone,
      task,
      deliverable,
      dueDate,
      startedAt,
      completedAt,
      assignee,
      position,
      disabled: npiStepDisabled,
      id: npiStepId,
    } = npiStep

    return _.merge(npiMap, {
      [milestone.id]: {
        ...milestone,
        isLastLevelParent: true,
        stageName: stage.name,
        stageId: stage.id,
        milestoneIndex: Object.keys(npiMap).length,
        color: stage.hexColor,
        visible: !milestone.disabled,
        status: checkNpiEntityStatus({
          startedAt: milestone.startedAt,
          dueDate: milestone.dueAt,
          completedAt: milestone.completedAt,
        }),
        children: {
          [npiStepId]: {
            ...task,
            visible: !npiStepDisabled,
            milestoneId: milestone.id,
            stageId: stage.id,
            npiStepId,
            deliverable,
            dueDate,
            startedAt,
            position,
            completedAt,
            assignee,
            status: checkNpiEntityStatus(npiStep),
          },
        },
      },
    })
  }, {})
}

const ProjectDetailsNpiContainer = () => {
  const project = useReactiveVar(currentProject)
  const npiSteps = useReactiveVar(currentProjectNpiSteps)
  const isEditingJourneyProcess = useReactiveVar(isEditingJourneyTab)

  const { data: milestoneData } = useQuery(GET_CURRENT_MILESTONE, {
    variables: { projectId: project.id },
  })

  const formattedNpiSteps = useMemo(() => {
    if (!npiSteps) return {}
    return generateNpiNestedModel(npiSteps)
  }, [npiSteps])

  useEffect(() => {
    const milestoneId = milestoneData?.currentNpiMilestone?.id
    if (!milestoneId || !formattedNpiSteps[milestoneId]) return

    const filteredMilestones = isEditingJourneyProcess
      ? Object.entries(formattedNpiSteps)
      : Object.entries(formattedNpiSteps).filter(([, milestone]) => milestone.visible)

    currentMilestone({
      ...(filteredMilestones.find(
        ([currentMilestoneId]) => currentMilestoneId === milestoneId
      )?.[1] ?? {}),
      milestonePosition: filteredMilestones.findIndex(
        ([currentMilestoneId]) => currentMilestoneId === milestoneId
      ),
    })
  }, [milestoneData?.currentNpiMilestone, formattedNpiSteps, isEditingJourneyProcess])

  if (!npiSteps) {
    return null
  }

  return <NpiTabs formattedNpiSteps={formattedNpiSteps} />
}

export default ProjectDetailsNpiContainer
