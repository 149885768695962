import { useMutation, useReactiveVar } from '@apollo/client'
import { faFilePen } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'

import { Tooltip } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

import AddIcon from '@public/svg/icons/add.svg'
import DragIcon from '@public/svg/icons/double-bars-icon.svg'
import EllipsisIcon from '@public/svg/icons/ellipsis-vertical-icon.svg'
import EyeIcon from '@public/svg/icons/eye.svg'
import LockIcon from '@public/svg/icons/lock-icon.svg'
import MessageIcon from '@public/svg/icons/message.svg'
import PlayIcon from '@public/svg/icons/play-icon.svg'

import {
  currentAccountUser,
  currentMilestone,
  currentProjectNpiSteps,
  editingJourneyTabElementId,
  isEditingJourneyTab,
} from '@lib/apollo/apolloCache'
import { makeEllipsisText } from '@lib/theme'
import { hasPermission } from '@lib/userAuth'

import { START_NPI_STEP } from '@graphql/npi/mutators'

import FileUploadContext from '@components_pop/DropZone/FileUploadContext'
import NpiDeliverableFilesModal from '@components_pop/ProjectDetailsNPI/NpiDeliverableFilesModal'
import {
  isSubmissionReviewed,
  isSubmissionStatusDelivered,
  modifyNpiStepsWithChangedOne,
} from '@components_pop/ProjectDetailsNPI/utils'
import SvgLoader from '@components_pop/SvgLoader'
import GembahTable from '@components_pop/Table'
import { TOAST_TYPES } from '@components_pop/Toast'

import useToast from '@hooks/useToast'

import NpiFilesVersioningExpandedContent from './NpiFilesVersioningExpandedContent'
import {
  DELIVERABLE_STATUSES,
  EMPTY_STATE,
  NPI_MENU_TYPE_ITEMS,
  NPI_STATUS_HIERACHY,
  NPI_VISUAL_DATE_FORMAT,
} from './constants'

const useStyles = makeStyles((theme) => ({
  ...theme.npiTable,
  menuButtonRoot: {
    marginLeft: 15,
    '& > span > div': {
      width: 20,
      height: 20,
    },
  },
  dragButtonRoot: {
    marginLeft: 15,
    '& > span > div': {
      width: 12,
      height: 20,
    },
  },
  documentsTableWrapper: {
    marginBottom: 50,
  },
  tableCellName: {
    fontWeight: 500,
    color: '#47484D',
    ...makeEllipsisText(100),
  },
  deliverableNameContent: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    color: '#47484D',
    '& div': {
      ...makeEllipsisText(250),
    },
  },
  linkButton: {
    marginLeft: theme.spacing(1),
  },
  alignContentRight: {
    textAlign: 'right',
  },
  cell: {
    padding: 0,
  },
  addFilesBtn: {
    margin: 0,
  },
  icon: {
    width: 25,
    height: 25,
  },
}))

const MilestoneDocuments = ({ milestoneDocs, isCompleted, milestone, onMenuOpen, handleDND }) => {
  const classes = useStyles()
  const { addToast } = useToast()

  const fileUploadContext = useContext(FileUploadContext)
  const isEditingJourneyProcess = useReactiveVar(isEditingJourneyTab)
  const currentStep = useReactiveVar(currentMilestone)
  const currentMovedJourneyTabElementId = useReactiveVar(editingJourneyTabElementId)

  const [startNpiStep, { loading: isStartingNpiStep }] = useMutation(START_NPI_STEP)

  const milestoneName = milestone.name
  const milestoneId = milestone.id

  const canEditMilestones =
    (currentMovedJourneyTabElementId === null && currentStep?.id === milestoneId) ||
    (isCompleted && isEditingJourneyProcess)

  const filteredDocs = isEditingJourneyProcess
    ? milestoneDocs
    : milestoneDocs.filter((doc) => doc.visible)

  const headers = [
    {
      name: 'openExpandedContent',
      text: '',
      width: '5%',
    },
    {
      name: 'name',
      text: 'Deliverable',
      width: '20%',
    },
    {
      name: 'version',
      text: 'Version',
      width: '10%',
    },
    {
      name: 'owner',
      text: 'Owner',
      width: '10%',
    },
    {
      name: 'info',
      text: 'Info',
      width: '15%',
    },
    {
      name: 'uploaded',
      text: 'Uploaded',
      width: '10%',
    },
    {
      name: 'delivered',
      text: 'Delivered',
      width: '10%',
    },
    {
      name: 'actions',
      text: '',
    },
  ]

  const handleStartNpiStep = (npiStepId) => {
    startNpiStep({
      variables: {
        stepId: npiStepId,
      },
    }).then((res) => {
      if (!res.errors) {
        addToast({
          message: 'Successfully started Deliverable',
          type: TOAST_TYPES.SUCCESS,
        })
        const newStep = res.data.startNpiStep.npiStep

        modifyNpiStepsWithChangedOne(newStep, currentProjectNpiSteps)
      }
    })
  }

  const items = (task, _unused, { dragProvided }) => {
    const {
      npiStepId,
      deliverable: { submissions, name: deliverableName },
      status: { position: statusPosition },
      templateDocumentUrl,
    } = task

    return {
      row: {
        classNames: { [classes.hiddenNpiElement]: !task.visible },
      },
      cells: [
        {
          content: (
            <div className={classes.deliverableNameContent}>
              <div>{deliverableName}</div>
              {templateDocumentUrl && (
                <Tooltip placement="top" title="Click to view template document" arrow>
                  <IconButton
                    onClick={() => window.open(templateDocumentUrl, '_blank')}
                    size="small"
                    className={classes.linkButton}
                  >
                    <FontAwesomeIcon icon={faFilePen} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          ),
          classNames: { [classes.tableCellName]: true },
        },
        {
          content: null,
        },
        {
          content: task.ownerRole?.name,
        },
        {
          content: <span>({submissions?.length ?? 0} Documents)</span>,
        },
        {
          content: null,
        },
        {
          content: null,
        },
        {
          content: (
            <div className={classes.alignContentRight}>
              {statusPosition === NPI_STATUS_HIERACHY.TO_DO ? (
                <IconButton
                  size="small"
                  disabled={!canEditMilestones}
                  onClick={() => handleStartNpiStep(npiStepId)}
                >
                  <SvgLoader className={classes.icon} {...PlayIcon} isLoading={isStartingNpiStep} />
                </IconButton>
              ) : (
                <IconButton
                  disabled={!canEditMilestones}
                  size="small"
                  onClick={() => {
                    fileUploadContext.onOpenNpiFileUploadModal(task)
                  }}
                >
                  <SvgLoader className={classes.icon} {...AddIcon} />
                </IconButton>
              )}
              {isEditingJourneyProcess && !isCompleted && !task?.startedAt && (
                <>
                  <IconButton
                    disabled={currentMovedJourneyTabElementId !== null}
                    size="medium"
                    data-test-id={`project-details--deliverable-${task?.id}`}
                    classes={{ root: classes.menuButtonRoot }}
                    onClick={onMenuOpen({
                      item: task,
                      type: NPI_MENU_TYPE_ITEMS.DELIVERABLE,
                    })}
                  >
                    <SvgLoader {...EllipsisIcon} />
                  </IconButton>
                  <IconButton
                    disabled={currentMovedJourneyTabElementId !== null}
                    size="medium"
                    classes={{ root: classes.dragButtonRoot }}
                    {...dragProvided.dragHandleProps}
                  >
                    <SvgLoader
                      {...DragIcon}
                      isLoading={currentMovedJourneyTabElementId === task?.npiStepId}
                    />
                  </IconButton>
                </>
              )}
            </div>
          ),
        },
      ],
    }
  }

  return (
    <div className={classes.documentsTableWrapper}>
      <span className={classes.filesTitle}>Milestone Documents</span>
      <GembahTable
        tableClassNames={{
          [classes.documentsTable]: true,
        }}
        cellClassNames={{ [classes.cell]: true }}
        tableName={`${milestoneName}-milestone-documents`}
        headers={headers}
        emptyState={<tr />}
        listItems={filteredDocs}
        listItemsMap={items}
        keyProp="npiStepId"
        dndProps={{
          id: milestoneId,
          onDragAndDrop: (results) => handleDND(results, milestoneDocs),
        }}
        WithExpansionContent={NpiFilesVersioningExpandedContent}
        hasVisibleHeadersOnEmpty
        noScrollOnExtensionOpen
        isExpansionTriggerAtStart
        hasAllRowExpansionTrigger
      />
    </div>
  )
}

MilestoneDocuments.propTypes = {
  milestoneDocs: PropTypes.array,
  milestone: PropTypes.object,
  isCompleted: PropTypes.bool,
  onMenuOpen: PropTypes.func,
  handleDND: PropTypes.func,
}

const DeliveredDocuments = ({ deliveredDocs, milestone }) => {
  const classes = useStyles()
  const [submissionToReview, setSubmissionToReview] = useState(null)
  const user = useReactiveVar(currentAccountUser)
  const isEditingJourneyProcess = useReactiveVar(isEditingJourneyTab)

  const currentStep = useReactiveVar(currentMilestone)
  const milestoneName = milestone.name
  const milestoneId = milestone.id

  const canEditMilestones = currentStep?.id === milestoneId || isEditingJourneyProcess

  const shouldBeAbleToReviewFiles = hasPermission.CREATOR(user)

  const renderCorrectIcon = (submission) => {
    if (!canEditMilestones) return null

    const isApproved = submission?.status === DELIVERABLE_STATUSES.ACCEPTED
    const hasReviews = !!submission?.reviews && isSubmissionReviewed(submission?.status)

    if (shouldBeAbleToReviewFiles && !hasReviews) {
      return (
        <IconButton onClick={() => setSubmissionToReview(submission)} size="small">
          <SvgLoader {...EyeIcon} className={classes.icon} />
        </IconButton>
      )
    }

    if (isApproved) {
      return (
        <IconButton onClick={() => setSubmissionToReview(submission)} size="small">
          <SvgLoader {...LockIcon} className={classes.icon} />
        </IconButton>
      )
    }

    if (hasReviews) {
      return (
        <IconButton onClick={() => setSubmissionToReview(submission)} size="small">
          <SvgLoader {...MessageIcon} className={classes.icon} />
        </IconButton>
      )
    }

    return null
  }

  const headers = [
    {
      name: 'name',
      text: 'Name',
      width: '25%',
    },
    {
      name: 'version',
      text: 'Version',
      width: '10%',
    },
    {
      name: 'owner',
      text: 'Owner',
      width: '10%',
    },
    {
      name: 'info',
      text: 'Info',
      width: '15%',
    },
    {
      name: 'delivered',
      text: 'Delivered',
      width: '10%',
    },
    {
      name: 'changeRequested',
      text: 'Change Requested',
      width: '15%',
    },
    {
      name: 'signed',
      text: 'Signed',
      width: '5%',
    },
    {
      name: 'actions',
      text: '',
    },
  ]

  const items = (submission) => {
    const { gembahVersion, media, reviews, status, deliverable, ownerRole } = submission ?? {}
    const { createdAt: requestChangedAt } = reviews?.[0] ?? []
    const { uploadConfirmedAt } = media?.[0] ?? []
    const isSubmissionApproved = status === DELIVERABLE_STATUSES.ACCEPTED
    const isSubmissionRejected = status === DELIVERABLE_STATUSES.REJECTED

    return {
      cells: [
        {
          content: (
            <span onClick={() => setSubmissionToReview({ ...submission, isReadOnly: true })}>
              {deliverable?.name}
            </span>
          ),
          classNames: { [classes.tableCellName]: true },
        },
        {
          content: gembahVersion,
        },
        {
          content: ownerRole?.name,
        },
        {
          content: EMPTY_STATE,
        },
        {
          content: uploadConfirmedAt
            ? moment(uploadConfirmedAt).format(NPI_VISUAL_DATE_FORMAT)
            : EMPTY_STATE,
        },
        {
          content:
            isSubmissionRejected && requestChangedAt
              ? moment(requestChangedAt).format(NPI_VISUAL_DATE_FORMAT)
              : EMPTY_STATE,
        },
        {
          content:
            isSubmissionApproved && requestChangedAt
              ? moment(requestChangedAt).format(NPI_VISUAL_DATE_FORMAT)
              : EMPTY_STATE,
        },
        {
          classNames: { [classes.alignContentRight]: true },
          content: renderCorrectIcon(submission),
        },
      ],
    }
  }

  return (
    <div className={classes.documentsTableWrapper}>
      <span className={classes.filesTitle}>Delivered Documents</span>
      <GembahTable
        tableClassNames={{
          [classes.documentsTable]: true,
        }}
        cellClassNames={{ [classes.cell]: true }}
        tableName={`${milestoneName}-delivered-documents`}
        headers={headers}
        emptyState={<tr />}
        listItems={_.orderBy(deliveredDocs, 'gembahVersion', 'desc')}
        listItemsMap={items}
        hasVisibleHeadersOnEmpty
        hasAllRowExpansionTrigger
      />
      {submissionToReview && (
        <NpiDeliverableFilesModal
          onCloseModal={() => setSubmissionToReview(null)}
          submission={submissionToReview}
        />
      )}
    </div>
  )
}

DeliveredDocuments.propTypes = {
  deliveredDocs: PropTypes.array,
  milestone: PropTypes.object,
}

const NpiDeliverablesContent = ({ tasks, milestone, onMenuOpen, handleDND }) => {
  const accountUser = useReactiveVar(currentAccountUser)

  const hasPermissionToViewInternalData = hasPermission.GEMBAH_OR_EXPERT(accountUser)

  const deliveredDocuments = tasks.reduce((submissions, currentTask) => {
    const sentSubmission = currentTask.deliverable.submissions?.filter((submission) => {
      const isDelivered = isSubmissionStatusDelivered(submission?.status)
      const isDeleted = submission.deletedAt
      if (!isDelivered || isDeleted) {
        return false
      }

      const isCarryOver =
        submission.deliveredToClientAt && submission.deliveredToClientAt < milestone.startedAt
      return !isCarryOver || hasPermissionToViewInternalData
    })

    const enchancedSentSubmissions = (sentSubmission ?? []).map((submission) => ({
      ...submission,
      ownerRole: currentTask?.ownerRole,
    }))

    return submissions.concat(enchancedSentSubmissions)
  }, [])

  return (
    <>
      <DeliveredDocuments deliveredDocs={deliveredDocuments} milestone={milestone} />
      {hasPermissionToViewInternalData && (
        <MilestoneDocuments
          isCompleted={!!milestone.completedAt}
          onMenuOpen={onMenuOpen}
          milestone={milestone}
          milestoneDocs={tasks}
          handleDND={handleDND}
        />
      )}
    </>
  )
}

NpiDeliverablesContent.propTypes = {
  tasks: PropTypes.array,
  milestone: PropTypes.object,
  onMenuOpen: PropTypes.func,
  handleDND: PropTypes.func,
}

export default NpiDeliverablesContent
