import { useQuery, useReactiveVar } from '@apollo/client'
import _ from 'lodash'

import AttachmentsIcon from '@public/svg/icons/attachments-icon.svg'
import DiamondsIcon from '@public/svg/icons/diamons-stars-icon.svg'
import DriveIcon from '@public/svg/icons/drive-icon.svg'
import ChatIcon from '@public/svg/icons/message.svg'
import MountainIcon from '@public/svg/icons/mountain-icon.svg'
import TeamIcon from '@public/svg/icons/team-icon.svg'

import { currentAccountUser, currentProject, featureFlags } from '@lib/apollo/apolloCache'
import { NOTIFICATION_TYPE } from '@lib/constants/notifications'
import { hasPermission } from '@lib/userAuth'

import { GET_NOTIFICATIONS_LOCAL } from '@graphql/notifications/queries'

import ProjectDetailsAttachments from '@components_pop/ProjectDetailsAttachments'
import ProjectDetailsChat from '@components_pop/ProjectDetailsChat'
import ProjectDetailsNpi from '@components_pop/ProjectDetailsNPI'
import ProjectDetailsTeam from '@components_pop/ProjectDetailsTeam'

export const PROJECT_DETAILS_ROUTES_NAMES = {
  MILESTONES: 'milestones',
  COLLABORATION: 'collaboration',
  ATTACHMENTS: 'attachments',
  TEAM: 'team',
  SPEC: 'spec',
  CHAT: 'chat',
}

export const PROJECT_DETAILS_ROUTES = {
  [PROJECT_DETAILS_ROUTES_NAMES.MILESTONES]: {
    name: 'Milestones',
    title: 'Milestones',
    url: 'milestones',
    component: ProjectDetailsNpi,
    icon: MountainIcon,
  },
  [PROJECT_DETAILS_ROUTES_NAMES.COLLABORATION]: {
    name: 'Collaboration',
    title: 'Collaboration',
    url: () => {
      const currProject = useReactiveVar(currentProject)
      return currProject?.folderLink
    },
    isVisible: () => {
      const currProject = useReactiveVar(currentProject)
      return !!currProject?.folderLink
    },
    external: true,
    icon: DriveIcon,
  },
  [PROJECT_DETAILS_ROUTES_NAMES.ATTACHMENTS]: {
    name: 'Attachments',
    title: 'Attachments',
    url: 'attachments',
    component: ProjectDetailsAttachments,
    icon: AttachmentsIcon,
  },
  [PROJECT_DETAILS_ROUTES_NAMES.TEAM]: {
    name: 'Team',
    title: 'Team',
    url: 'team',
    component: ProjectDetailsTeam,
    icon: TeamIcon,
  },
  [PROJECT_DETAILS_ROUTES_NAMES.SPEC]: {
    name: 'Spec',
    title: 'Spec',
    isVisible: () => {
      const currProject = useReactiveVar(currentProject)
      const accountUser = useReactiveVar(currentAccountUser)
      const { canAccessProjectProductAdmin } = featureFlags()

      const canAccessProdSpecPage =
        canAccessProjectProductAdmin || hasPermission.GEMBAH_SUPER(accountUser)

      return canAccessProdSpecPage && currProject?.product?.specification
    },
    url: () => {
      const currProject = useReactiveVar(currentProject)
      return `https://${process.env.API_HOST}/admin/products/productspecification/?goto=${currProject?.product?.specification?.id}`
    },
    external: true,
    icon: DiamondsIcon,
  },
  [PROJECT_DETAILS_ROUTES_NAMES.CHAT]: {
    name: 'Chat',
    title: 'Chat',
    url: 'chat',
    component: ProjectDetailsChat,
    icon: ChatIcon,
    hasIconNotification: () => {
      const project = useReactiveVar(currentProject)
      const ids = [
        project?.internalChatChannel?.id,
        project?.designChatChannel?.id,
        project?.externalChatChannel?.id,
      ]

      const {
        data: {
          notificationsWrapper: { notifications },
        },
      } = useQuery(GET_NOTIFICATIONS_LOCAL)

      const chatChannelNotificationCount = _.filter(notifications, (n) => {
        try {
          return (
            ids.includes(n.channelId) &&
            [NOTIFICATION_TYPE.AT_MENTION, NOTIFICATION_TYPE.CHAT_MESSAGE].includes(
              n.notificationType
            )
          )
        } catch (err) {
          return false
        }
      })

      return !!chatChannelNotificationCount.length
    },
    hasWhiteBorders: true,
  },
}
