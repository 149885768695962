import { useReactiveVar } from '@apollo/client'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React from 'react'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import EllipsisIcon from '@public/svg/icons/ellipsis-icon.svg'
import EllipsisVerticalIcon from '@public/svg/icons/ellipsis-vertical-icon.svg'

import { currentProject, currentUser } from '@lib/apollo/apolloCache'
import {
  BOX_BORDER_COLOR,
  COMMON_BLACK,
  COMMON_WHITE,
  FIFTH_GREY,
  FIFTH_GREY_BACKGROUND,
  FOURTH_GREY_BACKGROUND,
  ICON_GREY_TERTIARY,
  PRIMARY_GREY,
  SECONDARY_BLUE_BACKGROUND,
  SECONDARY_GREY,
} from '@lib/colors'
import { MIME_TYPES_FOR_ICONS } from '@lib/constants'
import { normalizeStringCharsForUrl } from '@lib/dataUtils'
import { makeEllipsisText, MOBILE_BREAKPOINT } from '@lib/theme'
import { serializeData } from '@lib/tracking'

import ButtonSquareMenu from '@components_pop/ButtonSquareMenu/buttonSquareMenu'
import ContentCard from '@components_pop/ContentCard'
import SvgLoader from '@components_pop/SvgLoader'
import MediaIcon from '@components_pop/attachments/MediaIcon'

import useBreakpoint from '@hooks/useBreakpoint'

import Filters from './Filters'

const zone = moment.tz.guess(true)

const useStyles = makeStyles((theme) => ({
  emptyState: {
    ...theme.projectDetails.tabEmptyState,
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  emptyStateWithAccordion: {
    ...theme.projectDetails.tabEmptyState,
    padding: theme.spacing(2),
  },
  uploadActionsWrapper: {
    position: 'fixed',
    bottom: 100,
    right: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& > span': {
      marginTop: theme.spacing(1),
      fontSize: '0.8rem',
      fontStyle: 'italic',
    },
  },
  fabRoot: {
    zIndex: 2,
  },
  attachmentsActions: {
    ...theme.palette.pop,
  },
  attachmentsAction: {
    background: COMMON_BLACK,
    color: COMMON_WHITE,
    '&:hover': {
      background: FIFTH_GREY,
    },
  },
  downloadLoader: {
    color: COMMON_WHITE,
  },
  listItem: {
    paddingLeft: theme.spacing(1),
    cursor: 'pointer',
    padding: `${theme.spacing(1)}px 0`,
    flexWrap: 'wrap',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      flexWrap: 'nowrap',
      height: '4rem',
      '& .desktop-menu-button': {
        color: theme.palette.common.white,
      },
      '&:hover .desktop-menu-button': {
        color: SECONDARY_GREY,
      },
    },
  },
  listItemAvatarRoot: {
    minWidth: 56,
    maxWidth: 56,
    maxHeight: 56,
    marginRight: 10,
    textAlign: 'center',
    fontSize: theme.spacing(4), // FontAwesome icon size
    '& svg': {
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  listItemTextRoot: {
    margin: 0,
    alignItems: 'center',
    display: 'flex',
    wordBreak: 'break-word',
  },
  listItemTextPrimary: {
    lineHeight: 'normal',
  },
  fileTextBlock: {
    display: 'inline-block', // ensures the menu button is to the immediate right of the text block (vs. all the way to the end of the row)
  },
  filename: {
    display: 'block', // so ellipses will work
    fontSize: '1rem',
    fontWeight: 500,
  },
  uploaderInfo: {
    display: 'block', // so ellipses will work
    fontSize: '0.875rem',
    fontWeight: 300,
    color: PRIMARY_GREY,
  },
  uploaderName: {
    marginRight: theme.spacing(),
  },
  uploadDate: {},
  menuButtonRoot: {
    height: 48,
    width: 48,
    marginLeft: theme.spacing(),
    fontSize: '1.2rem',
    '& > span > div': {
      width: 20,
      height: 20,
    },
  },
  attachmentNameAndLabel: {
    display: 'flex',
    alignItems: 'baseline',
    flexDirection: 'column',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      flexDirection: 'row',
    },
    '& > svg': {
      marginLeft: theme.spacing(1),
      width: 10,
      height: 10,
    },
  },
  coverPhotoLabel: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    borderRadius: 10,
    background: FIFTH_GREY_BACKGROUND,
    color: ICON_GREY_TERTIARY,
    fontWeight: 'bold',
    border: `1px dashed ${COMMON_BLACK}`,
    fontSize: '0.5rem',
    textTransform: 'uppercase',
    letterSpacing: 1.2,
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: theme.spacing(1),
    },
  },
  mobileEllipsisText: {
    ...makeEllipsisText('57vw'),
  },
  headerButton: {
    marginLeft: theme.spacing(1),
  },
  uploadActionWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      display: 'none',
      fontWeight: 300,
      fontSize: '0.8rem',
      color: PRIMARY_GREY,
      [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
        display: 'inline-block',
      },
    },
  },
  actions: {
    display: 'flex',
  },
  mediaListRoot: {
    padding: 0,
  },
  mediaItemInfoWrapper: {
    display: 'flex',
    flex: 1,
  },
  // accordions
  accordionRoot: {
    '&$expanded': {
      margin: 0,
      '&:last-child': {
        borderTop: `1px solid ${FOURTH_GREY_BACKGROUND}`,
      },
    },
  },
  accordionSummaryIcon: {
    padding: 4,
    color: COMMON_WHITE,
  },
  accordionDetailsRoot: {
    display: 'block',
    padding: `0 ${theme.spacing(1)}px`,
  },
  accordionSummaryRoot: {
    paddingLeft: 10,
    backgroundColor: ICON_GREY_TERTIARY,
    color: COMMON_WHITE,
    minHeight: 'auto',
    '&$expanded': {
      minHeight: 'auto',
    },
  },
  accordionSummaryContent: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontSize: '0.75rem',
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
  // checkboxes
  checkboxRoot: {
    color: SECONDARY_BLUE_BACKGROUND,
    '&$checked': {
      color: SECONDARY_BLUE_BACKGROUND,
    },
  },
  checkboxLabel: {
    textTransform: 'uppercase',
    fontSize: '0.8rem',
  },
  simpleLabel: {
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    marginRight: theme.spacing(2),
    color: SECONDARY_BLUE_BACKGROUND,
  },
  checked: {},
  attachmentLink: {
    '& [class*="uploaderName"]': {
      color: PRIMARY_GREY,
    },
    '& [class*="filename"]': {
      color: 'inherit',
    },
    '& img': {
      border: `1px solid ${BOX_BORDER_COLOR}`,
    },
  },
}))

const Filename = ({ media }) => {
  const { isMobile } = useBreakpoint()
  const classes = useStyles()
  const rootClass = clsx(classes.filename, {
    [classes.mobileEllipsisText]: Boolean(isMobile),
  })

  return <div className={rootClass}>{media.filename}</div>
}

Filename.propTypes = {
  media: PropTypes.shape({
    filename: PropTypes.string,
  }),
}

const UploaderInfo = ({ mediaChannelType }) => {
  const { isMobile } = useBreakpoint()
  const { media } = mediaChannelType
  const classes = useStyles()
  const rootClass = clsx(classes.uploaderInfo, {
    [classes.mobileEllipsisText]: Boolean(isMobile),
  })
  const date = moment(mediaChannelType.createdAt)
  const isDateValid = date.isValid()

  return (
    <div className={rootClass}>
      <span className={classes.uploaderName}>
        {media.uploadUser ? `${media.uploadUser.firstName} ${media.uploadUser.lastName}` : null}
      </span>
      <span className={classes.uploadDate}>
        {isDateValid ? date.tz(zone).format('DD MMMM YYYY - hh:mm A') : 'No upload date available'}
      </span>
    </div>
  )
}

UploaderInfo.propTypes = {
  mediaChannelType: PropTypes.shape({
    createdAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    media: PropTypes.shape({
      uploadConfirmedAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      mimetype: PropTypes.string,
      uploadUser: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    }),
  }),
}

const AttachmentList = ({
  list,
  coverImageId,
  onAttachmentMenuOpen,
  children: checkboxes,
  animatedMediaId,
  targetAttachment,
  hasResults,
  dataTestId,
}) => {
  const classes = useStyles()
  const { isMobile } = useBreakpoint()

  const orderedMedia = _.orderBy(list, (mediaChannelType) => moment(mediaChannelType.createdAt), [
    'desc',
  ])

  const listItems = orderedMedia.map((item, i) => {
    const isGoogleDrive = item.media.mimetype === MIME_TYPES_FOR_ICONS.GOOGLE_DRIVE

    return (
      <React.Fragment key={item.id}>
        <Slide direction="left" in={animatedMediaId !== item.id}>
          <div style={{ opacity: targetAttachment === item.id ? 0.2 : 1 }}>
            <ListItem className={classes.listItem} data-test-id="project-details-attachments-item">
              <Link
                href={normalizeStringCharsForUrl(isGoogleDrive ? item.media.url : item.url)}
                target="_blank"
                rel="noreferrer"
                className={classes.attachmentLink}
              >
                <div
                  className={classes.mediaItemInfoWrapper}
                  data-tracking-info={serializeData({
                    id: `project_attachments-attachment-row_click`,
                    attachmentId: item.id,
                  })}
                >
                  <ListItemAvatar classes={{ root: classes.listItemAvatarRoot }}>
                    {item.media.thumbnailUrl ? (
                      <img src={item.media.thumbnailUrl} alt={item.media.filename} />
                    ) : (
                      <MediaIcon mimetype={item.media.mimetype} />
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    classes={{
                      root: classes.listItemTextRoot,
                      primary: classes.listItemTextPrimary,
                    }}
                    primary={
                      <div className={classes.fileTextBlock}>
                        <div className={classes.attachmentNameAndLabel}>
                          <Filename media={item.media} />
                          {coverImageId === item.media.id && (
                            <span
                              data-test-id="project_attachments-attachment-cover"
                              className={classes.coverPhotoLabel}
                            >
                              cover photo
                            </span>
                          )}
                          {isGoogleDrive && (
                            <span className={classes.coverPhotoLabel}>Google Drive</span>
                          )}
                        </div>
                        <UploaderInfo mediaChannelType={item} />
                      </div>
                    }
                  />
                  <IconButton
                    className="desktop-menu-button"
                    size="medium"
                    classes={{ root: classes.menuButtonRoot }}
                    aria-label="options"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={onAttachmentMenuOpen(item)}
                    data-test-id="project_attachments-attachment-menu"
                    data-tracking-info={serializeData({
                      id: `project_attachments-attachment-menu_click`,
                      attachmentId: item.id,
                    })}
                  >
                    <SvgLoader {...(isMobile ? EllipsisIcon : EllipsisVerticalIcon)} />
                  </IconButton>
                </div>
              </Link>
              {typeof checkboxes === 'function' ? checkboxes(item) : checkboxes}
            </ListItem>
            {i < list.length - 1 ? <Divider /> : null}
          </div>
        </Slide>
      </React.Fragment>
    )
  })

  if (!hasResults) {
    return <div className={classes.emptyStateWithAccordion}>Nothing has been attached yet.</div>
  }

  if (hasResults && list.length === 0) {
    return (
      <div className={classes.emptyStateWithAccordion}>
        No attachments matching your filter criteria where found.
      </div>
    )
  }

  return (
    <List data-test-id={dataTestId} className={classes.mediaListRoot}>
      {listItems}
    </List>
  )
}

AttachmentList.propTypes = {
  dataTestId: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
      media: PropTypes.shape({
        mimetype: PropTypes.string,
      }),
    })
  ),
  coverImageId: PropTypes.string,
  onAttachmentMenuOpen: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  animatedMediaId: PropTypes.string,
  targetAttachment: PropTypes.string,
  hasResults: PropTypes.bool,
}

const AttachmentListsWithAccordion = ({
  targetAttachment,
  lists,
  onAttachmentMenuOpen,
  accordionState,
  onAccordionState,
  animatedMediaId,
  coverImageId,
}) => {
  const classes = useStyles()

  return lists.map(({ title, media, hasResults, color }, i) => {
    const accordionGroup = title.split(' ')[0]?.toLowerCase() // gembah || client || design
    return (
      <Accordion
        data-test-id={`project-details-attachments-${accordionGroup}`}
        key={title}
        classes={{ root: classes.accordionRoot, expanded: classes.expanded }}
        expanded={accordionState === i}
        data-tracking-info={serializeData({
          id: `project_attachments-${accordionGroup}-accordion_click`,
          newState: accordionState === i ? 'closed' : 'open',
        })}
        onChange={() => onAccordionState(i)}
      >
        <AccordionSummary
          classes={{
            root: classes.accordionSummaryRoot,
            content: classes.accordionSummaryContent,
            expanded: classes.expanded,
            expandIcon: classes.accordionSummaryIcon,
          }}
          style={{ borderLeft: `8px solid ${color}` }}
          expandIcon={<ArrowDropDownIcon />}
        >
          {title}
        </AccordionSummary>
        <AccordionDetails
          data-test-id={`project-details-attachments-body-${accordionGroup}`}
          classes={{ root: classes.accordionDetailsRoot }}
        >
          <AttachmentList
            coverImageId={coverImageId}
            targetAttachment={targetAttachment}
            animatedMediaId={animatedMediaId}
            list={media}
            onAttachmentMenuOpen={onAttachmentMenuOpen}
            hasResults={hasResults}
          />
        </AccordionDetails>
      </Accordion>
    )
  })
}

AttachmentListsWithAccordion.propTypes = {
  targetAttachment: PropTypes.string,
  accordionState: PropTypes.number,
  lists: PropTypes.array,
  onAttachmentMenuOpen: PropTypes.func,
  onAccordionState: PropTypes.func,
  animatedMediaId: PropTypes.string,
  coverImageId: PropTypes.string,
}

const ProjectDetailsAttachmentsView = ({
  buttonSquareMenuEntries,
  downloading,
  targetAttachment,
  mediaList,
  filteredMedia,
  onAttachmentMenuOpen,
  accordionState,
  onAccordionState,
  setFilteredMedia,
  coverImageId,
}) => {
  const classes = useStyles()
  const user = useReactiveVar(currentUser)
  const { isUserOnProjectTeam } = useReactiveVar(currentProject)
  const noResults = !mediaList || Object.values(mediaList).every((l) => !l?.media.length)
  const listProps = Object.values(filteredMedia)
  const canUploadFiles = Boolean(isUserOnProjectTeam || user.staffUser)

  const renderAppropiateMediaLists = () =>
    listProps.length > 1 ? (
      <AttachmentListsWithAccordion
        coverImageId={coverImageId}
        targetAttachment={targetAttachment}
        accordionState={accordionState}
        lists={listProps}
        onAttachmentMenuOpen={onAttachmentMenuOpen}
        onAccordionState={onAccordionState}
      />
    ) : (
      <AttachmentList
        coverImageId={coverImageId}
        list={listProps[0]?.media || []}
        hasResults={listProps[0]?.hasResults}
        dataTestId={`project-details-attachments-${listProps[0].title
          .split(' ')[0]
          ?.toLowerCase()}`}
        onAttachmentMenuOpen={onAttachmentMenuOpen}
      />
    )

  return (
    <ContentCard
      dataTestId="project-details--attachments-wrapper"
      additionalContent={<Filters mediaList={mediaList} setFilteredMedia={setFilteredMedia} />}
      action={
        <div className={classes.actions}>
          {canUploadFiles && (
            <div className={classes.uploadActionWrapper}>
              <span>(drop file anywhere in this window)</span>
              <ButtonSquareMenu
                loading={downloading}
                title="Attach file"
                data-test-id="project-details--attachments-tab--menu-button"
                id="button-square-menu"
                entries={buttonSquareMenuEntries}
                data-tracking-info={serializeData({
                  id: 'project_attachments-menu-button_click',
                })}
              />
            </div>
          )}
        </div>
      }
      transparentHeader
    >
      <div className={classes.root}>
        {noResults ? (
          <div className={classes.emptyState}>Nothing has been attached to this project yet.</div>
        ) : (
          renderAppropiateMediaLists()
        )}
      </div>
    </ContentCard>
  )
}

ProjectDetailsAttachmentsView.propTypes = {
  buttonSquareMenuEntries: PropTypes.array,
  coverImageId: PropTypes.string,
  mediaList: PropTypes.object,
  filteredMedia: PropTypes.object,
  onAttachmentMenuOpen: PropTypes.func,
  downloading: PropTypes.bool,
  accordionState: PropTypes.number,
  onAccordionState: PropTypes.func,
  targetAttachment: PropTypes.string,
  setFilteredMedia: PropTypes.func,
}

export default ProjectDetailsAttachmentsView
