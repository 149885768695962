/* eslint-disable react/jsx-props-no-spreading */
import { useMutation, useReactiveVar } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { makeStyles } from '@material-ui/core/styles'

import PopButton from '@forms/PopButton'
import PopComboField from '@forms/PopComboField'
import PopTextField from '@forms/PopTextField'

import { npiRoles } from '@lib/apollo/apolloCache'
import { PRIMARY_TEXT_GREY } from '@lib/colors'
import { normalizeURL, URL_REGEX } from '@lib/stringUtils'
import { makeEllipsisText, MOBILE_BREAKPOINT } from '@lib/theme'
import { serializeData } from '@lib/tracking'

import { EDIT_NPI_STEP } from '@graphql/npi/mutators'

import { TOAST_TYPES } from '@components_pop/Toast'
import GembahDialogTitle from '@components_pop/dialog/GembahDialogTitle'

import useToast from '@hooks/useToast'

const VALIDATION_SCHEMA = Yup.object().shape({
  templateDocumentUrl: Yup.string()
    .trim()
    .transform(normalizeURL)
    .matches(URL_REGEX, 'Please enter a valid URL'),
})

const useModalStyles = makeStyles((theme) => ({
  modalSubHeader: {
    ...makeEllipsisText(400),
    fontSize: '0.65rem',
    color: PRIMARY_TEXT_GREY,
    marginTop: theme.spacing(0.5),
  },
  dialogContentWrapper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  dialogContent: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    '& > div': {
      flex: 1,
    },
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      flexWrap: 'noWrap',
      flexDirection: 'row',
      '& > div + div': {
        marginLeft: 20,
      },
    },
  },
  actionsWrapper: {
    display: 'flex',
  },
}))

const NpiEditModal = ({ onCloseModal, itemOptions }) => {
  const classes = useModalStyles()
  const { addToast } = useToast()
  const currentNpiRoles = useReactiveVar(npiRoles)

  const [callEditNpiStep, { loading: editNpiLoading }] = useMutation(EDIT_NPI_STEP)

  const isLoading = editNpiLoading

  const currentItem = itemOptions.item

  const { handleSubmit, register, setValue, errors, watch } = useForm({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: {
      duration: currentItem.duration,
      ownerRole: currentItem.ownerRole?.name,
      templateDocumentUrl: currentItem.templateDocumentUrl,
    },
  })

  const { duration: durationValue, ownerRole } = watch()

  const handleSave = (values) => {
    callEditNpiStep({
      variables: {
        stepId: currentItem.npiStepId,
        duration: parseInt(values.duration, 10),
        ownerRole: values.ownerRole,
        templateDocumentUrl: values.templateDocumentUrl,
      },
    }).then((res) => {
      if (res.errors) return

      addToast({
        message: 'Item has been successfully edited',
        type: TOAST_TYPES.SUCCESS,
      })

      onCloseModal()
    })
  }

  const findCorrectRoleValue = (ownerName) => {
    return currentNpiRoles.find((opt) => opt.role.name === ownerName) ?? null
  }

  const handleRoleChange = (role) => {
    setValue('ownerRole', role.name)
  }

  useEffect(() => {
    register({ name: 'ownerRole' })
    register({ name: 'templateDocumentUrl' })
  }, [register])

  useEffect(() => {
    if (!durationValue) return

    if (parseInt(durationValue, 10) < 1) {
      setValue('duration', currentItem.duration)
    }
  }, [durationValue, setValue, currentItem.duration])

  return (
    <Dialog
      open
      fullWidth
      onClose={onCloseModal}
      disableBackdropClick
      data-test-id="npi--edit-item--dialog"
    >
      <GembahDialogTitle onClose={onCloseModal}>
        Edit {itemOptions.type.toLocaleLowerCase()}
      </GembahDialogTitle>
      <DialogContent className={classes.dialogContentWrapper}>
        <div className={classes.dialogContent}>
          <PopTextField
            data-test-id="npi--edit-duration-input--dialog"
            id="duration"
            name="duration"
            label="Duration (days)"
            ref={register}
            onBlur={(e) => {
              if (!e.target.value) {
                setValue('duration', currentItem.duration)
              }
            }}
            error={!!errors.duration}
            helperText={errors.duration?.message}
            type="number"
          />
          <PopComboField
            name="ownerRole"
            label="Owner Role"
            data-test-id="npi--edit-role-input--dialog"
            value={findCorrectRoleValue(ownerRole)}
            noOptionsText="No roles found."
            options={currentNpiRoles}
            getOptionLabel={(option) => option.role?.name || ''}
            getOptionSelected={(opt, selected) => opt.role?.name === selected.role?.name}
            onChange={(_, owner) => handleRoleChange(owner.role)}
            data-tracking-info={serializeData({
              id: 'npi--edit-role-input--dialog_click',
            })}
            disableClearable
          />
        </div>

        <PopTextField
          data-test-id="npi--edit-template-document-input--dialog"
          id="templateDocumentUrl"
          name="templateDocumentUrl"
          label="Template Document"
          ref={register}
          error={!!errors.templateDocumentUrl}
          helperText={errors.templateDocumentUrl?.message}
          type="text"
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button
          onClick={onCloseModal}
          data-tracking-info={serializeData({
            id: `npi_edit-item-modal-cancel-button_click`,
          })}
        >
          Cancel
        </Button>
        <PopButton
          size="small"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleSubmit(handleSave)}
          data-tracking-info={serializeData({
            id: `npi_edit-item-modal-save-button_click`,
          })}
        >
          Save
        </PopButton>
      </DialogActions>
    </Dialog>
  )
}

NpiEditModal.propTypes = {
  onCloseModal: PropTypes.func,
  itemOptions: PropTypes.shape({
    item: PropTypes.object,
    type: PropTypes.string,
  }),
}

export default NpiEditModal
