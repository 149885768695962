import PropTypes from 'prop-types'
import React from 'react'

import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

import { BOX_BORDER_COLOR } from '@lib/colors'
import { makeEllipsisText } from '@lib/theme'

import MediaIcon from '@components_pop/attachments/MediaIcon'

const useModalStyles = makeStyles((theme) => ({
  fileList: {
    width: '100%',
    marginBottom: theme.spacing(1.5),
  },
  fileContainer: {
    borderTop: `1px solid ${BOX_BORDER_COLOR}`,
    flexFlow: 'wrap',
    marginTop: 10,
    paddingTop: 10,
    alignItems: 'center',
    '&:first-of-type': {
      border: 'none',
      margin: 0,
      padding: 0,
    },
  },
  filenameContainer: {
    flexWrap: 'nowrap',
    flex: 1,
  },
  fileIconItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(),
    fontSize: 32,
    maxWidth: 56,
    minWidth: 56,
    minHeight: 56,
    maxHeight: 56,
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  fileNameItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    color: theme.palette.text.primary,
    fontWeight: 400,
    wordBreak: 'break-word',
    '& > span': {
      ...makeEllipsisText(300),
    },
  },
  filesTitle: {
    display: 'block',
    fontSize: '1rem',
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  imageStyles: {
    width: 80,
  },
}))

const normalizeFilesData = (files = []) => {
  return files.map((file) => {
    return {
      id: file.id,
      thumbnailUrl: file.thumbnailUrl,
      path: file.path ?? file.url,
      type: file.type ?? file.mimetype,
      name: file.name ?? file.filename,
    }
  })
}

const FileList = ({ files = [], title, children }) => {
  const classes = useModalStyles()

  return files?.length > 0 ? (
    <Grid item className={classes.fileList}>
      {<span className={classes.filesTitle}>{title}</span>}
      {normalizeFilesData(files).map((file) => (
        <Grid item container key={file.path} className={classes.fileContainer}>
          <Grid item container className={classes.filenameContainer}>
            <Grid item className={classes.fileIconItem}>
              {file.thumbnailUrl ? (
                <img className={classes.imageStyles} src={file.thumbnailUrl} alt={file.filename} />
              ) : (
                <MediaIcon className={classes.imageStyles} mimetype={file.type} />
              )}
            </Grid>
            <Grid item className={classes.fileNameItem}>
              <Tooltip arrow placement="top" title={file.name ?? ''}>
                <span>{file.name}</span>
              </Tooltip>
            </Grid>
          </Grid>
          {children({ file })}
        </Grid>
      ))}
    </Grid>
  ) : null
}

FileList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  children: PropTypes.func,
}

export default FileList
