import { faUserMinus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { serializeData } from '@lib/tracking'

const useStyles = makeStyles(() => ({
  memberMenuIconRoot: {
    minWidth: 0,
    paddingRight: 10,
  },
}))

const ProjectDetailsTeamView = ({ anchorEl, onMenuClose, onRemoveClick }) => {
  const classes = useStyles()

  return (
    <>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onMenuClose}>
        <MenuItem
          onClick={onRemoveClick}
          data-test-id="project-team-member-menu-item--remove-from-project"
          data-tracking-info={serializeData({
            id: `project_team-member-menu-option_click`,
            option: 'remove-member',
          })}
        >
          <ListItemIcon classes={{ root: classes.memberMenuIconRoot }}>
            <FontAwesomeIcon icon={faUserMinus} />
          </ListItemIcon>
          <ListItemText primary="Remove from project" />
        </MenuItem>
      </Menu>
    </>
  )
}

ProjectDetailsTeamView.propTypes = {
  anchorEl: PropTypes.shape({}),
  onMenuClose: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
}

export default ProjectDetailsTeamView
