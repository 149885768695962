import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const styles = {
  error: {
    width: '100%',
    backgroundColor: '#FCC5D3',
    padding: 8,
    marginBottom: 16,
  },
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(/* error */) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, errorInfo) {
    // TODO: log to Sentry?
    // console.log(error, errorInfo)
  }

  resetError = () => {
    const { restoreMessage } = this.props

    this.setState({
      hasError: false,
    }, () => {
      restoreMessage()
    })
  }

  render() {
    const { classes, children } = this.props
    const { hasError } = this.state

    if (hasError) {
      return (
        <div className={classes.error}>
          Oops! An error has occurred.
          &nbsp;
          <Button variant="contained" color="primary" onClick={this.resetError}>Try Again</Button>
        </div>
      )
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  classes: PropTypes.shape({
    error: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  restoreMessage: PropTypes.func.isRequired,
}

export default withStyles(styles)(ErrorBoundary)
