import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'
import _ from 'lodash'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useRef, useState } from 'react'

import DownloadIcon from '@public/svg/icons/download-file.svg'
import LinkIcon from '@public/svg/icons/link.svg'
import UploadIcon from '@public/svg/icons/upload-icon.svg'

import { channelMediaHasChanged, currentAccountUser, currentProject } from '@lib/apollo/apolloCache'
import {
  PRIMARY_BLACK_BACKGROUND,
  PRIMARY_BLUE_BACKGROUND,
  PRIMARY_RED,
  USER_TYPE_COLORS,
} from '@lib/colors'
import { MIME_TYPES_FOR_ICONS } from '@lib/constants'
import { ACCOUNT_TYPES, getChatChannels, hasPermission } from '@lib/userAuth'

import { SHARE_ATTACHMENT_TO_CHANNEL } from '@graphql/project/attachments/mutators'
import { GET_ALL_ATTACHMENTS_ALL_CHANNELS } from '@graphql/project/attachments/queries'
import { SET_PROJECT_COVER_IMAGE } from '@graphql/project/mutators'
import { GET_PROJECT_QUERY } from '@graphql/project/queries'

import FileUploadContext from '@components_pop/DropZone/FileUploadContext'
import Loader from '@components_pop/Loader'
import BatchDownloadProgress from '@components_pop/ProjectDetailsAttachments/BatchDownloadProgress'
import { TOAST_TYPES } from '@components_pop/Toast'
import AttachmentMenu from '@components_pop/attachments/AttachmentMenu'

import useDownloadFile from '@hooks/useDownloadFile'
import useToast from '@hooks/useToast'

import ProjectDetailsAttachments from './view'

const NOT_FILES_MIMETYPES = [MIME_TYPES_FOR_ICONS.GOOGLE_DRIVE]

const ProjectDetailsAttachmentsContainer = () => {
  const closeMenuTimeout = useRef(null)

  const router = useRouter()
  const { projectSlug } = router.query

  const accountUser = useReactiveVar(currentAccountUser)
  const projectContext = useReactiveVar(currentProject)
  const uploadContext = useContext(FileUploadContext)

  const isCustomer = hasPermission.CREATOR(accountUser)

  const SHARE_WITH_DEFAULT = {
    internalChannelAttachment: {
      with: 'Gembah',
      isSharable: false,
      color: PRIMARY_BLACK_BACKGROUND,
      id: '',
    },
    externalChannelAttachment: {
      with: isCustomer ? 'Gembah' : 'Customer',
      isSharable: false,
      color: PRIMARY_RED,
      id: '',
    },
    designChannelAttachment: {
      with: 'Stakeholders',
      isSharable: false,
      color: PRIMARY_BLUE_BACKGROUND,
      id: '',
    },
  }

  const channelMediaHasChangedContext = useReactiveVar(channelMediaHasChanged)
  const [fileListInfo, setFileListInfo] = useState(null)
  const [channelMedia, setChannelMedia] = useState({})
  const [filteredMedia, setFilteredMedia] = useState({})
  const [attachmentMenuAnchorEl, setAttachmentMenuAnchorEl] = useState(null)
  const [attachmentMenuItem, setAttachmentMenuItem] = useState({})
  const [accordionState, setAccordionState] = useState(0)
  const [channelIds, setChannelIds] = useState({})
  const [targetAttachment, setTargetAttachment] = useState()
  const [shareWith, setShareWith] = useState(SHARE_WITH_DEFAULT)
  const [mediaIds, setMediaIds] = useState()
  const { addToast } = useToast()
  const channelPermissions = getChatChannels(projectContext, accountUser)
  const { zipAndDownloadAll, stopZipAndDownloadAll, isDownloading, batchDownloadInfo } =
    useDownloadFile()

  const [getProjectChannelMedia, { data }] = useLazyQuery(GET_ALL_ATTACHMENTS_ALL_CHANNELS, {
    fetchPolicy: 'cache-and-network',
  })

  const [shareAttachmentToChannel] = useMutation(SHARE_ATTACHMENT_TO_CHANNEL)
  const [setProjectCoverImage] = useMutation(SET_PROJECT_COVER_IMAGE, {
    refetchQueries: [
      {
        query: GET_PROJECT_QUERY,
        variables: { projectSlug },
      },
    ],
  })

  const handleAttachmentMenuClose = () => {
    setAttachmentMenuAnchorEl(null)
    setShareWith(SHARE_WITH_DEFAULT)
    closeMenuTimeout.current = setTimeout(setAttachmentMenuItem, 500, {})
  }

  const handleSetProjectCoverImage = (mediaId) => {
    setProjectCoverImage({
      variables: {
        mediaId,
        projectId: projectContext.id,
      },
    }).then((res) => {
      if (res.errors) {
        addToast({
          message: 'Image could not be set as cover image',
          type: TOAST_TYPES.ERROR,
        })
        handleAttachmentMenuClose()
        return
      }

      addToast({
        message: 'Image has been set as cover image',
        type: TOAST_TYPES.SUCCESS,
      })
      setTargetAttachment(null)
      getProjectChannelMedia({
        variables: channelIds,
      })
      handleAttachmentMenuClose()
    })
  }

  const handleShareAttachmentToChannel = (mediaProjectChannelId, channelId) => {
    shareAttachmentToChannel({
      variables: {
        mediaProjectChannelId,
        channelId,
      },
    }).then((res) => {
      if (res.errors) {
        setTargetAttachment(null)
        handleAttachmentMenuClose()
        addToast({
          message: 'Attachment could not be shared',
          type: TOAST_TYPES.ERROR,
        })
        return
      }

      setTargetAttachment(null)
      getProjectChannelMedia({
        variables: channelIds,
      })
      handleAttachmentMenuClose()

      addToast({
        message: 'Attachment has been succesfully shared',
        type: TOAST_TYPES.SUCCESS,
      })
    })
  }

  const downloadAll = async () => {
    if (!fileListInfo) {
      return
    }

    await zipAndDownloadAll(fileListInfo)
  }

  const extractMediaIds = (files) => files?.map((file) => file.media.id)
  const isSharable = (attachmentId, channelName) =>
    !!mediaIds[channelName]?.every((id) => attachmentId !== id)

  // this is used to open a new accordion category if the currently opened one has no results
  useEffect(() => {
    const newChannelMediaValues = Object.values(channelMedia)
    const currentOpenedAccordion = newChannelMediaValues[accordionState]

    if (!currentOpenedAccordion?.hasResults) {
      const accordionIdThatShouldBeOpen = newChannelMediaValues.findIndex(
        (channel) => !!channel?.hasResults
      )
      setAccordionState(accordionIdThatShouldBeOpen)
    }
  }, [channelMedia, setAccordionState])

  useEffect(() => {
    if (projectContext && !projectContext.isLoading) {
      const ids = {
        ...(channelPermissions.externalChatChannel && {
          externalChannelId: projectContext.externalChatChannel.id,
        }),
        ...(channelPermissions.internalChatChannel && {
          internalChannelId: projectContext.internalChatChannel.id,
        }),
        ...(channelPermissions.designChatChannel && {
          designChannelId: projectContext.designChatChannel.id,
        }),
      }

      setChannelIds(ids)
      getProjectChannelMedia({
        variables: ids,
      })
    }

    return () => {
      clearTimeout(closeMenuTimeout.current)
    }
  }, [projectContext, setChannelIds])

  useEffect(() => {
    if (data?.allAttachmentsAllChannels) {
      const { externalChannelAttachment, internalChannelAttachment, designChannelAttachment } =
        data.allAttachmentsAllChannels

      const newChannelMedia = {
        ...(channelPermissions.externalChatChannel && {
          externalChannelAttachment: {
            id: projectContext.externalChatChannel.id,
            url: projectContext.externalChatChannel.url,
            title: `${isCustomer ? 'Gembah' : 'Client'} visible attachments`,
            media: externalChannelAttachment,
            hasResults: !!externalChannelAttachment?.length,
            color: USER_TYPE_COLORS[ACCOUNT_TYPES.CREATOR],
          },
        }),
        ...(channelPermissions.internalChatChannel && {
          internalChannelAttachment: {
            id: projectContext.internalChatChannel.id,
            url: projectContext.internalChatChannel.url,
            title: 'Gembah only attachments',
            media: internalChannelAttachment,
            hasResults: !!internalChannelAttachment?.length,
            color: USER_TYPE_COLORS[ACCOUNT_TYPES.GEMBAH],
          },
        }),
        ...(channelPermissions.designChatChannel && {
          designChannelAttachment: {
            id: projectContext.designChatChannel.id,
            url: projectContext.designChatChannel.url,
            title: 'Stakeholders attachments',
            media: designChannelAttachment,
            hasResults: !!designChannelAttachment?.length,
            color: USER_TYPE_COLORS[ACCOUNT_TYPES.EXPERT],
          },
        }),
      }

      setChannelMedia({ ...newChannelMedia })
      setFilteredMedia({ ...newChannelMedia })

      const mediaIdsCollection = Object.entries(newChannelMedia).reduce(
        (soFar, [channelKey, channelData]) => {
          return {
            ...soFar,
            [channelKey]: extractMediaIds(channelData.media),
          }
        },
        {}
      )

      setMediaIds({ ...mediaIdsCollection })
    }
  }, [data, setFilteredMedia, setChannelMedia, setMediaIds])

  useEffect(() => {
    const {
      company: { name },
      id,
    } = projectContext

    const allMedia = _.union(...Object.values(filteredMedia).map((value) => value.media)).filter(
      ({ media }) => !NOT_FILES_MIMETYPES.includes(media?.mimetype)
    )

    setFileListInfo({
      allMedia: _.uniqBy(allMedia, 'media.filename'),
      fileName: `${name}-${id}`,
    })
  }, [filteredMedia, setFileListInfo])

  useEffect(() => {
    if (channelMediaHasChangedContext) {
      getProjectChannelMedia({ variables: channelIds })
      channelMediaHasChanged(false)
    }
  }, [channelMediaHasChangedContext])

  const handleUploadFilesModalOpen = () => {
    uploadContext.onOpenFileSelectModal()
  }

  const handleAttachLinksModalOpen = () => {
    uploadContext.onOpenLinksAttachModal()
  }

  const handleAttachmentMenuOpen = (attachment) => (event) => {
    event.stopPropagation()
    event.preventDefault()
    setAttachmentMenuItem(attachment)
    setAttachmentMenuAnchorEl(event.currentTarget)

    const newSharedWith = Object.entries(shareWith).reduce((soFar, [channelName, channelProps]) => {
      return {
        ...soFar,
        [channelName]: {
          ...channelProps,
          isSharable: isSharable(attachment.media.id, channelName),
          id: channelMedia[channelName]?.id,
        },
      }
    }, {})

    setShareWith({ ...newSharedWith })
  }

  const handleAccordionState = (accordionIdx) => {
    setAccordionState(accordionIdx !== accordionState ? accordionIdx : null)
  }

  let buttonSquareMenuEntries = [
    {
      label: 'Upload Files',
      icon: UploadIcon,
      cb: handleUploadFilesModalOpen,
      dataTestId: 'project-details--attachments-tab--menu-upload-button',
      trackingInfo: {
        id: 'project_attachments-menu-upload_click',
      },
    },
    {
      label: 'Add links',
      icon: LinkIcon,
      cb: handleAttachLinksModalOpen,
      dataTestId: 'project-details--attachments-tab--menu-links-button',
      trackingInfo: {
        id: 'project_attachments-menu-add-links_click',
      },
    },
  ]

  if (fileListInfo && fileListInfo.allMedia.length > 1) {
    buttonSquareMenuEntries = [
      ...buttonSquareMenuEntries,
      {
        disabled: isDownloading,
        label: `Download All (${fileListInfo?.allMedia?.length} files)`,
        icon: DownloadIcon,
        cb: downloadAll,
        dataTestId: 'project-details--attachments-tab--menu-download-files-button',
        trackingInfo: {
          id: 'project_attachments-menu-download-files_click',
        },
      },
    ]
  }

  return (
    <Loader loaderElementsCount={5} isLoadingContent={!data} variant="attachment">
      <>
        <BatchDownloadProgress
          filesDownloaded={batchDownloadInfo.filesDownloaded}
          fileCount={batchDownloadInfo.fileCount}
          onStopDownload={stopZipAndDownloadAll}
        />
        <ProjectDetailsAttachments
          buttonSquareMenuEntries={buttonSquareMenuEntries}
          coverImageId={projectContext?.coverImage?.id}
          targetAttachment={targetAttachment}
          mediaList={channelMedia}
          downloading={isDownloading}
          filteredMedia={filteredMedia}
          onAttachmentMenuOpen={handleAttachmentMenuOpen}
          accordionState={accordionState}
          onAccordionState={handleAccordionState}
          setFilteredMedia={setFilteredMedia}
        />
        <AttachmentMenu
          shareWith={shareWith}
          anchorEl={attachmentMenuAnchorEl}
          attachment={attachmentMenuItem}
          onMenuClose={handleAttachmentMenuClose}
          onShareAttachmentToChannel={handleShareAttachmentToChannel}
          onSetProjectCoverImage={handleSetProjectCoverImage}
        />
      </>
    </Loader>
  )
}

export default ProjectDetailsAttachmentsContainer
