import { useReactiveVar, useMutation } from '@apollo/client'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { currentAccountUser, currentProject } from '@lib/apollo/apolloCache'
import { PROJECT_STAFF_USER_TYPE as PROJECT_USER_TYPES } from '@lib/constants/projects'
import { ACCOUNT_TYPES, hasPermission } from '@lib/userAuth'

import { GET_PROJECT_QUERY } from '@graphql/project/queries'
import {
  ASSIGN_STAFF_USER_TO_PROJECT,
  ASSIGN_CONTRACTOR_TO_PROJECT,
  ADD_CUSTOMER_TO_PROJECT,
  TOGGLE_PROJECT_DESIGN_CHAT_CUSTOMER,
  GRANT_EXTERNAL_CHANNEL_ACCESS_TO_CONTRACTOR,
  REVOKE_EXTERNAL_CHANNEL_ACCESS_FROM_CONTRACTOR,
} from '@graphql/users/mutators'

import UserSelection from '@components_pop/ProjectDetailsTeam/userSelection'
import { TOAST_TYPES } from '@components_pop/Toast'
import TeamMemberMenu from '@components_pop/projectTeam/TeamMemberMenu'

import useToast from '@hooks/useToast'

import TeamActionSelection from './actionSelection'
import ProjectDetailsTeam from './view'

export const TAB_STATES = {
  DEFAULT: 'default',
  SELECT: 'select-option',
  ATTACH_EMPLOYEE: ACCOUNT_TYPES.GEMBAH,
  ADD_CUSTOMER: ACCOUNT_TYPES.CREATOR,
  ATTACH_CONTRACTOR: ACCOUNT_TYPES.EXPERT,
  ATTACH_FACTORY: ACCOUNT_TYPES.FACTORY,
}

const useTeamNavigation = () => {
  const [teamNavigationState, setTeamNavigationState] = useState(TAB_STATES.DEFAULT)
  const cancel = () => setTeamNavigationState(TAB_STATES.DEFAULT)
  const goToSelectOption = () => setTeamNavigationState(TAB_STATES.SELECT)
  const goToAdd = (type) => setTeamNavigationState(type)
  return {
    teamNavigationState,
    cancel,
    goToSelectOption,
    goToAdd,
  }
}

const ProjectDetailsTeamContainer = () => {
  const { addToast } = useToast()
  const {
    team,
    id: projectId,
    company: { id: companyId },
    slug: projectSlug,
  } = useReactiveVar(currentProject)
  const accountUser = useReactiveVar(currentAccountUser)
  const [memberMenuAnchorEl, setMemberMenuAnchorEl] = useState(null)
  const [menuMember, setMenuMember] = useState(null)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const refetchQueries = {
    refetchQueries: [{ query: GET_PROJECT_QUERY, variables: { projectSlug } }],
  }

  const [grantExternalChannelToContractorMutator] = useMutation(
    GRANT_EXTERNAL_CHANNEL_ACCESS_TO_CONTRACTOR,
    refetchQueries
  )
  const [revokeExternalChannelFromContractorMutator] = useMutation(
    REVOKE_EXTERNAL_CHANNEL_ACCESS_FROM_CONTRACTOR,
    refetchQueries
  )

  const [assignStaffUserToProject] = useMutation(ASSIGN_STAFF_USER_TO_PROJECT, refetchQueries)
  const [assignContractorToProject] = useMutation(ASSIGN_CONTRACTOR_TO_PROJECT, refetchQueries)
  const [addCustomerToProject] = useMutation(ADD_CUSTOMER_TO_PROJECT, refetchQueries)

  const [toggleProjectDesignChatCustomerMutator] = useMutation(
    TOGGLE_PROJECT_DESIGN_CHAT_CUSTOMER,
    refetchQueries
  )

  const { handleSubmit, register, errors, setValue, formState } = useForm({
    mode: 'onChange',
  })

  const { isValid } = formState
  const tabStateHandler = useTeamNavigation()

  useEffect(() => {
    register({ name: 'userId' }, { required: 'User is required' })
  }, [register])

  const toggleProjectDesignChatCustomer = (userId, isAuthorized, cb) => {
    toggleProjectDesignChatCustomerMutator({
      variables: {
        projectId,
        userId,
        isAuthorized,
      },
    }).then((res) => {
      if (res.errors) {
        if (cb) cb(!isAuthorized)
        return
      }

      if (cb) cb(isAuthorized)

      addToast({
        message: isAuthorized
          ? 'Customer can now chat with stakeholders'
          : 'Customer can no longer chat with stakeholders',
        type: TOAST_TYPES.SUCCESS,
      })
    })
  }

  const grantExternalChannelToContractor = (userId, cb) => {
    grantExternalChannelToContractorMutator({
      variables: {
        projectId,
        userId,
      },
    }).then((res) => {
      if (res.errors) {
        return
      }

      if (cb) cb()

      addToast({
        message: 'Permission to chat with Client granted',
        type: TOAST_TYPES.SUCCESS,
      })
    })
  }

  const revokeExternalChannelFromContractor = (userId, cb) => {
    revokeExternalChannelFromContractorMutator({
      variables: {
        projectId,
        userId,
      },
    }).then((res) => {
      if (res.errors) {
        return
      }

      if (cb) cb()

      addToast({
        message: 'Permission to chat with Client revoked',
        type: TOAST_TYPES.SUCCESS,
      })
    })
  }

  const determineCorrectAddCall = (userId) => {
    switch (tabStateHandler.teamNavigationState) {
      case TAB_STATES.ADD_CUSTOMER:
        return addCustomerToProject({
          variables: {
            projectId,
            userId,
          },
        })
      case TAB_STATES.ATTACH_CONTRACTOR:
      case TAB_STATES.ATTACH_FACTORY:
        return assignContractorToProject({
          variables: {
            projectId,
            contractorId: userId,
          },
        })
      case TAB_STATES.ATTACH_EMPLOYEE:
      default:
        return assignStaffUserToProject({
          variables: {
            projectId,
            staffUserId: userId,
            projectStaffUserType: PROJECT_USER_TYPES.STAFF,
          },
        })
    }
  }

  const onAddUser = (userData) => {
    setIsSubmitting(true)

    determineCorrectAddCall(userData.userId)
      .then((res) => {
        if (res.errors) {
          return
        }

        addToast({
          message: 'New team member added',
          type: TOAST_TYPES.SUCCESS,
        })

        tabStateHandler.cancel()
      })
      .finally(() => setIsSubmitting(false))
  }

  const handleMemberMenuOpen = (member) => (event) => {
    setMemberMenuAnchorEl(event.currentTarget)
    setMenuMember(member)
  }

  const handleMemberMenuClose = () => {
    setMemberMenuAnchorEl(null)
    setMenuMember(null)
  }

  if (tabStateHandler.teamNavigationState === TAB_STATES.DEFAULT) {
    return (
      <>
        <ProjectDetailsTeam
          team={team}
          tabStateHandler={tabStateHandler}
          companyId={companyId}
          onMenuOpen={handleMemberMenuOpen}
          toggleProjectDesignChatCustomer={toggleProjectDesignChatCustomer}
          grantExternalChannelToContractor={grantExternalChannelToContractor}
          revokeExternalChannelFromContractor={revokeExternalChannelFromContractor}
        />
        {hasPermission.GEMBAH(accountUser) && (
          <TeamMemberMenu
            team={team}
            anchorEl={memberMenuAnchorEl}
            member={menuMember}
            onMenuClose={handleMemberMenuClose}
          />
        )}
      </>
    )
  }

  if (tabStateHandler.teamNavigationState === TAB_STATES.SELECT) {
    return <TeamActionSelection cancel={tabStateHandler.cancel} goToAdd={tabStateHandler.goToAdd} />
  }

  return (
    <UserSelection
      cancel={tabStateHandler.goToSelectOption}
      errors={errors}
      register={register}
      submitting={isSubmitting}
      onSubmit={handleSubmit(onAddUser)}
      userType={tabStateHandler.teamNavigationState}
      setValue={setValue}
      isValid={isValid}
    />
  )
}

export default ProjectDetailsTeamContainer
