import { useReactiveVar, useLazyQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import React, { useEffect, useState, useRef } from 'react'

import { currentAccountUser, currentChatChannel, currentProject } from '@lib/apollo/apolloCache'
import {
  COMMON_WHITE,
  LIGHT_BLUE,
  LIGHT_GRAY,
  PRIMARY_BLACK_BACKGROUND,
  PRIMARY_BLUE_BACKGROUND,
  PRIMARY_RED,
  PRIMARY_TEXT_COLOR,
} from '@lib/colors'
import { hasPermission } from '@lib/userAuth'

import { GET_MESSAGE_CHANNEL } from '@graphql/chat/queries'

import { clearQueryString } from '@components_pop/chat/utils'

import ChatPopper from './ChatPopper'

const ProjectDetailsChat = () => {
  const router = useRouter()
  const previousChannel = useRef() // References the last channel key that was open before closing the chat
  const chatAnchorRef = useRef()
  const currentChannelId = useReactiveVar(currentChatChannel)
  const queryMessageId = router.query.messageid
  const project = useReactiveVar(currentProject)
  const accountUser = useReactiveVar(currentAccountUser)
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)

  const isCreator = hasPermission.CREATOR(accountUser)
  const isGembahAdmin = hasPermission.GEMBAH_ADMIN(accountUser)

  let CHAT_CHANNELS = [
    {
      key: 'internal',
      name: 'Gembah',
      subName: 'Company discussion',
      channelId: project?.internalChatChannel?.id,
      visible: project?.internalChatChannel?.subscribed || isGembahAdmin,
      isInternal: true,
      style: {
        main: {
          background: PRIMARY_BLACK_BACKGROUND,
          opacity: 1,
        },
        title: {
          color: COMMON_WHITE,
        },
        subtitle: {
          color: COMMON_WHITE,
          opacity: 0.7,
        },
        body: {
          background: LIGHT_GRAY,
        },
      },
      hide: hasPermission.EXPERT(accountUser),
    },
    {
      key: 'stakeholders',
      name: 'Stakeholders',
      subName: 'Project discussion',
      channelId: project?.designChatChannel?.id,
      visible: project?.designChatChannel?.subscribed || isGembahAdmin,
      isInternal: false,
      style: {
        main: {
          background: PRIMARY_BLUE_BACKGROUND,
          color: PRIMARY_TEXT_COLOR,
          opacity: 1,
        },
        subtitle: {
          opacity: 0.5,
        },
        body: {
          background: LIGHT_BLUE,
        },
      },
      hide: isCreator && !project?.designChatChannel?.subscribed,
    },
    {
      key: 'external',
      name: isCreator ? 'Gembah' : 'Client',
      subName: `${isCreator ? 'Gembah' : 'Client'} facing discussion`,
      channelId: project?.externalChatChannel?.id,
      visible: project?.externalChatChannel?.subscribed || isGembahAdmin,
      isInternal: false,
      style: {
        main: {
          background: COMMON_WHITE,
          opacity: 1,
        },
        title: {
          color: PRIMARY_RED,
        },
        subtitle: {
          color: PRIMARY_TEXT_COLOR,
          opacity: 0.5,
        },
      },
    },
  ]

  const hasNoChannels = CHAT_CHANNELS.every((ch) => !ch.visible)

  if (hasNoChannels) return null

  // we cleanup the chats that should just not exist
  CHAT_CHANNELS = CHAT_CHANNELS.filter((ch) => ch.visible && !ch.hide)

  const getCurrentChannelTabIndex = () =>
    CHAT_CHANNELS.map((ch) => ch.channelId).indexOf(currentChannelId)

  const [getMessageChannel] = useLazyQuery(GET_MESSAGE_CHANNEL, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const channelId = data?.messageChannel?.id
      if (channelId) {
        const tabIndex = CHAT_CHANNELS.findIndex((c) => c.channelId === channelId)
        if (tabIndex !== -1) {
          currentChatChannel(CHAT_CHANNELS[tabIndex].channelId)
          setIsChatOpen(true) // make sure the chat popper is open
        }
      }
    },
  })

  // =============================================================================================================
  // This effect sets the active channel to the first visible one
  // This effect also opens up the chat by default for desktop
  // =============================================================================================================
  useEffect(() => {
    const firstVisibleChannel = CHAT_CHANNELS.filter((ch) => ch.visible)[0]
    previousChannel.current = firstVisibleChannel.channelId
    currentChatChannel(firstVisibleChannel.channelId)
  }, [project?.id])

  // =============================================================================================================
  // Jump-to-message effect:
  // * For deep links. If there is a 'messageid' query param:
  //   1) make sure the chat popper is open
  //   2) query for the channel, then make sure the correct channel is open
  // * The scroll-to effect happens within the ChatChannelWindow component, because that's what renders
  //   the message element.
  // =============================================================================================================
  useEffect(() => {
    if (queryMessageId) {
      getMessageChannel({
        // query for channel. query onComplete handles opening the correct channel
        variables: {
          messageId: queryMessageId,
        },
      })
    }
  }, [queryMessageId])

  // =============================================================================================================

  const handleClose = () => {
    setIsChatOpen(false)
    previousChannel.current = currentChannelId // Save ref to current tab, for reopening
    currentChatChannel(null)
    // By clearing direct links, this prevents the chat from scrolling to the message every time the chat window is open/closed
    clearQueryString(router)
  }

  const handleFullScreenToggle = () => {
    setFullScreen(!fullScreen)
  }

  const handleChatTabChange = (_, newTab) => {
    currentChatChannel(CHAT_CHANNELS[newTab].channelId)
    // By clearing direct links, this prevents the chat from scrolling to the message every time the user navigates between channels
    clearQueryString(router)
  }

  const chatOpenAndChannelSet = !!currentChannelId && isChatOpen

  if (isCreator) {
    CHAT_CHANNELS = CHAT_CHANNELS.filter((c) => !c.isInternal).reverse()
  }

  return (
    <ChatPopper
      open={chatOpenAndChannelSet}
      anchorEl={isChatOpen ? chatAnchorRef.current : null}
      fullScreen={fullScreen}
      projectName={project?.name}
      chatChannels={CHAT_CHANNELS}
      onClose={handleClose}
      onFullScreenToggle={handleFullScreenToggle}
      onChatTabChange={handleChatTabChange}
      chatTab={getCurrentChannelTabIndex()}
    />
  )
}

export default ProjectDetailsChat
